import React, { useState } from "react";
import UploadForm from "./partials/UploadForm";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import CheckIcon from '@mui/icons-material/Check';

const Upload = (props) => {
  // const { templates, selectedTemplate, setSelectedTemplate, surveyResults } =
  //   useOutletContext();

  const [importType, setImportType] = useState("");
  const [, /*importStatus*/ setImportStatus] = useState(false);

  const { t } = useTranslation();

  return (importType?
    <>
      <div className="flex items-center top-bar mb-4">
        <label
          className="block mb-1 title-primary-base"
          htmlFor="importType"
        >
          {t("uploads.select_label")}
        </label>
        <FormControl sx={{ width: 300 }}>
          <Select
            id="importType"
            name="importType"
            value={importType}
            onChange={(e) => setImportType(e.target.value)}
          >
            <MenuItem value="" disabled>
              {t("uploads.select_placeholder")}
            </MenuItem>
              <MenuItem value="manager">{t("uploads.manager_option")}</MenuItem>
              <MenuItem value="employee">{t("uploads.employee_option")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <UploadForm importType={importType} setImportStatus={setImportStatus} />
    </>
    :
      <div className="top-bar">
        <h3 className="title-primary-md mb-4">
          {t("uploads.step_1.title")}
        </h3>
        <p className="mb-6">{t("uploads.step_1.text")}</p>
        <div className="inner-white-box lg:flex lg:flex-wrap">
          <div className="lg:w-1/3 w-full mb-4 lg:pr-5">
            <div className="rounded-[22px] p-6 pb-20 bg-[#F3F3FA] h-full relative">
              {importType==="manager" && (
                <CheckIcon className="float-right -mt-2 -mr-2 !text-[38px] !text-secondary"/>
              )}
              <h3 className="title-primary-md mb-4">{t("uploads.step_1.title_manager")}</h3>
              <p className="mb-6 subtitle">{t("uploads.step_1.text_manager")}</p>
              <button className={`button btn-${importType==="manager"?"secondary":"primary"} absolute bottom-6 left-6`}
                onClick={(e) => {
                  setImportType("manager");
                }}
              >
                {t("uploads.step_1.manager_btn")}
              </button>
            </div>
          </div>
          <div className="lg:w-1/3 w-full mb-4 lg:px-3">
            <div className="rounded-[22px] p-6 pb-20 bg-[#F3F3FA] h-full relative">
              {importType==="employee" && (
                <CheckIcon className="float-right -mt-2 -mr-2 !text-[38px] !text-secondary"/>
              )}
              <h3 className="title-primary-md mb-4">{t("uploads.step_1.title_employee")}</h3>
              <p className="mb-6 subtitle">{t("uploads.step_1.text_employee")}</p>
              <button className={`button btn-${importType==="employee"?"secondary":"primary"} absolute bottom-6 left-6`}
                 onClick={(e) => {
                  setImportType("employee");
                }}
              >
                {t("uploads.step_1.employee_btn")}
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Upload;
