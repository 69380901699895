import { useEffect, useState, useCallback } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import EditableTable from "./partials/table";
import NewGroupModal from "./partials/NewGroupModal";
import SkeletonGroups from "./skeletons/SkeletonGroups";

const Groups = (props) => {
  const location = useLocation();
  const { getTags, tags, editTags, getUsers, managerList, notify } =
    useOutletContext();

  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/admin/manage/groups") {
      // true means get tags with statistics
      getTags("getmanagerstatistics");
      getUsers();
    }
  }, [getTags, location.pathname, getUsers]);

  const fields = [
    { key: "tag", text: "Name", editable: true },
    { key: "manager_user_ids", text: "Managers", editable: false },
  ];

  const handleGroupAdd = () => {
    setIsGroupModalOpen(true);
  };

  const handleGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const handleGroupModalSubmit = useCallback(
    async (newGroupData) => {
      try {
        const result = await editTags("create", newGroupData);

        let success = false;
        let errors = "Could not create tag";

        // Assuming the result contains the newly created Group object
        if (result) {
          if (result?.errors) {
            success = false;
            // check if string
            if (typeof result.errors === "string") {
              errors = result.errors;
            }
          } else {
            success = true;
          }
        }

        if (success) {
          setIsGroupModalOpen(false);
          notify("Group created successfully", "success");
          getTags("getmanagerstatistics");
        } else {
          notify(errors, "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    [editTags, setIsGroupModalOpen, notify, getTags]
  );

  return (
    <>
      <EditableTable
        SkeletonComponent={SkeletonGroups}
        dataList={tags}
        role="tags"
        fields={fields}
        handleAdduser={handleGroupAdd}
      />

      <NewGroupModal
        managerList={managerList || []}
        isGroupModalOpen={isGroupModalOpen}
        handleGroupModalClose={handleGroupModalClose}
        handleGroupModalSubmit={handleGroupModalSubmit}
      />
    </>
  );
};

export default Groups;
