import { useTranslation } from 'react-i18next';
const SurveyStep4 = ({surveyData, setStepNumber}) => {
  const { t } = useTranslation();
  return (
    <section className="container pt-[140px] lg:h-[90vh] mx-auto text-center lg:max-w-[742px]">
        <div className="icon-confetti mb-5"></div>
        <div className="w-full text-center xl:mb-20 mb-16">
          <h2 className="title-primary mb-6">{t('step4.title')}</h2>
        </div>
      </section>
  );
};

export default SurveyStep4;
