import { useEffect, useState, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import SkeletonCompany from "./skeletons/SkeletonCompany";
import { useTranslation } from "react-i18next";
import { Carousel, IconButton } from "@material-tailwind/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import PrintModule from "./partials/PrintModule";

// get current i8n language

const imageUrl = process.env.REACT_APP_IMAGE_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Analyze = () => {
  const context = useOutletContext();

  if (!context) {
    console.error("useOutletContext returned null or undefined");
  }

  const {
    companyOverviewResults,
    triggerSearchQuery,
    setTriggerSearchQuery,
    search,
    getCompanyOverviewData,
    setPrintNode,
    printNode,
  } = context;

  const [selectedCategory, setSelectedCategory] = useState("light-green");
  // const [feedbackList, setFeedbackList] = useState([]);
  const [categoryAverages, setCategoryAverages] = useState([]);

  const categoryData = useMemo(
    () => companyOverviewResults?.categoryData,
    [companyOverviewResults?.categoryData]
  );

  const getPalette = (paletteName, palettes) =>
    palettes.find((item) => item.name === paletteName) || {};

  const labels = useMemo(() => Object.keys(categoryData || {}), [categoryData]);

  const labelPalette = useMemo(
    () => [
      {
        name: "light-green",
        borderColorSelected: "#32B78B",
        borderColor: "rgba(50, 183, 139, 0.3)",
        backgroundColor: "#32B78B",
      },
      {
        name: "light-blue",
        borderColorSelected: "#479CDA",
        borderColor: "rgba(71, 156, 218, 0.3)",
        backgroundColor: "#479CDA",
      },
      {
        name: "light-brown",
        borderColorSelected: "#CE992F",
        borderColor: "rgba(206, 153, 47, 0.3)",
        backgroundColor: "#CE992F",
      },
      {
        name: "dark-purple",
        borderColorSelected: "#3A2699",
        borderColor: "rgba(58, 38, 153, 0.3)",
        backgroundColor: "#3A2699",
      },
      {
        name: "light-purple",
        borderColorSelected: "#7A4CC4",
        borderColor: "rgba(122, 76, 196, 0.3)",
        backgroundColor: "#7A4CC4",
      },
      {
        name: "dark-red",
        borderColorSelected: "#E2376B",
        borderColor: "rgba(226, 55, 107, 0.3)",
        backgroundColor: "#E2376B",
      },
      {
        name: "hot-orange",
        borderColorSelected: "#fd7e14",
        borderColor: "rgba(253, 126, 20, 0.3)",
        backgroundColor: "#fd7e14",
      },
      {
        name: "sea-blue",
        borderColorSelected: "#007bff",
        borderColor: "rgba(0, 123, 255, 0.3)",
        backgroundColor: "#007bff",
      },
    ],
    []
  );

  const selectedCategoryPalette =
    categoryData && categoryData[selectedCategory]?.insights?.palette;

  let data = {
    labels: [],
    datasets: [],
  };

  const options = {
    type: "line",
    responsive: true,
    elements: {
      line: {
        tension: 0.5,
        fill: false,
        borderWidth: 2,
      },
      point: {
        radius: 5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        border: {
          dash: function (context) {
            if (context.index === 0) {
              return "";
            }
            return [4, 4];
          },
        },
        grid: {
          color: function (context) {
            if (context.index === 0) {
              return "#877FAE";
            }
            return "rgba(58, 38, 153, 0.25)";
          },
        },
        ticks: {
          color: "rgba(58, 38, 153, 0.50)",
          font: { size: "10px", weight: "bold" },
        },
      },
      y: {
        min: 0,
        max: 5,
        grid: {
          color: function (context) {
            if (context.index === 3) {
              return "#3A2699";
            } else if (context.index === 0) {
              return "#877FAE";
            }
            return "rgba(58, 38, 153, 0.25)";
          },
        },
        ticks: {
          color: "rgba(58, 38, 153, 0.50)",
          font: { size: "12px" },
          stepSize: 1, // Ensures only whole numbers are displayed
        },
      },
    },
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (categoryData) {
      if (labels > 0) {
        let newAverages = [];

        labels.map((item) => {
          let sum = 0;
          companyOverviewResults.categoryData[item].scores.map(
            (value) => (sum += value.score)
          );
          newAverages[item] = (
            sum / companyOverviewResults.categoryData[item].scores.length
          ).toFixed(1);
          return null;
        });
        setCategoryAverages(newAverages);
      }
    }
  }, [companyOverviewResults, labels, categoryData]);

  if (categoryData) {
    const categoryKeys = labels;

    if (data.labels.length === 0 && categoryKeys.length > 0) {
      const firstCategoryKey = categoryKeys[0];

      // Initialize data labels
      data.labels = categoryData[firstCategoryKey].scores.map(
        (value) => value.title
      );

      // Initialize datasets
      data.datasets = categoryKeys.map((category) => {
        const paletteName = categoryData[category]?.insights?.palette;
        const palette = getPalette(paletteName, labelPalette);

        return {
          label: category,
          data: companyOverviewResults.categoryData[category].scores.map(
            (score) => score.score.toFixed(1)
          ),
          borderColor:
            selectedCategory === category
              ? palette.borderColorSelected
              : palette.borderColor,
          backgroundColor: palette.backgroundColor,
        };
      });

      // Adjust options if there's only one label
      if (data.labels.length <= 1) options.scales.x.offset = true;
    }
  }

  useEffect(() => {
    if (triggerSearchQuery && search) {
      getCompanyOverviewData(search);
      setTriggerSearchQuery(false);
    }
  }, [
    search,
    triggerSearchQuery,
    getCompanyOverviewData,
    setTriggerSearchQuery,
  ]);

  useEffect(() => {
    if (!companyOverviewResults) {
      getCompanyOverviewData(search);
    }
  }, [getCompanyOverviewData, search, companyOverviewResults]);

  useEffect(() => {
    if (companyOverviewResults?.getFeedback) {
      setPrintNode(false);
    }

    return () => {
      setPrintNode(null);
    };
  }, [companyOverviewResults?.getFeedback, setPrintNode]);

  useEffect(() => {
    // set the selected category to the first category
    if (labels.length > 0) {
      setSelectedCategory(labels[0]);
    }
  }, [labels]);

  const dataForGraph = (
    <div className="bg-white w-full flex p-4">
      <div className="w-1/4 pr-8">
        {labels?.length > 0 &&
          labels.map((category, ind) => {
            return (
              <button
                key={ind}
                className={`graph-btn !bg-white text-white bg-${categoryData[category]?.insights?.palette}`}
              >
                {category}
                <span className="float-right">
                  {categoryAverages[category]}
                </span>
              </button>
            );
          })}
      </div>
      {categoryData && (
        <div className="w-3/4 pl-4">
          <Line options={{ ...options, animation: false }} data={data} />
        </div>
      )}
    </div>
  );

  const addedWidgets = companyOverviewResults?.addedWidgets || {};

  return !companyOverviewResults ? (
    <SkeletonCompany />
  ) : labels?.length > 0 ? (
    <>
      <div className="flex w-full">
        <div className="lg:w-1/3 lg:pr-8 lg:max-w-[270px] w-full">
          {addedWidgets.managers_increase_overall_score && (
            <div className="box-primary small-box py-4 px-5 mb-5">
              <h3>{addedWidgets.managers_increase_overall_score.title}</h3>
              <span className="title-3xl lg:text-[50px]">
                {addedWidgets.managers_increase_overall_score.data}
              </span>
            </div>
          )}

          {addedWidgets.employees_participate_last_round && (
            <div className="box-primary small-box py-4 px-5 mb-5">
              <h3>{addedWidgets.employees_participate_last_round.title}</h3>
              <p className="title-3xl lg:text-[50px]">
                {addedWidgets.employees_participate_last_round.data}
              </p>
            </div>
          )}

          {addedWidgets.managers_decreased_scored_last_survey && (
            <div className="box-primary small-box py-4 px-5 mb-5">
              <h3>
                {addedWidgets.managers_decreased_scored_last_survey.title}
              </h3>
              <p className="title-3xl lg:text-[50px]">
                {addedWidgets.managers_decreased_scored_last_survey.data}
              </p>
            </div>
          )}

          {addedWidgets.qualitative_answers_collected && (
            <div className="box-primary small-box py-4 px-5 mb-5">
              <h3>{addedWidgets.qualitative_answers_collected.title}</h3>
              <p className="title-3xl lg:text-[50px]">
                {addedWidgets.qualitative_answers_collected.data}
              </p>
            </div>
          )}

          <div className="box-white py-4 px-5 mb-5">
            <h3 className="title-primary-base mb-3">
              {t("analyze.company.data_points_title")}
            </h3>
            {addedWidgets.leadership_increase_most_by_percent && (
              <div className="space-x-2 text-[#686285] flex w-full items-center mb-4">
                <div
                  className="w-1/2 break-words hyphens-auto"
                  lang={i18n.language}
                >
                  {addedWidgets.leadership_increase_most_by_percent.data.skill}{" "}
                  {t("analyze.widgets.leadership_increase_most_by_percent")}
                </div>{" "}
                <span
                  className={`w-1/2 bg-${addedWidgets.leadership_increase_most_by_percent.data.palette} pill`}
                >
                  {Number(
                    addedWidgets.leadership_increase_most_by_percent.data
                      .percentage
                  ).toFixed(0)}
                  %{" "}
                </span>
              </div>
            )}

            {addedWidgets.leadership_decrease_most_by_percent && (
              <div className="space-x-2 text-[#686285] w-full flex items-center mb-4">
                <div
                  className="w-1/2 break-words hyphens-auto"
                  lang={i18n.language}
                >
                  {addedWidgets.leadership_decrease_most_by_percent.data.skill}{" "}
                  {t("analyze.widgets.leadership_decrease_most_by_percent")}{" "}
                </div>{" "}
                <span
                  className={`w-1/2 bg-${addedWidgets.leadership_decrease_most_by_percent.data.palette} pill`}
                >
                  {Number(
                    addedWidgets.leadership_decrease_most_by_percent.data
                      .percentage
                  ).toFixed(0)}
                  %{" "}
                </span>
              </div>
            )}

            {addedWidgets.leadership_change_most_least_score && (
              <>
                <div className="w-full space-x-2 text-[#686285] flex items-center mb-4">
                  <div
                    className="w-1/2 break-words hyphens-auto"
                    lang={i18n.language}
                  >
                    {
                      addedWidgets.leadership_change_most_least_score.data
                        .maxChangeSkill
                    }{" "}
                    {t(
                      "analyze.widgets.leadership_change_most_least_score.most"
                    )}
                    {""}
                  </div>
                  <span
                    className={`w-1/2 bg-${addedWidgets.leadership_change_most_least_score.data.maxChangePalette} pill`}
                  >
                    {Number(
                      addedWidgets.leadership_change_most_least_score.data
                        .maxChangeScore
                    ).toFixed(1)}
                  </span>
                </div>
                <div className="w-full space-x-2 text-[#686285] flex items-center mb-4">
                  <div className="w-1/2 break-words hyphens-auto">
                    {
                      addedWidgets.leadership_change_most_least_score.data
                        .minChangeSkill
                    }{" "}
                    {t(
                      "analyze.widgets.leadership_change_most_least_score.least"
                    )}
                    {""}
                  </div>
                  <span
                    className={`w-1/2 bg-${addedWidgets.leadership_change_most_least_score.data.minChangePalette} pill`}
                  >
                    {Number(
                      addedWidgets.leadership_change_most_least_score.data
                        .minChangeScore
                    ).toFixed(1)}
                  </span>
                </div>
              </>
            )}

            {addedWidgets.leadership_highest_score && (
              <div className="space-x-2 w-full text-[#686285] flex items-center mb-4">
                <div
                  className="w-1/2 break-words hyphens-auto"
                  lang={i18n.language}
                >
                  {addedWidgets.leadership_highest_score.data.skill}{" "}
                  {t("analyze.widgets.leadership_highest_score")}
                </div>
                <span
                  className={`w-1/2 bg-${addedWidgets.leadership_highest_score.data.palette} pill`}
                >
                  {Number(
                    addedWidgets.leadership_highest_score.data.score
                  ).toFixed(1)}
                </span>
              </div>
            )}

            {addedWidgets.leadership_lowest_score && (
              <div className="w-full space-x-2 text-[#686285] flex items-center mb-4">
                <div
                  className="w-1/2 break-words hyphens-auto"
                  lang={i18n.language}
                >
                  {addedWidgets.leadership_lowest_score.data.skill}{" "}
                  {t("analyze.widgets.leadership_lowest_score")}
                </div>
                <span
                  className={`w-1/2 bg-${addedWidgets.leadership_lowest_score.data.palette} pill`}
                >
                  {Number(
                    addedWidgets.leadership_lowest_score.data.score
                  ).toFixed(1)}
                </span>
              </div>
            )}

            {addedWidgets.question_lowest_score_all_manager && (
              <div className="outline outline-1 outline-[#b5b1c8] p-2 mb-5">
                <div className="text-[#686285] items-center mb-4">
                  <div>
                    {t("analyze.widgets.question_lowest_score_all_manager")}:
                    {""}
                  </div>
                  <br />
                  <p className="italic">
                    {
                      addedWidgets.question_lowest_score_all_manager.data
                        .question
                    }
                  </p>{" "}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:max-w-full lg:min-w-[calc(100%-270px)]">
          <div className="top-bar">
            <div className="lg:flex mb-8">
              <h3 className="lg:w-1/4 lg:pr-4 w-full title-primary-base">
                {t("analyze.company.graph_title")}
              </h3>
              <p className="text-[#686285] lg:pl-4 lg:w-3/4 w-full">
                {t("analyze.company.graph_text")}
              </p>
            </div>
            <div className="flex">
              <div className="w-1/4 pr-8">
                {labels.map((category) => (
                  <button
                    key={category}
                    className={`graph-btn !bg-white ${
                      category !== selectedCategory
                        ? `text-${categoryData[category]?.insights?.palette}`
                        : `text-white bg-${categoryData[category]?.insights?.palette}`
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                    <span className="float-right">
                      {categoryAverages[category]}
                    </span>
                  </button>
                ))}
              </div>
              {categoryData && (
                <div className="w-3/4 pl-4">
                  <Line options={options} data={data} />
                </div>
              )}
            </div>
            {selectedCategory && categoryData && (
              <div className="lg:flex inner-white-box">
                <div className="lg:w-1/2 w-full lg:pr-6">
                  <h3 className="title-primary-base mb-5">
                    {t("analyze.company.cat_description_title")}
                  </h3>

                  <div
                    className={`survey-box !bg-transparent !m-0 !p-0 w-full element-${selectedCategoryPalette}`}
                  >
                    <span className="survey-image !h-auto">
                      <img
                        src={
                          imageUrl +
                            categoryData[selectedCategory]?.insights?.image
                              ?.url || ""
                        }
                        alt={selectedCategory}
                      />
                      <h3 className="title-md text-center mt-3">
                        {selectedCategory}
                      </h3>
                    </span>
                    <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
                      <h4
                        className={`title-base font-bold mb-4 text-${selectedCategoryPalette}`}
                      >
                        {categoryData[selectedCategory]?.insights.text}
                      </h4>
                      <p className="text-[#686285] leading-[18px]">
                        {categoryData[selectedCategory]?.insights.summary}
                      </p>
                    </div>
                  </div>
                </div>
                {categoryData[selectedCategory]?.questions && (
                  <div className="lg:w-1/2 w-full lg:pl-6">
                    <h3 className="title-primary-base mb-5">
                      {t("analyze.company.questions_title")}
                    </h3>
                    <Carousel
                      className={`rounded-xl bg-${selectedCategoryPalette}-light h-auto text-${selectedCategoryPalette}`}
                      prevArrow={({ handlePrev }) => (
                        <IconButton
                          variant="text"
                          onClick={handlePrev}
                          className={`!absolute top-2/4 -translate-y-2/4 left-2 w-4 h-4 hover:bg-transparent text-${selectedCategoryPalette}`}
                        >
                          <ChevronLeftIcon className="w-4 h-4" />
                        </IconButton>
                      )}
                      nextArrow={({ handleNext }) => (
                        <IconButton
                          variant="text"
                          onClick={handleNext}
                          className={`!absolute top-2/4 -translate-y-2/4 right-2 w-4 h-4 hover:bg-transparent text-${selectedCategoryPalette}`}
                        >
                          <ChevronRightIcon className="w-4 h-4" />
                        </IconButton>
                      )}
                      navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-6 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                          {new Array(length).fill("").map((_, i) => (
                            <span
                              key={i}
                              className={`block h-2 w-2 cursor-pointer rounded-2xl transition-all content-[''] bg-${
                                categoryData[selectedCategory]?.insights
                                  ?.palette
                              } ${activeIndex === i ? "" : " opacity-50"}`}
                              onClick={() => setActiveIndex(i)}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {categoryData[selectedCategory]?.questions &&
                        categoryData[selectedCategory]?.questions?.map(
                          (question) => (
                            <div key={question} className="p-8 pb-12">
                              {question}
                            </div>
                          )
                        )}
                    </Carousel>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {printNode && dataForGraph && (
        <PrintModule dataForGraph={dataForGraph} setPrintNode={setPrintNode} />
      )}
    </>
  ) : (
    <div className="text-center">
      <h3>{t("general.no_results_found")}</h3>
    </div>
  );
};

export default Analyze;
