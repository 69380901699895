import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const EditableTable = ({
  dataList,
  role,
  fields,
  handleUploadUsers,
  setChangedData,
}) => {
  const [editedData, setEditedData] = useState([...dataList]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleCellEdit = (email, field, value) => {
    const updatedData = editedData.map((item) => {
      if (item.email === email) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setEditedData(updatedData);
  };

  const handleSave = async () => {
    setLoading(true);

    // format data to be sent to the server

    const editedDataToSend = editedData.map((item) => {
      const newData = {};
      const acceptedItem = item.acceptedValues ? "new_value" : "old_value";

      for (let [key, value] of Object.entries(item)) {
        value = value[acceptedItem] !== undefined ? value[acceptedItem] : value;

        if (key === "manager_user_ids") {
          newData.manager_emails = value
            .map((manager) => manager.email)
            .join(",");
        } else {
          newData[key] = value;
        }
      }

      return newData;
    });

    await handleUploadUsers(role, editedDataToSend);
  };

  useEffect(() => {
    setEditedData([...dataList]);
  }, [dataList]);

  const handleDataConfirmModalClose = () => {
    setChangedData(null);
  };

  return (
    <div className="modal">
      <div className="overlay" onClick={handleDataConfirmModalClose}></div>
      <div className="modal-body w-1/2">
        <button className="close-button" onClick={handleDataConfirmModalClose}>
          X
        </button>
        <div className="modal-overflow p-4">
          <div className="modal-inner w-full">
            <div className="bg-[#dddfef] p-4 w-full h-full rounded-[22px]">
              <div className="h-[79vh] overflow-auto w-full">
                <table className={`admin-data-table min-w-full table-${role}`}>
                  {/* Table header */}
                  <thead>
                    <tr>
                      {fields.map(({ key, text }) => (
                        <th key={key}>{text}</th>
                      ))}
                      <th>
                        <Fab
                          className="!bg-secondary btn-secondary button"
                          size="small"
                          onClick={(event) => {
                            event.preventDefault();
                            handleSave();
                          }}
                          disabled={
                            editedData.filter(
                              (data) => data.acceptedValues === undefined
                            ).length > 0 || loading
                          }
                          aria-label="edit"
                        >
                          {loading ? (
                            <CircularProgress className="!text-white" />
                          ) : (
                            t("modal.btn.save")
                          )}
                        </Fab>
                      </th>
                      <th scope="col">
                        <span className="sr-only">{t("table.edit_btn")}</span>
                      </th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody>
                    {editedData?.map((item) => (
                      <tr key={item.email}>
                        {fields.map(({ key }) => {
                          const updatedval =
                            // check for new_value key property and old_value key property exist
                            item[key]?.hasOwnProperty("new_value") ||
                            item[key]?.hasOwnProperty("old_value");

                          const isManagerUserIds = key === "manager_user_ids";

                          return (
                            <td key={`${item.email}-${key}`}>
                              {updatedval ? (
                                <div className="flex space-x-2">
                                  <div
                                    className={`${
                                      item[key].new_value ? "line-through" : ""
                                    }`}
                                  >
                                    {isManagerUserIds
                                      ? item[key].old_value
                                          .map(({ email }) => email)
                                          .join(", ")
                                      : item[key].old_value || ""}
                                  </div>
                                  <div>
                                    {isManagerUserIds
                                      ? item[key].new_value
                                          .map(({ email }) => email)
                                          .join(", ")
                                      : item[key].new_value || ""}
                                  </div>
                                </div>
                              ) : isManagerUserIds ? (
                                item[key].map(({ email }) => email).join(", ")
                              ) : (
                                item[key] || ""
                              )}
                            </td>
                          );
                        })}
                        <td>
                          <div className="flex space-x-2 justify-center">
                            <div
                              className={`${
                                item.acceptedValues &&
                                "outline outline-offset-1 outline-1 rounded-lg"
                              }`}
                            >
                              <IconButton
                                onClick={() =>
                                  handleCellEdit(
                                    item.email,
                                    "acceptedValues",
                                    true
                                  )
                                }
                                aria-label="checked"
                              >
                                <CheckIcon />
                              </IconButton>
                            </div>
                            <div
                              className={`${
                                item.acceptedValues === false &&
                                "outline outline-offset-1 outline-1 rounded-lg"
                              }`}
                            >
                              <IconButton
                                onClick={() =>
                                  handleCellEdit(
                                    item.email,
                                    "acceptedValues",
                                    false
                                  )
                                }
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableTable;
