import { useState, useEffect } from "react";
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InitializeOptionsLists from "./modules/options";

import UploadForm from "./Manage/partials/UploadForm";
import CheckIcon from "@mui/icons-material/Check";

const NewSurvey = () => {
  const {
    optionsListChooseListScope,
    optionsHasManagerList,
    optionsManagerInvitesEmployees,
  } = InitializeOptionsLists();

  const { modifyCompany } = useOutletContext();

  const [importStatus, setImportStatus] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [hasFullList, setHasFullList] = useState("disabled");
  const [managerInvitesEmployee, setManagerInvitesEmployee] =
    useState("disabled");
  const [hasManagerList, setHasManagerList] = useState("disabled");
  const [readyToProceed, setReadyToProceed] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    let isReady = false;
    switch (currentStep) {
      case 1:
        isReady = hasFullList !== "disabled";
        break;
      case 2:
        isReady =
          hasManagerList !== "disabled" &&
          managerInvitesEmployee !== "disabled";
        break;
      case 3:
      case 4:
        isReady = importStatus === true;
        break;
      case 5:
        isReady = true;
        break;
      default:
        isReady = false;
    }
    setReadyToProceed(isReady);
  }, [
    currentStep,
    hasFullList,
    hasManagerList,
    managerInvitesEmployee,
    importStatus,
  ]);

  useEffect(() => {
    if (currentStep === 6) {
      navigate(
        `/admin/manage/surveys?newsurvey=true&list=${
          ////here none
          hasFullList === "yes"
            ? "full"
            : hasManagerList === "yes"
            ? "manager"
            : "none"
        }&invite_handler=${
          managerInvitesEmployee === "yes" ? "manager" : "admin"
        }`
      );
    }
  }, [
    currentStep,
    navigate,
    hasFullList,
    hasManagerList,
    managerInvitesEmployee,
  ]);

  const location = useLocation();
  useEffect(() => {
    setCurrentStep(1);
  }, [location, setCurrentStep]);

  const stepHandler = async (direction) => {
    if (direction === "forward") {
      if (currentStep === 1 && hasFullList === "yes") {
        await modifyCompany({
          survey_invite_handler: "admin",
        });
      }

      if (currentStep === 2) {
        await modifyCompany({
          survey_invite_handler:
            managerInvitesEmployee === "yes" ? "manager" : "admin",
        });
      }
    }

    setCurrentStep((current) => {
      const isForward = direction === "forward";

      switch (current) {
        case 1:
          return isForward ? (hasFullList === "yes" ? 3 : 2) : 1;
        case 2:
          return isForward ? (hasManagerList === "yes" ? 3 : 5) : 1;
        case 3:
          return isForward
            ? hasFullList === "no"
              ? 5
              : 4
            : hasFullList === "yes"
            ? 1
            : 2;
        case 4:
          return isForward ? 5 : 3;
        case 5:
          return isForward
            ? 6
            : hasFullList === "yes"
            ? 4
            : hasManagerList === "yes"
            ? 3
            : 2;
        default:
          return isForward ? current + 1 : current - 1;
      }
    });
  };

  return (
    <>
      <div className="flex justify-between items-center top-bar mb-4">
        <button
          onClick={() => stepHandler("backward")}
          disabled={currentStep === 1}
          className="mr-4 disabled:opacity-20 disabled:pointer-events-none title-primary-sm hover:text-opacity-80"
        >
          {t("new_survey.back_btn")}
        </button>
        <button
          onClick={() => stepHandler("forward")}
          disabled={!readyToProceed}
          className="disabled:opacity-20 disabled:pointer-events-none button"
        >
          {currentStep === 5
            ? t("new_survey.done_btn")
            : t("new_survey.next_btn")}
        </button>
      </div>
      {currentStep === 1 && (
        <div className="top-bar">
          <h3 className="title-primary-md mb-4">
            {t("new_survey.step_1.title")}
          </h3>
          <p className="mb-6">{t("new_survey.step_1.text")}</p>
          <div className="inner-white-box lg:flex lg:flex-wrap">
            <div className="lg:w-1/3 w-full mb-4 lg:pr-5">
              <div className="rounded-[22px] p-6 pb-20 bg-[#F3F3FA] h-full relative">
                {hasFullList === "yes" && (
                  <CheckIcon className="float-right -mt-2 -mr-2 !text-[38px] !text-secondary" />
                )}
                <h3 className="title-primary-md mb-4">
                  {t("new_survey.step_1.title_yes")}
                </h3>
                <p className="mb-6 subtitle">
                  {t("new_survey.step_1.text_yes")}
                </p>
                <button
                  className={`button btn-${
                    hasFullList === "yes" ? "secondary" : "primary"
                  } absolute bottom-6 left-6`}
                  onClick={(e) => {
                    setHasFullList("yes");
                  }}
                >
                  {optionsListChooseListScope?.map((item) =>
                    item.value === "yes" ? item.text : false
                  )}
                </button>
              </div>
            </div>
            <div className="lg:w-1/3 w-full mb-4 lg:px-3">
              <div className="rounded-[22px] p-6 pb-20 bg-[#F3F3FA] h-full relative">
                {hasFullList === "no" && (
                  <CheckIcon className="float-right -mt-2 -mr-2 !text-[38px] !text-secondary" />
                )}
                <h3 className="title-primary-md mb-4">
                  {t("new_survey.step_1.title_no")}
                </h3>
                <p className="mb-6 subtitle">
                  {t("new_survey.step_1.text_no")}
                </p>
                <button
                  className={`button btn-${
                    hasFullList === "no" ? "secondary" : "primary"
                  } absolute bottom-6 left-6`}
                  onClick={(e) => {
                    setHasFullList("no");
                  }}
                >
                  {optionsListChooseListScope?.map((item) =>
                    item.value === "no" ? item.text : false
                  )}
                </button>
              </div>
            </div>
            <div className="lg:w-1/3 w-full mb-4 lg:pl-5">
              <div className="rounded-[22px] p-6 pb-20 bg-[#F3F3FA] h-full relative">
                {hasFullList === "none" && (
                  <CheckIcon className="float-right -mt-2 -mr-2 !text-[38px] !text-secondary" />
                )}
                <h3 className="title-primary-md mb-4">
                  {t("new_survey.step_1.title_custom")}
                </h3>
                <p className="mb-6 subtitle">
                  {t("new_survey.step_1.text_custom")}
                </p>
                <button
                  className={`button btn-${
                    hasFullList === "none" ? "secondary" : "primary"
                  } absolute bottom-6 left-6`}
                  onClick={(e) => {
                    setHasFullList("none");
                    navigate("/admin/manage/surveys?newsurvey=true&list=none");
                  }}
                >
                  {t("new_survey.step_1.cta_custom")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <>
          <div className="box-white">
            <h3 className="title-primary-md mb-6">
              {t("new_survey.step_2.title")}
            </h3>
            <p className="mb-4 mt-6">
              {t("new_survey.step_2.manager_handles_invite_text")}
            </p>
            <FormControl sx={{ width: 300 }}>
              <Select
                id="manager-handles_invite"
                onChange={(e) => {
                  setManagerInvitesEmployee(e.target.value);
                }}
                value={managerInvitesEmployee}
              >
                {optionsManagerInvitesEmployees.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.value}
                    disabled={option.disabled || false}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="mb-4 mt-6">
              {t("new_survey.step_2.managers_list_text")}
            </p>
            <FormControl sx={{ width: 300 }}>
              <Select
                id="managers-list"
                onChange={(e) => {
                  setHasManagerList(e.target.value);
                }}
                value={hasManagerList}
              >
                {optionsHasManagerList.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.value}
                    disabled={option.disabled || false}
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </>
      )}
      {(currentStep === 3 || currentStep === 4) && (
        <UploadForm
          importType={
            currentStep === 3
              ? "manager"
              : currentStep === 4
              ? "employee"
              : null
          }
          setImportStatus={setImportStatus}
        />
      )}
      {currentStep === 5 && (
        <div className="box-white">
          <h3 className="title-primary-md mb-6">
            {t("new_survey.step_5.title")}
          </h3>
          <p className="mb-6">{t("new_survey.step_5.text_1")}</p>
          <p className="mb-2">{t("new_survey.step_5.text_2")}</p>
          <ul className="mb-6 list-disc pl-6">
            <li className="mb-2">{t("new_survey.step_5.text_2.li_1")}</li>
            <li className="mb-2">{t("new_survey.step_5.text_2.li_2")}</li>
          </ul>
          <p className="mb-2">{t("new_survey.step_5.text_3")}</p>
          <ul className="mb-6 list-disc pl-6">
            <li>{t("new_survey.step_5.text_3.li_1")}</li>
          </ul>
        </div>
      )}
      <div className="w-full flex flex-1" />
    </>
  );
};

export default NewSurvey;
