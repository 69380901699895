import { useEffect, useRef } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import SkeletonGroups from "./skeletons/SkeletonGroups";
import { useTranslation } from "react-i18next";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PrintModule from "./partials/PrintModule";

const Analyze = (props) => {
  const {
    search,
    getGroupedManagerData,
    groupedManagerSurveyResults,
    triggerSearchQuery,
    setTriggerSearchQuery,
    printNode,
    setPrintNode,
  } = useOutletContext();
  const { t } = useTranslation();

  const dataForGraph = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!groupedManagerSurveyResults || (triggerSearchQuery && search)) {
      const getData = async () => {
        await getGroupedManagerData(search);
        if (triggerSearchQuery) {
          setTriggerSearchQuery(false);
        }
      };
      getData();
    }
  }, [
    getGroupedManagerData,
    search,
    groupedManagerSurveyResults,
    triggerSearchQuery,
    setTriggerSearchQuery,
  ]);

  const RenderManagersList = ({ title, managers }) => {
    return (
      <div className="managers-list">
        <h3 className="title-base mb-5">{title}</h3>
        <ul className="list-disc">
          {managers.length > 0 &&
            managers.map((manager) => (
              <li
                className="cursor-pointer m-1"
                onClick={() =>
                  navigate(`/admin/analyze/managers?group=${manager.id}`)
                }
              >
                {manager.first_name + " " + manager.last_name}
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const inspectHandler = (group) => {
    // destruct manager ids from group and pass them to the url as query params

    const managerIds = group.map((manager) => manager.id).join(",");

    navigate(`/admin/analyze/managers?group=${managerIds}`);
  };

  useEffect(() => {
    if (groupedManagerSurveyResults?.length > 0) {
      setPrintNode(false);
    }

    return () => {
      setPrintNode(null);
    };
  }, [groupedManagerSurveyResults, setPrintNode]);

  return !groupedManagerSurveyResults ? (
    <SkeletonGroups />
  ) : groupedManagerSurveyResults?.length > 0 ? (
    <>
      <div className="w-full" ref={dataForGraph}>
        {groupedManagerSurveyResults.map((group) => {
          let date = new Date(group.starting_date);
          let formattedDate = date.toLocaleDateString("en-GB");
          return (
            <div className="top-bar mb-5">
              <div className="lg:flex lg:flex-wrap">
                <h2 className="title-primary-base lg:w-1/3">
                  {group.title}
                  <br />
                  {group.block_title}
                </h2>
                <p className="subtitle lg:pl-16">{formattedDate}</p>
              </div>
              <div className="inner-white-box lg:flex lg:flex-wrap text-primary/75">
                <div className="lg:w-[30%] lg:pr-8 flex flex-wrap">
                  <span className="w-1/3 pr-2">{t("analyze.groups.rank")}</span>
                  <div className="w-2/3 pl-3 mb-7">
                    <strong className="text-primary block">
                      {t("analyze.groups.highest_ratings")}
                    </strong>
                    {t("analyze.groups.highest_ratings_subtitle")}
                  </div>
                  <span className="w-1/3 pr-2">
                    {t("analyze.groups.distribution")}
                  </span>
                  <div
                    className={`w-2/3 pl-3 mb-7 ${
                      group.managers.top.length === 0 ? "opacity-50" : ""
                    }`}
                  >
                    <div className="bg-secondary w-4/5 text-right p-4 rounded-[22px] pt-14 text-white relative">
                      <PersonOutlineIcon className="align-middle !text-white text-md" />{" "}
                      {group.managers.top.length}
                      {group.managers.top.length > 0 && (
                        <RenderManagersList
                          title={t("analyze.groups.highest_ratings")}
                          managers={group.managers.top}
                        />
                      )}
                    </div>
                  </div>
                  <span className="w-1/3 pr-2">{t("analyze.groups.list")}</span>
                  <div className="w-2/3 pl-3">
                    <p className="mb-1">
                      {group.managers.top.length} {t("analyze.groups.managers")}
                    </p>
                    <button
                      disabled={group.managers.top.length === 0}
                      className="font-bold text-primary disabled:opacity-50"
                      onClick={() => inspectHandler(group.managers.top)}
                    >
                      {t("analyze.groups.inspect")}{" "}
                      <ChevronRightIcon disabled className="!w-5 !h-5 -ml-1" />
                    </button>
                  </div>
                </div>
                <div className="lg:w-1/2 border-l-[1px] border-[#AFAAC9]/30 lg:px-8">
                  <h4 className="font-bold text-primary mb-11">
                    {t("analyze.groups.rest")}
                  </h4>
                  <div
                    className={`bg-[#60AAF4] w-2/3 text-right p-4 rounded-[22px] pt-14 text-white mb-7 relative ${
                      group.managers.remaining.length === 0 ? "opacity-50" : ""
                    }`}
                  >
                    <PersonOutlineIcon className="align-middle !text-white text-md" />{" "}
                    {group.managers.remaining.length}
                    {group.managers.remaining.length > 0 && (
                      <RenderManagersList
                        title={t("analyze.groups.rest")}
                        managers={group.managers.remaining}
                      />
                    )}
                  </div>
                  <p className="mb-1">
                    {group.managers.remaining.length}{" "}
                    {t("analyze.groups.managers")}
                  </p>
                  <button
                    disabled={group.managers.remaining.length === 0}
                    className="font-bold text-primary disabled:opacity-50"
                    onClick={() => inspectHandler(group.managers.remaining)}
                  >
                    {t("analyze.groups.inspect")}{" "}
                    <ChevronRightIcon className="!w-5 !h-5 -ml-1" />
                  </button>
                </div>
                <div className="lg:w-1/5 border-l-[1px] border-[#AFAAC9]/30 lg:pl-8">
                  <div className="mb-7">
                    <strong className="text-primary block">
                      {t("analyze.groups.lowest_ratings")}
                    </strong>
                    {t("analyze.groups.lowest_ratings_subtitle")}
                  </div>
                  <div
                    className={`bg-primary w-4/5 text-right p-4 rounded-[22px] pt-14 text-white mb-7 relative ${
                      group.managers.bottom.length === 0 ? "opacity-50" : ""
                    }`}
                  >
                    <PersonOutlineIcon className="align-middle !text-white text-md" />{" "}
                    {group.managers.bottom.length}
                    {group.managers.bottom.length > 0 && (
                      <RenderManagersList
                        title={t("analyze.groups.lowest_ratings")}
                        managers={group.managers.bottom}
                      />
                    )}
                  </div>
                  <p className="mb-1">
                    {group.managers.bottom.length}{" "}
                    {t("analyze.groups.managers")}
                  </p>
                  <button
                    disabled={group.managers.bottom.length === 0}
                    className="font-bold text-primary disabled:opacity-50"
                    onClick={() => inspectHandler(group.managers.bottom)}
                  >
                    {t("analyze.groups.inspect")}{" "}
                    <ChevronRightIcon className="!w-5 !h-5 -ml-1" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {printNode && dataForGraph && (
        <PrintModule
          dataForGraph={
            <div
              className="w-full bg-white p-4"
              dangerouslySetInnerHTML={{
                __html: dataForGraph.current.outerHTML,
              }}
            />
          }
          setPrintNode={setPrintNode}
        />
      )}
    </>
  ) : (
    <div className="text-center">
      <h3>{t("general.no_results_found")}</h3>
    </div>
  );
};

export default Analyze;
