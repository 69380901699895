import { useEffect, useState, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EditableTable from "./partials/table";

import NewEmployeeModal from "./partials/NewEmployeeModal";
import SkeletonEmployees from "./skeletons/SkeletonEmployees";

const Employees = () => {
  const { getUsers, employeeList, managerList, uploadUsersHandler, notify } =
    useOutletContext();

  const { t } = useTranslation();

  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);

  useEffect(() => {
    // get the list of employees and managers
    getUsers();
  }, [getUsers]);

  const fields = useMemo(
    () => [
      { key: "first_name", text: t("field.first_name"), editable: true },
      { key: "last_name", text: t("field.last_name"), editable: true },
      { key: "email", text: t("field.email"), editable: true },
      {
        key: "manager_user_ids",
        text: t("field.manager"),
        inputType: "select",
        editable: true,
        options:
          managerList
            ?.sort((a, b) => {
              return a.last_name.localeCompare(b.last_name);
            })
            .map(({ first_name, last_name, id }) => ({
              display: `${first_name} ${last_name}`,
              id,
            })) || [],
      },
    ],
    [managerList, t]
  );

  const handleEmployeeAdd = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleEmployeeModalClose = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleEmployeeModalSubmit = async (newEmployeeData) => {
    try {
      const result = await uploadUsersHandler(
        [newEmployeeData],
        "employee",
        true
      );

      let addedEmployee = false;
      let errors = "Employee could not be added";

      let failedUploadedArray = result?.data?.failedUploaded;

      if (failedUploadedArray?.length === 1) {
        // determine if the error is an array or string
        if (typeof failedUploadedArray[0]?.errors === "string") {
          errors = failedUploadedArray[0]?.errors;
        }
      } else if (failedUploadedArray?.length === 0) {
        // if there is no failedUploaded array, then the employee was added successfully
        addedEmployee = true;
      }

      if (addedEmployee) {
        getUsers();
        notify("Employee added successfully", "success");
        setIsEmployeeModalOpen(false);
      } else {
        notify(errors, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <EditableTable
        SkeletonComponent={SkeletonEmployees}
        dataList={employeeList}
        role="employees"
        fields={fields}
        handleAdduser={handleEmployeeAdd}
      />

      <NewEmployeeModal
        isEmployeeModalOpen={isEmployeeModalOpen}
        handleEmployeeModalClose={handleEmployeeModalClose}
        handleEmployeeModalSubmit={handleEmployeeModalSubmit}
        managerList={managerList}
        mode={"new"}
      />
    </>
  );
};

export default Employees;
