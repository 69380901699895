import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
  selectedCustomField,
  handleCustomFieldDeleteConfirmation,
}) {
  const [loading, setLoading] = useState(false);

  const handleClose = async (response) => {
    if (response) {
      setLoading(true);
    }
    handleCustomFieldDeleteConfirmation(response);
  };

  return (
    <div>
      <Dialog
        open={selectedCustomField ? true : false}
        onClose={() => handleClose(false)} // Allow closing the dialog by clicking outside
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {selectedCustomField && selectedCustomField.has_data
            ? "Archive"
            : "Delete"}{" "}
          custom field
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selectedCustomField && selectedCustomField.has_data
              ? "This custom field has data associated with it, and will be archived. Are you sure you want to proceed?"
              : "This custom field has no data associated with it, and will be deleted. Are you sure you want to proceed?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button
            className={`${loading && "opacity-20 pointer-events-none"}`}
            onClick={() => handleClose(true)}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
