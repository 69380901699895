import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LinkEmployeeModal = ({
  employees,
  handleLinkEmployees,
  closeLinkEmployeeModal,
  linkEmployeeModalOpen,
}) => {
  const { t } = useTranslation();

  const [selectedModalEmployees, setSelectedModalEmployees] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (linkEmployeeModalOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }

    return () => {
      document.body.classList.remove("disable-scroll");
      setSearchText("");
      setSelectedModalEmployees([]);
    };
  }, [linkEmployeeModalOpen]);

  const unlinkedEmployees = useMemo(() => {
    return (
      (employees?.length > 0 &&
        employees.filter(
          (employee) =>
            !selectedModalEmployees.includes(employee.id) &&
            (employee.first_name
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
              employee.last_name
                .toLowerCase()
                .includes(searchText.toLowerCase()))
        )) ||
      []
    );
  }, [employees, selectedModalEmployees, searchText]);

  const handleModalEmployeeSelection = (employeeId) => {
    if (selectedModalEmployees.includes(employeeId)) {
      setSelectedModalEmployees((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      );
    } else {
      setSelectedModalEmployees((prevSelected) => [
        ...prevSelected,
        employeeId,
      ]);
    }
  };

  if (!linkEmployeeModalOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="overlay" onClick={closeLinkEmployeeModal}></div>
      <div className="modal-body md:w-1/2">
        <button className="close-button" onClick={closeLinkEmployeeModal}>
          X
        </button>
        <div className="modal-overflow">
          <div className="modal-inner">
            <div className="p-8 w-full">
              <h2 className="text-md font-bold mb-4">
                {t("modal.link_employee.title")}
              </h2>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder={t("modal.link_employee.search_input")}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="border p-2 w-full rounded-md"
                />
              </div>
              <div className="h-80 flex">
                <div className="w-1/3 overflow-y-auto">
                  <div className="text-gray-700 font-bold mb-2">
                    {t("modal.link_employee.selection")}
                  </div>
                  <div className="checkbox-list">
                    {unlinkedEmployees.map((employee) => (
                      <label
                        style={{ width: "90%" }}
                        onClick={() =>
                          handleModalEmployeeSelection(employee.id)
                        }
                        key={employee.id}
                        className={`relative group flex items-center p-2 hover:bg-[#dedff1] font-bold rounded-lg cursor-pointer`}
                      >
                        {employee.first_name} {employee.last_name}
                        <span className="hidden group-hover:block absolute top-1 right-0 cursor-pointer p-1 text-green-500">
                          ✔
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="w-2/3 overflow-y-auto">
                  <div className="text-gray-700 font-bold mb-2">
                    {t("modal.link_employee.selected_employees")}
                  </div>

                  {selectedModalEmployees.map((employeeId) => {
                    const employee = employees.find(
                      (employee) => employee.id === employeeId
                    );
                    return (
                      <li
                        onClick={() =>
                          handleModalEmployeeSelection(employee.id)
                        }
                        className={`relative group flex w-full p-2 rounded-lg my-2 bg-[#dedff1] cursor-pointer`}
                        key={employee.id}
                      >
                        <div className="flex items-center cursor-pointer">
                          <label className="text-gray-700 cursor-pointer">
                            {employee.first_name} {employee.last_name}
                          </label>
                          <span className="hidden group-hover:block absolute top-1 right-0 cursor-pointer p-1 text-red-500">
                            ✖
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className="mt-auto pt-4 flex justify-end space-x-2">
                <button
                  disabled={selectedModalEmployees.length === 0}
                  onClick={() => handleLinkEmployees(selectedModalEmployees)}
                  className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {t("modal.link_employee.link_button")}
                </button>
                <button
                  onClick={closeLinkEmployeeModal}
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-300 ease-in-out"
                >
                  {t("modal.link_employee.cancel_button")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkEmployeeModal;
