import { useTranslation } from "react-i18next";
const SurveyStep3 = ({
  surveyData,
  setStepNumber,
  surveyAnswers,
  gatherSurveyAnswers,
  submitSurveyHandler,
  surveyType,
}) => {
  const { t } = useTranslation();
  return (
    <section className="container flex flex-wrap py-[85px] mx-auto">
      <div className="step-banner-horizontal"></div>
      <div className="w-full text-center mb-8">
        <h2 className="title-primary mb-2">{t("step3.title")}</h2>
        <p className="subtitle">
          {surveyType === "user"
            ? t("step3.description")
            : t("step3.description-self")}
        </p>
      </div>
      {surveyData?.questions?.map((question) => (
        <div className="lg:w-1/3 px-6 mb-[67px]" key={question.id}>
          <label
            htmlFor={`textarea-${question.id}`}
            className="inline-block m-0 align-middle title-primary-md mb-5"
          >
            {question.text}
          </label>
          <textarea
            onChange={(e) => gatherSurveyAnswers(question, e.target.value)}
            value={surveyAnswers[question.id]}
            id={`textarea-${question.id}`}
            className="textarea"
          ></textarea>
        </div>
      ))}

      <div className="text-center w-full">
        <button
          className="button bg-secondary border-0 xl:w-[335px] lg:w-[280px] w-full text-black py-4 font-medium mb-5"
          onClick={() => {
            // set survey_complete as true. Informs backend to close survey
            submitSurveyHandler({ final_step: true });
          }}
        >
          {t("step3.cta")}
        </button>
      </div>
    </section>
  );
};

export default SurveyStep3;
