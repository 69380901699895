import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "./partials/dialog";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Settings = (props) => {
  const {
    customFields,
    userDetails,
    modifyCompany,
    updateCustomFields,
    createCustomFields,
    notify,
    getWidgets,
    getLanguages,
    setCompanyOverviewResults,
  } = useOutletContext();

  const [editedCompanyData, setEditedCompanyData] = useState(
    userDetails.company_id
  );

  const [newCustomField, setNewCustomField] = useState({
    field_description: "",
  });

  const [selectedCustomField, setSelectedCustomField] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);

  const { t } = useTranslation();

  // Update company data
  const handleCompanyDataChange = (e) => {
    let { name, value, checked } = e.target;

    // e.target.value is a string but the backend expects a number for default_language
    if (name === "default_language") {
      value = Number(value);
    }

    // available_languages is an array of numbers related to language ids set on strapi
    if (name === "available_languages") {
      value = editedCompanyData.available_languages || [];

      if (checked) {
        value.push(Number(e.target.value));
      } else {
        // ensure one language is always selected

        if (value.length === 1) {
          notify("You  must have one language selected", "error");
        } else {
          value = value.filter((id) => id !== Number(e.target.value));
        }
      }
    }

    setEditedCompanyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ChevronRightIcon
        className={className}
        style={{ ...style, fill: "black" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ChevronLeftIcon
        className={className}
        style={{ ...style, fill: "black" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // update company data state when userDetails prop changes
  useEffect(() => {
    const getWidgets = userDetails.company_id.widgets.map((widget) => {
      return widget.id;
    });

    const getDefaultLanguage = Number(
      userDetails.company_id.default_language.id
    );

    const getAvailableLanguages =
      userDetails.company_id.available_languages.map((language) => {
        return language.id;
      });

    const companyDetails = {
      ...userDetails.company_id,
      widgets: getWidgets,
      default_language: getDefaultLanguage,
      available_languages: getAvailableLanguages,
    };

    setEditedCompanyData(companyDetails);
  }, [userDetails]);

  // Filter the available languages based on the selected languages
  const filteredAvailableLanguages = languages?.data?.filter((language) =>
    editedCompanyData.available_languages.includes(language.id)
  );

  // Save changes to company data
  const saveCompanyData = useCallback(async () => {
    await modifyCompany(editedCompanyData);
    setCompanyOverviewResults(null);

    notify(t("settings.data.saved"), "success");
  }, [editedCompanyData, modifyCompany, notify, setCompanyOverviewResults, t]);

  const handleNewCustomFieldChange = (e) => {
    const { name, value } = e.target;
    setNewCustomField((prevField) => ({
      ...prevField,
      [name]: value,
    }));
  };

  const addCustomField = async () => {
    const addfieldresults = await createCustomFields(newCustomField);
    if (addfieldresults) {
      setNewCustomField({ field_description: "" });
    }
  };

  const handleCustomFieldDeleteConfirmation = useCallback(
    async (confirmed) => {
      if (confirmed) {
        await updateCustomFields(selectedCustomField.id);
        // take the selected custom field and delete or archive it
      }
      setSelectedCustomField(null); // Close the dialog
    },
    [selectedCustomField, updateCustomFields]
  );

  const getWidgetsData = useCallback(async () => {
    const data = await getWidgets();
    setWidgets(data);
  }, [getWidgets]);

  const getLanguagesData = useCallback(async () => {
    setIsLoadingLanguages(true);
    const data = await getLanguages();
    setLanguages(data);
    setIsLoadingLanguages(false);
  }, [getLanguages]);

  useEffect(() => {
    if (userDetails) {
      getWidgetsData();
    }
  }, [getWidgetsData, userDetails]);

  useEffect(() => {
    getLanguagesData();
  }, [getLanguagesData]);

  const handleWidgetClick = async (widgetId) => {
    const updatedCompanyData = { ...editedCompanyData };
    if (!updatedCompanyData.widgets) {
      updatedCompanyData.widgets = [];
    }
    if (updatedCompanyData.widgets.includes(widgetId)) {
      updatedCompanyData.widgets = updatedCompanyData.widgets.filter(
        (id) => id !== widgetId
      );
    } else {
      updatedCompanyData.widgets.push(widgetId);
    }
    setEditedCompanyData(updatedCompanyData);
  };

  return (
    <>
      {selectedCustomField && (
        <Dialog
          selectedCustomField={selectedCustomField}
          handleCustomFieldDeleteConfirmation={
            handleCustomFieldDeleteConfirmation
          }
        />
      )}
      <div className="flex items-center top-bar mb-4">
        <h1 className="title-primary-md">{t("settings.title")}</h1>
      </div>
      <div className="h-[80vh] overflow-y-auto overflow-x-hidden relative">
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">{t("settings.data.title")}</h2>
          <div className="inner-white-box text-primary/75">
            <div className="mb-4">
              <label className="w-32">{t("settings.data.name")}</label>
              <input
                type="text"
                name="name"
                value={editedCompanyData?.name || ""}
                onChange={handleCompanyDataChange}
              />
            </div>
            <div className="mb-4">
              <label className="w-32">{t("settings.data.address")}</label>
              <input
                type="text"
                name="address_street"
                value={editedCompanyData?.address_street || ""}
                onChange={handleCompanyDataChange}
              />
            </div>
            <div className="mb-4">
              <label className="w-32">
                {t("settings.data.address_number")}
              </label>
              <input
                type="text"
                name="address_number"
                value={editedCompanyData?.address_number || ""}
                onChange={handleCompanyDataChange}
              />
            </div>
            {/* Add more fields as needed */}
            <button
              className="button btn-primary border-none"
              onClick={saveCompanyData}
            >
              {t("settings.data.btn")}
            </button>
          </div>
        </div>
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">{t("settings.language_options")}</h2>
          <div className="inner-white-box text-primary/75">
            {isLoadingLanguages ? (
              <p>Loading languages...</p>
            ) : (
              <>
                <div className="mb-4">
                  <div className="mb-2">
                    <label>{t("settings.available_languages")}</label>
                  </div>

                  <div className="flex flex-wrap -mx-2">
                    {languages?.data?.map((language) => (
                      <label
                        key={language.id}
                        className={`${
                          !editedCompanyData.available_languages.includes(
                            language.id
                          )
                            ? "bg-purple-900 shadow-lg"
                            : "bg-purple-200 hover:bg-purple-300 transition duration-300 shadow-md"
                        } rounded-lg cursor-pointer p-2 m-2 !text-white`}
                      >
                        <input
                          type="checkbox"
                          id={`language_${language.id}`}
                          name="available_languages"
                          value={language?.id || ""}
                          checked={editedCompanyData.available_languages.includes(
                            language.id
                          )}
                          onChange={handleCompanyDataChange}
                          className="hidden"
                        />
                        {language.attributes.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="mb-2">
                    <label>{t("settings.default_language")}</label>
                  </div>
                  <select
                    className="bg-[#F8F8FC] px-[20px] py-[16px] mb-5 rounded-lg !w-64"
                    name="default_language"
                    value={editedCompanyData?.default_language || ""}
                    onChange={handleCompanyDataChange}
                  >
                    {filteredAvailableLanguages?.map((language) => (
                      <option key={language.id} value={language.id}>
                        {language.attributes.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <div className="flex justify-between items-center mt-8">
              <button
                className="button btn-primary border-none"
                onClick={saveCompanyData}
              >
                {t("settings.data.save_language")}
              </button>
              <div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-purple-900 rounded-lg"></div>
                  <span>{t("settings.not_selected")}</span>
                  <div className="w-4 h-4 bg-purple-200 rounded-lg"></div>
                  <span>{t("settings.selected")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">
            {t("settings.custom_fields.title")}
          </h2>
          <div className="inner-white-box text-primary/75">
            <div className="lg:flex lg:flex-wrap -mx-3">
              {customFields.map((field) => {
                return (
                  <div className="lg:w-1/4 px-3 mb-6 relative" key={field.id}>
                    <span
                      onClick={() => setSelectedCustomField(field)}
                      className={`${
                        field.has_data ? "round-archive" : "round-delete"
                      }`}
                    ></span>
                    <input
                      readOnly
                      className="w-full"
                      type="text"
                      name="field_description"
                      value={field?.field_description || ""}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">{t("settings.new_field.title")}</h2>
          <div className="inner-white-box text-primary/75">
            <div className="mb-6">
              <label className="w-32">{t("settings.new_field.name")}</label>
              <input
                type="text"
                name="field_description"
                value={newCustomField?.field_description || ""}
                onChange={handleNewCustomFieldChange}
              />
            </div>
            <button
              className="button btn-primary border-none"
              onClick={addCustomField}
            >
              {t("settings.new_field.btn")}
            </button>
          </div>
        </div>
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">{t("settings.choose_widget")}</h2>
          <div className="inner-white-box text-primary/75">
            <Slider {...settings}>
              {widgets?.length > 0 &&
                widgets.map((widget) => {
                  return (
                    <div
                      key={widget.id}
                      className={`text-white py-4 px-5 mb-5 w-48 h-32 break-words ${
                        editedCompanyData.widgets &&
                        editedCompanyData.widgets.includes(widget.id)
                          ? "bg-purple-200 hover:bg-purple-300 transition duration-300 shadow-md"
                          : "bg-purple-900 shadow-lg"
                      } rounded-lg cursor-pointer`}
                      onClick={() => handleWidgetClick(widget.id)}
                    >
                      <h3>{widget.text}</h3>
                    </div>
                  );
                })}
            </Slider>

            <div className="flex justify-between items-center mt-8">
              <button
                onClick={saveCompanyData}
                className="button btn-primary border-none"
              >
                {t("settings.save_widgets")}
              </button>
              <div>
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 bg-purple-900 rounded-lg"></div>
                  <span>{t("settings.not_selected")}</span>
                  <div className="w-4 h-4 bg-purple-200 rounded-lg"></div>
                  <span>{t("settings.selected")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-bar mb-5">
          <h2 className="title-primary-md">{t("settings.other_settings")}</h2>
          <div className="inner-white-box text-primary/75">
            <div className="flex space-x-4">
              <div className="mb-4">
                <div className="mb-2">
                  <label>{t("settings.academy_contents")}</label>
                </div>
                <select
                  className="bg-[#F8F8FC] px-[20px] py-[16px] mb-5 rounded-lg !w-64"
                  name="academy_content_disabled"
                  value={editedCompanyData?.academy_content_disabled || false}
                  onChange={handleCompanyDataChange}
                >
                  <option value={true}>{t("settings.disabled")}</option>
                  <option value={false}>{t("settings.enabled")}</option>
                </select>
              </div>
              <div className="mb-4">
                <div className="mb-2">
                  <label className="!w-full">
                    {t("settings.self_survey_required")}
                  </label>
                </div>
                <select
                  className="bg-[#F8F8FC] px-[20px] py-[16px] mb-5 rounded-lg !w-64"
                  name="self_survey_required"
                  value={editedCompanyData?.self_survey_required || false}
                  onChange={handleCompanyDataChange}
                >
                  <option value={false}>{t("settings.disabled")}</option>
                  <option value={true}>{t("settings.enabled")}</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                className="button btn-primary border-none"
                onClick={saveCompanyData}
              >
                {t("settings.save_other_settings")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
