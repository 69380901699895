import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const NewGroupModal = ({
  isGroupModalOpen,
  handleGroupModalClose,
  handleGroupModalSubmit,
  managerList,
}) => {
  const [newGroup, setNewGroup] = useState({
    tag: "",
    manager_user_ids: [],
  });

  const [errors, setErrors] = useState({
    tag: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewGroup((prevGroup) => ({
      ...prevGroup,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    if (name === "tag") {
      if (value.length < 2) {
        newErrors.tag = "This field must be at least 2 characters";
      } else {
        newErrors.tag = "";
      }
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      handleGroupModalSubmit(newGroup);
    }
  };

  useEffect(() => {
    if (!isGroupModalOpen) {
      setNewGroup({
        tag: "",
        manager_user_ids: [],
      });
      setErrors({
        tag: "",
      });
    }
  }, [isGroupModalOpen]);

  return (
    <>
      {isGroupModalOpen && (
        <div className="modal">
          <div className="overlay" onClick={handleGroupModalClose}></div>
          <div className="modal-body md:w-1/3">
            <button className="close-button" onClick={handleGroupModalClose}>
              X
            </button>
            <div className="modal-overflow">
              <div className="modal-inner">
                <div className="w-full p-9">
                  <h3 className="title-primary-md mb-6">New Group</h3>
                  <div className="mb-4">
                    <label
                      htmlFor="Group-first-name"
                      className="block font-medium mb-1"
                    >
                      Group Name
                    </label>
                    <input
                      name="tag"
                      type="text"
                      id="Group-first-name"
                      value={newGroup.tag || ""}
                      onChange={handleChange}
                    />
                    {errors.tag && (
                      <div className="text-red-500">{errors.tag}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="Group-manager"
                      className="block font-medium mb-1"
                    >
                      Manager
                    </label>
                    <FormControl className="w-full">
                      <Select
                        name="manager_user_ids"
                        id="Group-manager"
                        value={newGroup.manager_user_ids || []}
                        onChange={handleChange}
                        multiple
                      >
                        {managerList.map(({ first_name, last_name, id }) => (
                          <MenuItem key={id} value={id}>
                            {first_name || ""} {last_name || ""}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="disabled:opacity-20 disabled:pointer-events-none button btn-primary min-w-[100px]"
                      disabled={!isFormValid() || !newGroup.tag}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewGroupModal;
