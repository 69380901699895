import { useTranslation } from "react-i18next";

const InitializeOptionsLists = () => {
  const { t } = useTranslation();

  const optionsListChooseListScope = [
    { text: t("field.options_choose"), value: "disabled", disabled: true },
    { text: t("field.options_full_list"), value: "yes" },
    { text: t("field.options.partial_list"), value: "no" },
  ];

  const optionsHasManagerList = [
    {
      text: t("field.have_list_managers"),
      value: "disabled",
      disabled: true,
    },
    { text: t("field.yes"), value: "yes" },
    { text: t("field.no"), value: "no" },
  ];

  const optionsManagerInvitesEmployees = [
    {
      text: t("field.will_managers_add_employees"),
      value: "disabled",
      disabled: true,
    },
    { text: t("field.yes"), value: "yes" },
    { text: t("field.no"), value: "no" },
  ];

  const availableManagerFields = [
    { text: t("field.first_name"), value: "first_name" },
    { text: t("field.last_name"), value: "last_name" },
    { text: t("field.email"), value: "email" },
  ];

  const availableEmployeeFields = [
    { text: t("field.first_name"), value: "first_name" },
    { text: t("field.last_name"), value: "last_name" },
    { text: t("field.email"), value: "email" },
    { text: t("field.manager_emails"), value: "manager_emails" },
  ];

  return {
    optionsListChooseListScope,
    optionsHasManagerList,
    optionsManagerInvitesEmployees,
    availableManagerFields,
    availableEmployeeFields,
  };
};

export default InitializeOptionsLists;
