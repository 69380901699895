import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function MultiSelect({
  options,
  selectedOptions,
  setSelectedOptions,
  readOnly,
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      readOnly={readOnly}
      fullWidth
      multiple
      limitTags={1}
      value={selectedOptions}
      onChange={(_event, newValue) => {
        setSelectedOptions(newValue.map((option) => option.id));
      }}
      disableCloseOnSelect
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => `${option?.display || ""}`}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.id}>
            {option?.display || ""}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
  );
}
