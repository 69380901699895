import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Admin from "./pages/admin/index";
import NewSurvey from "./pages/admin/NewSurvey";
import AnalyzeGroups from "./pages/admin/Analyze/Groups";
import AnalyzeManagers from "./pages/admin/Analyze/Managers";
import AnalyzeCompany from "./pages/admin/Analyze/Company";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login";
import LoginRedirect from "./pages/LoginRedirect";
import SurveySteps from "./pages/SurveySteps";
import SurveyResults from "./pages/SurveyResults";
import ContentCard from "./pages/ContentCard";
import Managers from "./pages/admin/Manage/Managers";
import Surveys from "./pages/admin/Manage/Surveys";
import Groups from "./pages/admin/Manage/Groups";
import Employees from "./pages/admin/Manage/Employees";
import Uploads from "./pages/admin/Manage/Uploads";
import Settings from "./pages/admin/Manage/Settings";

import AnalyzeHome from "./pages/admin/Analyze/index";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const notify = (message, type = null) => {
    if (type === "success") {
      toast.success(message);
    } else if (type === "error") {
      toast.error(message);
    } else if (type === "info" || type === null) {
      toast(message);
    }
  };

  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ProtectedRoute notify={notify} />}>
            <Route path="admin" element={<Admin />}>
              <Route path="newsurvey" element={<NewSurvey />} />
              <Route path="manage">
                <Route path="managers" element={<Managers />} />
                <Route path="surveys" element={<Surveys />} />
                <Route path="groups" element={<Groups />} />
                <Route path="employees" element={<Employees />} />
                <Route path="uploads" element={<Uploads />} />
                <Route path="settings" element={<Settings />} />
              </Route>
              <Route path="analyze" element={<AnalyzeHome />}>
                <Route path="company" element={<AnalyzeCompany />} />
                <Route path="groups" element={<AnalyzeGroups />} />
                <Route path="managers" element={<AnalyzeManagers />} />
              </Route>
            </Route>
            <Route path="managers">
              <Route path="survey/results" element={<SurveyResults />} />
              <Route path="academy/:id" element={<ContentCard />} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/connect/auth0" element={<LoginRedirect />} />
          <Route
            path="/self-survey/:survey_name/:user_survey_id"
            element={<SurveySteps type="self" />}
          />
          <Route
            path="/user-survey/:survey_name/:user_survey_id"
            element={<SurveySteps type="user" />}
          />
          <Route
            path="/user-survey/:survey_name/"
            element={<SurveySteps type="user" />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
