import React, { useEffect, useState, useCallback } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import {
  axiosGetUserData,
  axiosGetSurveyRoute,
  axiosSendInviteRoute,
} from "./helpers/axios";
import logo from "./images/logo.svg";

import i18n from "i18next";

const ProtectedRoute = ({ notify }) => {
  const cookies = new Cookies();

  const { t } = useTranslation();

  const [isAuth, setIsAuth] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const frontendurl = `${process.env.REACT_APP_FRONTEND_URL}`;

  const token = cookies.get("veemind-app-token");

  const logOut = () => {
    cookies.remove("veemind-app-token", { path: "/" });
    window.location.href = `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/v2/logout?returnTo=${process.env.REACT_APP_FRONTEND_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`;
  };

  const { pathname } = useLocation();

  const getUserDetails = useCallback(async () => {
    try {
      const result = await axiosGetUserData({
        params: { populate: "deep" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        setIsAuth(true);
        setUserDetails(result.data);

        // if user is admin and company has default language set else use english browser language

        if (
          result?.data?.role?.name === "Admin" ||
          result?.data?.role?.name === "Manager"
        ) {
          if (result?.data?.company_id?.default_language?.code) {
            i18n.changeLanguage(result.data.company_id.default_language.code);
          }
        } else {
          // get browser language
          const browserLanguage = navigator.language || "en";

          // set language to browser language
          i18n.changeLanguage(browserLanguage);
        }
      }
    } catch (err) {
      setIsAuth(false);
      setUserDetails(null);
      console.log(err);
    }
  }, [token]);

  const getSurveyResults = useCallback(
    async (params) => {
      try {
        const result = await axiosGetSurveyRoute({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { ...params },
        });

        if (result.data) {
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const sendInvite = useCallback(
    async (data) => {
      try {
        const result = await axiosSendInviteRoute({
          data: { data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          return result.data;
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
      }
    },
    [token, notify]
  );

  useEffect(() => {
    !isAuth && getUserDetails();
  }, [pathname, getUserDetails, isAuth]);

  if (isAuth === null) {
    // Show loading or authentication check in progress
    return (
      <div className="w-full h-[100vh] bg-loading overflow-hidden bg-[#dedff1]">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <img src={logo} width="400" height="86" alt="VeeMind" />
          <h3 className="title-primary-md font-normal tracking-wider text-center mt-6">
            {t("splash.tag_line")}
          </h3>
          <div className="progress-bar !w-1/2 mx-auto mt-4 !h-2 bg-opacity-20">
            <span className="!bg-primary !h-2 animation-progress-bar"></span>
          </div>
        </div>
      </div>
    );
  }

  if (isAuth === false) {
    // User is not authenticated, navigate to login page
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (isAuth === true && userDetails) {
    if (
      userDetails.company_id === null ||
      userDetails.company_id.setup_completed === false
    ) {
      return (
        <>
          <div className="p-4">
            <div>Please contact administrator for further instructions.</div>
            <div className="pt-2">
              <button className="button" onClick={logOut}>
                Logout
              </button>
            </div>
          </div>
        </>
      );
    }
    // User is authenticated, render the protected routes
    if (userDetails.role.name === "Admin") {
      if (!pathname.startsWith("/admin") && !pathname.startsWith("/managers")) {
        return <Navigate to={{ pathname: "/admin/analyze/company" }} replace />;
      }
    } else if (userDetails.role.name === "Manager") {
      // Managers are only allowed to enter the "/survey" subroute
      if (!pathname.startsWith("/managers")) {
        return (
          <Navigate to={{ pathname: "/managers/survey/results" }} replace />
        );
      }
    }

    return (
      <>
        <Outlet
          context={{
            token,
            notify,
            userDetails,
            getUserDetails,
            frontendurl,
            getSurveyResults,
            sendInvite,
            logOut,
          }}
        />
      </>
    );
  }
};

export default ProtectedRoute;
