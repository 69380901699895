import { useState, useCallback, useEffect } from "react";

import Header from "../modules/Header";
import Footer from "../modules/Footer";

// import Img2 from "../images/marketing.jpg";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import { useParams, useOutletContext } from "react-router-dom";

import Cookies from "universal-cookie";

import { axiosGetAcademyRoute } from "../helpers/axios";

const cookies = new Cookies();

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ContentCard = () => {
  const [academyContent, setAcademyContent] = useState();

  const { logOut } = useOutletContext();

  const token = cookies.get("veemind-app-token");

  const { t } = useTranslation();

  let { id } = useParams();

  const getSurvey = useCallback(
    async (id) => {
      try {
        let result;

        result = await axiosGetAcademyRoute(`/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          setAcademyContent(result.data);
        }
      } catch (err) {
        console.log(err.response.data.error.message);
      }
    },
    [token]
  );

  useEffect(() => {
    getSurvey(id);
  }, [getSurvey, id]);
  const category = academyContent?.categories[0];
  const skill = academyContent?.skills[0];
  const exercises_content = academyContent?.exercises;
  const reading_time = Math.round(
    academyContent?.exercises?.split(" ").length / 200
  );

  return (
    <div>
      <Header logOut={logOut} />
      {academyContent && (
        <section className="content-card">
          <div className="container lg:px-0 flex flex-wrap pt-[170px] pb-[80px] mx-auto lg:w-[971px]">
            <div className="lg:w-1/2 w-full lg:pr-10 lg:mb-[90px] mb-[34px]">
              <span
                className="card-image"
                style={{
                  backgroundImage:
                    "url(" + imageUrl + academyContent?.header_image?.url + ")",
                }}
              ></span>
            </div>

            <div className="lg:w-1/2 w-full lg:pl-10 lg:mb-[90px] mb-[34px] self-center">
              <h1 className="card-title">{academyContent.core_message}</h1>
            </div>

            <div className="w-full box-white font-inter flex flex-wrap px-0 mb-7 lg:py-7 py-0">
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10">
                <span
                  className={`icon-border-rounded icon-lg icon-${category.title}`}
                >
                  <img
                    src={imageUrl + category.icon?.url}
                    alt={category.title}
                    className="icon-inner-img"
                  />
                </span>
                <div className="inline-block align-top pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767]">
                    {t("academy.category.title")}
                  </span>
                  <h3 className="title-primary-lg">{category.title}</h3>
                </div>
              </div>
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10 relative after:content-[''] after:absolute lg:after:left-0 after:left-10 lg:after:w-[1px] lg:after:h-auto after:h-[1px] lg:after:bottom-0 lg:after:right-auto after:right-10 after:bg-[#3A3A3A] after:top-0 after:opacity-25">
                <span className={`bg-${category.title} align-top icon-lg`}>
                  <img
                    src={imageUrl + skill.icon?.url}
                    alt={skill.title}
                    className="icon-inner-img"
                  />
                </span>
                <div className="inline-block align-middle pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767] ">
                    {t("academy.subcategory.title")}
                  </span>
                  <h3 className="title-primary-lg" lang="en">
                    {skill.title}
                  </h3>
                </div>
              </div>
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10 relative after:content-[''] after:absolute lg:after:left-0 after:left-10 lg:after:w-[1px] lg:after:h-auto after:h-[1px] lg:after:bottom-0 lg:after:right-auto after:right-10 after:bg-[#3A3A3A] after:top-0 after:opacity-25">
                <span className="icon-clock icon-lg"></span>
                <div className="inline-block align-middle pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767]">
                    {t("academy.reading_time")}
                  </span>
                  <h3 className="title-primary-lg">
                    {reading_time > 0 ? reading_time : 1} {t("academy.minutes")}
                  </h3>
                </div>
              </div>
            </div>

            <div className="w-full box-white mb-7 px-11">
              <h2 className="border-title">{t("academy.intro.title")}</h2>
              <p className="text-lg text-[#585371]">
                {academyContent.introduction}
              </p>
            </div>

            <div className="w-full box-white mb-7 px-11 dark:bg-[rgba(255,255,255,0.1)]">
              <h2 className="border-title dark:text-white dark:border-white">
                {t("academy.content.title")}
              </h2>
              <div className="card-content">
                <ReactMarkdown>{exercises_content}</ReactMarkdown>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer position="static" />
    </div>
  );
};

export default ContentCard;
