import React, { useState, useEffect } from "react";
import MultiSelect from "../../modules/MultiSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const TableRow = ({ item, fields, editMode, setTableChanges, t, errors }) => {
  const [localItem, setLocalItem] = useState({ ...item });

  useEffect(() => {
    setLocalItem({ ...item });
  }, [item]);

  const handleFieldEdit = (field, value) => {
    setLocalItem((prevItem) => ({
      ...prevItem,
      [field]: value,
    }));

    setTableChanges((prevChanges) => {
      const existingChangeIndex = prevChanges.findIndex(
        (change) => change.id === item.id
      );

      const updatedItem = {
        ...item,
        [field]: value,
      };

      if (existingChangeIndex !== -1) {
        // If there's an existing change for the item, update it
        return prevChanges.map((change, index) =>
          index === existingChangeIndex ? updatedItem : change
        );
      } else {
        // If no existing change, add a new change for the item
        return [...prevChanges, updatedItem];
      }
    });
  };

  const hasGeneralError =
    typeof errors === "string" && errors.length > 0 && errors;

  return (
    <tr key={localItem.id}>
      {fields.map(({ key, inputType, options, editable }) => {
        const value = Array.isArray(localItem[key])
          ? localItem[key]
          : [localItem[key]];

        const hasFieldError =
          Array.isArray(errors) &&
          errors?.length > 0 &&
          errors.find((err) => err.path.some((p) => p === key));

        const selectedOptions = [];
        const remainingOptions = [];
        options?.forEach((option) => {
          if (value.includes(option.id)) {
            selectedOptions.push(option);
          } else {
            remainingOptions.push(option);
          }
        });

        const sortedOptions = [...selectedOptions, ...remainingOptions];

        return (
          <td
            title={hasGeneralError ? hasGeneralError : ""}
            className={`${hasGeneralError && "!bg-red-100"}`}
            key={`${localItem.id}-${key}`}
          >
            {inputType === "select" ? (
              <MultiSelect
                readOnly={!editable || !editMode}
                options={sortedOptions}
                selectedOptions={options.filter((option) =>
                  value.includes(option.id)
                )}
                setSelectedOptions={(newValue) =>
                  handleFieldEdit(key, newValue)
                }
              />
            ) : (
              <input
                title={hasFieldError ? hasFieldError.message : ""}
                className={
                  `${hasFieldError && "!outline-red-500 !outline"}` +
                  " w-full lg:min-w-[100px2]"
                }
                readOnly={!editable || !editMode}
                type="text"
                value={localItem[key] || ""}
                onChange={(e) => handleFieldEdit(key, e.target.value)}
              />
            )}
          </td>
        );
      })}
      {editMode && (
        <td className="text-right">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={!editMode}
                checked={localItem.archived}
                onChange={(e) => handleFieldEdit("archived", e.target.checked)}
              />
            }
            label={t(`table.archive_btn`)}
          />
        </td>
      )}
    </tr>
  );
};

export default React.memo(TableRow);
