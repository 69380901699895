import React from "react";

const SkeletonSurveys = () => {
  return (
    <div className="lg:flex lg:flex-wrap animate-pulse">
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
      <div className="box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative">
        <div className="skeleton-title !w-4/5 mb-3"></div>
        <div className="skeleton-title !w-3/5 mb-6"></div>
        <div className="skeleton-title !h-2.5 !w-1/3 mb-6"></div>

        <div className="progress-bar bg-opacity-20 mb-4">
          <span className="!bg-[#9ca3af] !w-2/5"></span>
        </div>
        <div className="skeleton-text !w-1/2 mb-4"></div>
        <span className="status-green h-6 !bg-[#e2e4ea] !mt-0"></span>
      </div>
    </div>
  );
};

export default SkeletonSurveys;
