import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "splash.tag_line": "Leadership measured as data",
      // general info
      "general.no_results_found": "No results found",
      "general.copied_to_clipboard": "Copied to clipboard",
      "general.participants_added": "Participants added successfully",
      "general.survey_saved_as_draft": "Survey saved as draft",
      "general.survey_launched": "Survey launched successfully",
      "general.manager_added_successfully": "Manager added successfully",
      "general.employee_added_successfully": "Employee added successfully",
      "general.manager_not_added": "Manager not added",
      "general.employee_not_added": "Employee not added",
      "general.reminder_sent": "Reminder sent",

      // error messages
      "error.errors_to_correct": "Errors to correct:",
      "error.field_length_2_chars": "Field must be at least 2 characters long",
      "error.invalid_email": "Invalid email address",

      //field options / names
      "field.options_choose": "Choose one of the following",
      "field.options_full_list": "I have a full list of employees and managers",
      "field.options.partial_list":
        "I have no list or partial list of managers",
      "field.have_list_managers": "Do you have a list of managers?",
      "field.yes": "Yes",
      "field.no": "No",
      "field.will_managers_add_employees": "Will managers add employees?",
      "field.first_name": "First Name",
      "field.last_name": "Last Name",
      "field.email": "Email",
      "field.manager_emails": "Manager Emails",
      "field.manager": "Manager",
      "field.group": "Group",

      // Survey header menu
      "menu.logout": "Logout",
      "menu.new_survey": "New Survey",

      "menu.analyze.company": "Company",
      "menu.analyze.groups": "Groups",
      "menu.analyze.managers": "Managers",

      "menu.manage.managers": "Managers",
      "menu.manage.surveys": "Surveys",
      "menu.manage.groups": "Groups",
      "menu.manage.employees": "Employees",
      "menu.manage.uploads": "Uploads",
      "menu.manage.settings": "Settings",

      // Footer texts
      "footer.copyright": "2024 VeeMind",
      "footer.trust": "",
      "footer.support": "Support",
      "footer.impressum": "Impressum",

      //Survey Steps
      "surveySteps.completed_message": "you have already completed this survey",

      // Survey step 1
      "step1.owner": "You are leaving feedback for",
      "step1.feature1": "Purpose",
      "step1.feature2": "Feedback",
      "step1.feature3": "Data",
      "step1.feature4": "Goal",
      "step1.explainer1":
        "You've been invited to this survey to help improve their leadership style",
      "step1.explainer2":
        "Please be honest and helpful in your answers and comments",
      "step1.explainer3": "Your data is treated confidentially and anonymously",
      "step1.explainer4":
        "Your input will help them reflect and improve, and contribute to your long-term collaboration",
      "step1.cta": "START SURVEY",
      "step1.timeestimate": "It should take you approx.",
      "step1.minutes": "minutes",

      // Survey step 2
      "step2.title": "Leadership survey",
      "step2.description": "You are leaving feedback for",
      "step2.cta": "NEXT STEP",

      //Survey Step 2 Values
      "almost never": "almost never",
      rarely: "rarely",
      sometimes: "sometimes",
      frequently: "frequently",
      often: "often",
      "almost always": "almost always",

      // Survey step 3
      "step3.title": "Almost done!",
      "step3.description":
        "Your almost at the end of this survey. In this last part we would like to ask you for open-ended feedback. Your responses will remain anonymous.",
      "step3.description-self": "Your almost done with the self-assessment",
      "step3.cta": "FINISH SURVEY",

      // Survey step 4
      "step4.title": "Thank you, we appreciate\n your input!",
      "step4.text":
        "If you want to share your experience with VeeMind,\n we would be happy to hear about it at support@veemind.com",
      "step4.teasertitle": "Are you a good leader?",
      "step4.teasertext":
        "Do you want to receive good and honest feedback from your employees? Start your own survey at for free with VeeMind",
      "step4.cta": "START SURVEY",

      // Content Card (Academy)
      "academy.category.title": "Element",
      "academy.subcategory.title": "Skill",
      "academy.reading_time": "Reading time",
      "academy.minutes": "min",
      "academy.intro.title": "Intro",
      "academy.content.title": "What can I do?",
      "academy.read-more": "READ MORE",

      //Home
      "home.card_small_title": "Survey",
      "home.cta": "Select survey",

      //Login
      "login.title": "Learn about your own leadership style",
      "login.text":
        "Create a survey to ask your team, or yourself,\n what kind of leader you are",
      "login.cta": "LOGIN",
      "login.terms_of_use.text": "By creating an account, I agree\nto the",
      "login.terms_of_use.cta": "terms of Services",

      //Survey Conf
      "surveyConf.category.title": "Element",
      "surveyConf.subcategory.title": "Skill",
      "surveyConf.subcategory.question_title": "Question",
      "surveyConf.page_title": "Start your survey",
      "surveyConf.page_text":
        "This is a preview of the questions that will be distributed",
      "surveyConf.box.title": "GUIDE",
      "surveyConf.box.text_1":
        "In the next step, you will be asked to distribute access information to your team members, you can do this by providing their e-mails or giving them a direct link\n\nThe sections below illustrate the system we will use to assess your leadership style and the question that we ask your team",
      "surveyConf.box.text_2":
        "To see the questions we will send to your team members, click to explore each element below. We will also ask them to answer three open questions where they can share their suggestions and feedback.",
      "surveyConf.stats.time": "This survey will approx. take:",
      "surveyConf.minutes": "min",
      "surveyConf.stats.categories": "Selected elements:",
      "surveyConf.stats.questions": "Total Questions:",
      "surveyConf.stats.cta": "CREATE SURVEY",

      //SurveyResults
      "surveyResults.info.click_to_complete_self_survey":
        "Click here to complete the self survey (opens in a new window).",
      "surveyResults.info.after_survey_refresh":
        "After completing the survey, please refresh the page to view the results.",
      "surveyResults.info.no_results_for_manager":
        "There are no survey results for this manager",
      "surveyResults.info.self_assessment_required":
        "You are required to complete the following self-surveys before you can view the results",
      "surveyResults.summary_results_scorecard": "Results scorecard",
      "surveyResults.summary.leadership_elements":
        "Leadership elements evaluated",
      "surveyResults.summary.participants": "Number of participants",
      "surveyResults.summary.skills": "Leadership skills evaluated",
      "surveyResults.summary.answers_given": "Answers given",
      "surveyResults.summary.total_exercises":
        "Total exercises available in full version",
      "surveyResults.summary.opinions_given":
        "Opinions written by participants",
      "surveyResults.summary.time_average": "Time spent on avg.",
      "surveyResults.summary.completion_rate": "Completion rate",
      "surveyResults.summary.title": "Summary",
      "surveyResults.summary.box_text_1": "VeeMind",
      "surveyResults.summary.box_text_2": "Enterprise",
      "surveyResults.summary.box_cta": "Ask us via e-mail",
      "surveyResults.summary.text_bold":
        "This survey is a teaser for the enterprise version of VeeMind",
      "surveyResults.summary.text_1": "Thank you for taking time to use it",
      "surveyResults.summary.text_2":
        "If you would like to use our solution in your company, our platform enables further data-driven decision-making, such as:",
      "surveyResults.summary.text_list_1":
        "Whole company leadership quality overview",
      "surveyResults.summary.text_list_2": "Historical statistics",
      "surveyResults.summary.text_list_3": "Easy survey management",
      "surveyResults.summary.pdf_cta": "Send as PDF",

      //Page Content
      "surveyResults.page_title": "Results overview",
      "surveyResults.page_text": "This page shows and explains your results",
      "surveyResults.no_results.title": "No results",
      "surveyResults.no_results.text":
        "Results will become available as soon as the surveys are completed",
      "surveyResults.strength.title": "STRENGTH",
      "surveyResults.weakness.title": "WEAKNESS",
      "surveyResults.feedback.title": "Open-ended feedback",
      "surveyResults.feedback.no_results": "No results available",
      "surveyResults.feedback.show_all": "show all",
      "surveyResults.category.title": "element",
      "surveyResults.subcategory.title": "Skill",
      "surveyResults.category.subcategories_included": "skills included",
      "surveyResults.subcategory.tooltip.self-assess": "Self assess",
      "surveyResults.subcategory.academy-cta": "Improve",
      "surveyResults.subcategory.question_title": "Question",
      "surveyResults.select_survey": "Select one or more surveys",
      "surveyResults.all_surveys": "All surveys",

      //Tabs
      //Total Results
      "tab.results.survey_title.label": "Survey title",
      "tab.results.started_on.label": "Started on",
      "tab.results.end_date.label": "End date",
      "tab.results.end_date.text": "or when all responses are submitted",
      "tab.results.status.label": "Status",
      "tab.results.survey_closed": "This survey is now closed",

      //Tab Email
      "tab.email.title": "Using e-mail",
      "tab.email.invite-emails.label": "Invite via e-mail",
      "tab.email.invite-emails.placeholder":
        "Recipient e-mails (comma separated)",
      "tab.email.invite-emails.not_valid_message":
        "The following emails are not valid",
      "tab.email.invite-emails.already_sent_message":
        "The following have already been invited",
      "tab.email.invite-emails.send_to_your_email_message":
        "You cannot send an invite to yourself",
      "tab.email.tracking.label": "Response tracking",
      "tab.email.tracking.list_item_1": "automated reminders",
      "tab.email.tracking.list_item_2": "progress tracking",
      "tab.email.tracking.list_item_3": "survey closes with last respondent",
      "tab.email.end_date.label": "End date",
      "tab.email.end_date.text": "or when all responses are submitted",
      "tab.email.self-assess.cta": "self-assess",
      "tab.email.invite.cta": "invite",

      //Tab URL
      "tab.url.title": "Using a link",
      "tab.stats.title": "Status",
      "tab.url.invite-url.label": "Share URL",
      "tab.url.invite-url.text":
        "Use the link above to share with your team and make sure to click the button to start the survey.",
      "tab.url.invite-url.cta.share": "share",
      "tab.url.invite-url.cta.copied": "copied!",
      "tab.url.tracking.label": "Response tracking",
      "tab.url.tracking.text":
        "Inviting with e-mails helps you get the best results. E-mail invites enable:",
      "tab.url.tracking.list_item_1": "automated reminders",
      "tab.url.tracking.list_item_2": "progress tracking",
      "tab.url.tracking.list_item_3": "survey closes with last respondent",
      "tab.url.end_date.label": "End date",
      "tab.url.end_date.text": "Will close on midnight",
      "tab.url.self-assess.cta": "self-assess",

      //Register Modal
      "modal.register_user.title": "Tell us more about yourself",
      "modal.register_user.text":
        "We're still learning how to make this platform relevant across industries and contexts - the data you provide will be tremendously valuable. Sorry for the inconvenience & thank you!",
      "modal.register_user.q1": "What is your name and surname?",
      "modal.register_user.first_name": "First name",
      "modal.register_user.last_name": "Last name",
      "modal.register_user.q2": "What best describes your role?",
      "modal.register_user.choose": "Choose one",
      "modal.register_user.q3": "What is your industry?",
      "modal.register_user.q4": "Specify your industry",
      "modal.register_user.q5": "Where are you located?",
      "modal.register_user.q6": "What is the size of the team you manage?",
      "modal.register_user.q7": "What is the size of your company?",
      "modal.register_user.q8":
        "Does your company have a leadership and/or company culture guide?",
      "modal.register_user.cta": "Save",

      //Managers
      "managers.search_input": "Search managers",
      "managers.add_new_btn": "Add",
      "managers.no_managers_found": "No managers found.",
      "managers.copy_invite": "Copy link to survey",

      //Table
      "table.edit_btn": "Edit",
      "table.save_btn": "Save",
      "table.archive_btn": "Archive",
      "table.delete_btn": "Delete",

      //Modal New Manager
      "modal.new_manager.title": "New Manager",
      "modal.new_manager.first_name": "First Name",
      "modal.new_manager.last_name": "Last Name",
      "modal.new_manager.email": "Email",
      "modal.new_manager.submit_btn": "Add",

      //Modal Link Employee
      "modal.link_employee.title": "Select Employees to Link",
      "modal.link_employee.search_input": "Search by name",
      "modal.link_employee.selection": "Employee Selection",
      "modal.link_employee.selected_employees": "Selected Employees",
      "modal.link_employee.link_button": "Link Employee",
      "modal.link_employee.cancel_button": "Cancel",

      //Surveys
      "surveys.start": "Start",
      "surveys.end": "End",
      "surveys.invite_link": "Invite link",
      "surveys.send_reminder": "Send reminder",
      "surveys.not_active": "Survey Not Active",
      "surveys.completed": "Survey Completed",
      "surveys.copy": "Copy link",
      "surveys.all_surveys_placeholder": "All Surveys",
      "surveys.draft_surveys_placeholder": "Draft Surveys",
      "surveys.active_surveys_placeholder": "Active Surveys",
      "surveys.invited_managers": "Invited Managers",
      "surveys.invited_employees": "Invited Employees",
      "surveys.title": "Surveys",
      "surveys.add_new_btn": "Add",
      "surveys.starting_date": "Survey Starting Date",
      "surveys.closing_date": "Survey Closing Date",
      "surveys.completion_rate": "Completion Rate",
      "surveys.closed": "Survey Closed",
      "surveys.closed_yes": "Yes",
      "surveys.closed_no": "No",
      "surveys.invite_based": "Survey Invite Based",
      "surveys.link": "Survey Link",
      "surveys.link_to_survey": "Link to Public Survey",
      "surveys.invite_based_no": "No",
      "surveys.invite_based_yes": "Yes",
      "surveys.no_results_found": "No survey results found.",
      "surveys.survey_not_started": "Survey has not started yet",
      "surveys.status.not_started": "Not Started",
      "surveys.status.active": "Active",
      "surveys.status.closed": "Closed",
      "surveys.status.completed": "Completed",
      "surveys.status.in_progress": "In progress",
      "surveys.button.see_results": "See Results",
      "surveys.button.add_participants": "Add Participants",
      "surveys.button.duplicate": "Duplicate",
      "surveys.tab.managers": "Managers",
      "surveys.tab.employees": "Employees",
      "surveys.table_head.name_email": "Name / Email",
      "surveys.table_head.email": "Email",
      "surveys.table_head.manager": "Manager",
      "surveys.table_head.total_responses": "Total responses",
      "surveys.table_head.team_members_invited": "Team members invited",
      "surveys.table_head.status": "Status",
      "surveys.table_head.total_invites_received": "Total invites received",
      "surveys.manager_self_survey_link": "Manager self-survey link",
      "surveys.name_filter_placeholder": "Search",

      //Create Survey
      "create_survey.create_draft": "Create draft",
      "create_survey.save_draft": "Save draft",
      "create_survey.clear_selection": "Clear selection",
      "create_survey.select_all_managers_and_employees":
        "Select all managers and employees",
      "create_survey.select_all_managers": "Select all managers",
      "create_survey.link_existing_employee": "Link existing employee",
      "create_survey.cancel_btn": "Cancel",
      "create_survey.launch_btn": "Launch",
      "create_survey.template_label": "Template",
      "create_survey.start_date_label": "Start date",
      "create_survey.duration_label": "Duration",
      "create_survey.managers_invite_employees_label":
        "Managers Invite their Employees",
      "create_survey.survey_title_lable": "Survey Title",
      "create_survey.invite_based_survey_label":
        "Send employees invites via e-mail",
      "create_survey.self_survey_requested_label": "Self-assessment requested",
      "create_survey.add_manager_btn": "Add manager",
      "create_survey.select_manager_title": "Select manager",
      "create_survey.add_employee_btn": "Add employee",
      "create_survey.select_employee_title": "Select employees",
      "create_survey.launch_btn_loading": "Loading...",

      //Groups
      "tags.search_input": "Search groups",
      "tags.add_new_btn": "Add",

      //Modal New Group
      "modal.new_group.title": "New group",
      "modal.new_group.name_label": "Name",
      "modal.new_group.manager_label": "Manager",
      "modal.new_group.submit_btn": "Add",

      //Employees
      "employees.search_input": "Search employees",
      "employees.add_new_btn": "Add",

      //Modal New Employee
      "modal.new_employee.title": "New employee",
      "modal.new_employee.first_name_label": "First name",
      "modal.new_employee.last_name_label": "Last name",
      "modal.new_employee.email_label": "Email",
      "modal.new_employee.manager_label": "Manager",
      "modal.new_employee.submit_btn": "Add",

      //Uploads
      "uploads.select_label": "Select manager or employee",
      "uploads.select_placeholder": "Select import type",
      "uploads.manager_option": "Manager",
      "uploads.employee_option": "Employee",
      "uploads.step_1.title": "Select manager or employee",
      "uploads.step_1.text": "",
      "uploads.step_1.title_manager": "Manager",
      "uploads.step_1.text_manager": "",
      "uploads.step_1.title_employee": "Employee",
      "uploads.step_1.text_employee": "",
      "uploads.step_1.manager_btn": "Select",
      "uploads.step_1.employee_btn": "Select",

      //Upload Form
      "upload_form.import_successful": "Import successful",
      "upload_form.import_update_successful": "Update successful",
      "upload_form.managers_title": "Upload managers",
      "upload_form.employees_title": "Upload employees",
      "upload_form.sample_file_label": "Sample file",
      "upload_form.sample_file_btn": "Download sample file",
      "upload_form.upload_file_label": "Upload file",
      "upload_form.table.field_name": "Field name",
      "upload_form.table.available_fields": "Available fields",
      "upload_form.table.edit": "Edit",
      "upload_form.table.select_placeholder": "Choose one of the following",
      "upload_form.table.remove_btn": "Remove",
      "upload_form.table.import_btn": "Import",
      "upload_form.available_fields_label": "Available fields",

      //New Survey
      "new_survey.back_btn": "Back",
      "new_survey.next_btn": "Next",
      "new_survey.done_btn": "Done",
      "new_survey.step_1.title": "Launch a survey",
      "new_survey.step_1.text":
        "Would you like to create a new survey from scratch, for new managers and employees or for managers and employees who have been previously uploaded?",
      "new_survey.step_1.title_yes":
        "I want to upload new managers and employees",
      "new_survey.step_1.text_yes":
        "In the next step, you will be able to download an Excel template for inputting managers and employees",
      "new_survey.step_1.title_no":
        "I want to upload managers or add them by hand",
      "new_survey.step_1.text_no":
        "You will have a choice of delegating this task to your managers, or you can do it manually",
      "new_survey.step_1.title_custom":
        "I want to launch a survey for existing managers",
      "new_survey.step_1.text_custom": "",
      "new_survey.step_1.cta_custom": "Create new survey",
      "new_survey.step_2.title": "Mapping managers to reports",
      "new_survey.step_2.manager_handles_invite_text":
        "Do you want to ask your managers to add their employees? You will be able to inspect who they have invited",
      "new_survey.step_2.managers_list_text":
        "Do you have a list or managers that you can upload or prepare, or will you be adding them manually?",
      "new_survey.step_5.title": "Ready to launch",
      "new_survey.step_5.text_1": "You are now ready to launch the survey",
      "new_survey.step_5.text_2": "In the next step",
      "new_survey.step_5.text_2.li_1":
        "Duplicates are ignored, people are recognized by their e-mail",
      "new_survey.step_5.text_2.li_2":
        "You will be able to preview the import in the next step",
      "new_survey.step_5.text_3": "While the survey is active",
      "new_survey.step_5.text_3.li_1":
        "You will have the option to inspect and edit invitees managers throughout the duration of the survey",

      //Analyze Company
      "analyze.company.all_surveys_placeholder": "All surveys",
      "analyze.company.all_groups_placeholder": "All groups",
      "analyze.company.date_placeholder": "All time",
      "analyze.company.name_filter_placeholder": "Filter by name",
      "analyze.company.export_btn": "Export",
      "analyze.company.primary_box":
        "How many managers have increased their overall score?",
      "analyze.company.data_points_title": "Key data points",
      "analyze.company.feedback_title": "Qualitative feedback",
      "analyze.company.feedback_box_title": "Feedback",
      "analyze.company.graph_title": "Company matrix",
      "analyze.company.graph_text":
        "By clicking on each competency on the left, you will see the descriptions and corresponding questions used to assess the leadership competency. By clicking on the points in the graph, you can see the scores achieved.",
      "analyze.company.insights_title": "Category description",
      "analyze.company.questions_title": "Sample questions",
      "analyze.company.cat_description_title": "Category description",

      //Analyze Managers
      "analyze.managers.all_surveys_placeholder": "All surveys",
      "analyze.managers.all_groups_placeholder": "All groups",
      "analyze.managers.date_placeholder": "All time",
      "analyze.managers.name_filter_placeholder": "Filter by name",
      "analyze.managers.export_btn": "Export",
      "analyze.managers.primary_box":
        "Which element of leadership has changed the most?",
      "analyze.managers.insights_title": "Category description",
      "analyze.managers.questions_title": "Sample questions",
      "analyze.managers.cat_description_title": "Category description",
      "analyze.managers.feedback_box_title": "Feedback",
      "analyze.managers.summary": "Summary",
      "analyze.managers.list": "List",
      "analyze.managers.no_results": "No results",
      "anaylze.managers.self_assessed": "self-assessed",

      //Analyze Survey
      "analyze.survey.back_btn": "Back",

      //Analyze Groups
      "analyze.groups.rank": "Rank",
      "analyze.groups.highest_ratings": "Highest ratings",
      "analyze.groups.highest_ratings_subtitle": "95th percentile",
      "analyze.groups.distribution": "Distribution",
      "analyze.groups.list": "List",
      "analyze.groups.managers": "managers",
      "analyze.groups.inspect": "Inspect",
      "analyze.groups.rest": "Rest",
      "analyze.groups.lowest_ratings": "Lowest ratings",
      "analyze.groups.lowest_ratings_subtitle": "5th percentile",

      "analyze.widgets.leadership_increase_most_by_percent": "has increased",
      "analyze.widgets.leadership_decrease_most_by_percent": "has decreased",
      "analyze.widgets.leadership_change_most_least_score.most":
        "has the most change",
      "analyze.widgets.leadership_change_most_least_score.least":
        "has the least change",
      "analyze.widgets.leadership_highest_score": "has the highest score",
      "analyze.widgets.leadership_lowest_score": "has the lowest score",
      "analyze.widgets.question_lowest_score_all_manager":
        "Question with the lowest score among all managers",
      //Settings Page
      "settings.title": "Company Settings",
      "settings.data.title": "Company Data",
      "settings.data.name": "Name:",
      "settings.data.address": "Address:",
      "settings.data.address_number": "Address Number:",
      "settings.data.btn": "Save Company Data",
      "settings.data.save_language": "Save Language",
      "settings.save_other_settings": "Save Other Settings",
      "settings.self_survey_required": "Self-assessment required",
      "settings.language_options": "Language Options",
      "settings.academy_contents": "Academy Contents",
      "settings.other_settings": "Other Settings",
      "settings.default_language": "Default Language",
      "settings.available_languages": "Available Languages",
      "settings.custom_fields.title": "Custom Field",
      "settings.new_field.title": "Add New Custom Field",
      "settings.choose_widget": "Choose Widget",
      "settings.save_widgets": "Save Widget Choices",
      "settings.new_field.name": "Field Name",
      "settings.new_field.btn": "Add Custom Field",
      "settings.selected": "Selected",
      "settings.not_selected": "Not Selected",
      "settings.data.saved": "Company data saved",
      "settings.enabled": "Enabled",
      "settings.disabled": "Disabled",

      //modal Upload
      "modal.btn.save": "Save",
    },
  },
  de: {
    translation: {
      "splash.tag_line": "Führung als Daten gemessen",
      // general info
      "general.no_results_found": "Keine Ergebnisse gefunden",
      "general.copied_to_clipboard": "In die Zwischenablage kopiert",
      "general.participants_added": "Teilnehmer erfolgreich hinzugefügt",
      "general.survey_saved_as_draft": "Umfrage als Entwurf gespeichert",
      "general.survey_launched": "Umfrage erfolgreich gestartet",
      "general.manager_added_successfully": "Manager erfolgreich hinzugefügt",
      "general.employee_added_successfully":
        "Mitarbeiter erfolgreich hinzugefügt",
      "general.manager_not_added": "Manager nicht hinzugefügt",
      "general.employee_not_added": "Mitarbeiter nicht hinzugefügt",
      "general.reminder_sent": "Erinnerung gesendet",

      // error messages
      "error.errors_to_correct": "Fehler zu korrigieren:",
      "error.field_length_2_chars": "Feld muss mindestens 2 Zeichen lang sein",
      "error.invalid_email": "Ungültige E-Mail-Adresse",

      //field options / names
      "field.options_choose": "Wählen Sie eine der folgenden Optionen",
      "field.options_full_list":
        "Ich habe eine Liste von Mitarbeitern und Managern",
      "field.options.partial_list":
        "Ich habe keine Liste oder eine teilweise Liste von Managern",
      "field.have_list_managers": "Haben Sie eine Liste von Managern?",
      "field.yes": "Ja",
      "field.no": "Nein",
      "field.will_managers_add_employees":
        "Werden Manager Mitarbeiter hinzufügen?",
      "field.first_name": "Vorname",
      "field.last_name": "Nachname",
      "field.email": "Email",
      "field.manager_emails": "Manager E-Mails",
      "field.manager": "Manager",
      "field.group": "Gruppe",
      "menu.logout": "Abmeldung",
      "menu.new_survey": "Neue Umfrage",
      "menu.analyze.company": "Unternehmen",
      "menu.analyze.groups": "Gruppen",
      "menu.analyze.managers": "Manager",
      "menu.manage.managers": "Managers",
      "menu.manage.surveys": "Umfragen",
      "menu.manage.groups": "Gruppen",
      "menu.manage.employees": "Mitarbeiter",
      "menu.manage.uploads": "Uploads",
      "menu.manage.settings": "Einstellungen",
      "footer.copyright": "2024 VeeMind",
      "footer.trust": "",
      "footer.support": "Support",
      "footer.impressum": "Impressum",
      "surveySteps.completed_message":
        "Sie haben diese Umfrage bereits abgeschlossen",
      "step1.owner": "Sie hinterlassen ein Feedback für",
      "step1.feature1": "Zweck",
      "step1.feature2": "Feedback",
      "step1.feature3": "Daten",
      "step1.feature4": "Ziel",
      "step1.explainer1":
        "Sie sind zu dieser Umfrage eingeladen worden, um Feedback zum Führungsstil zu geben",
      "step1.explainer2":
        "Bitte seien Sie ehrlich und unterstützend in Ihren Antworten und Kommentaren",
      "step1.explainer3": "Ihre Daten werden vertraulich und anonym behandelt",
      "step1.explainer4":
        "Ihr Beitrag wird der Führungskraft helfen, ihr Führungsverhalten zu reflektieren und die Zusammenarbeit zu stärken.",
      "step1.cta": "UMFRAGE STARTEN",
      "step1.timeestimate": "Die Bearbeitung sollte ca.",
      "step1.minutes": "Minuten dauern.",
      "step2.title": "Umfrage zum Thema Führung",
      "step2.description": "Sie hinterlassen ein Feedback für",
      "step2.cta": "NÄCHSTER SCHRITT",
      "almost never": "fast nie",
      rarely: "selten",
      sometimes: "manchmal",
      frequently: "häufig",
      often: "oft",
      "almost always": "fast immer",
      "step3.title": "Sie haben es fast geschafft!",
      "step3.description":
        "Sie sind fast am Ende der Umfrage. In diesem letzten Teil möchten wir Sie um ein offenes Feedback bitten. Ihre Antworten bleiben anonym.",
      "step3.description-self":
        "Sie sind fast fertig mit der Selbsteinschätzung",
      "step3.cta": "UMFRAGE ABSCHLIESSEN",
      "step4.title": "Vielen Dank, wir schätzen Ihren Beitrag!",
      "step4.text":
        "Wenn Sie Ihre Erfahrungen mit VeeMind teilen möchten, würden wir uns sehr über eine Nachricht an support@veemind.com freuen.",
      "step4.teasertitle": "Sind Sie eine gute Führungskraft?",
      "step4.teasertext":
        "Möchten Sie ehrliches und unterstützendes Feedback von Ihren Mitarbeitern erhalten? Starten Sie Ihre eigene Umfrage kostenlos mit VeeMind",
      "step4.cta": "UMFRAGE STARTEN",
      "academy.category.title": "Kompetenz",
      "academy.subcategory.title": "Fähigkeit",
      "academy.reading_time": "Lesezeit",
      "academy.minutes": "min",
      "academy.intro.title": "Intro",
      "academy.content.title": "Was kann ich tun?",
      "academy.read-more": "MEHR LESEN",
      "home.card_small_title": "Umfrage",
      "home.cta": "Umfrage auswählen",
      "login.title": "Lernen Sie mehr über Ihren eigenen Führungsstil",
      "login.text":
        "Erstellen Sie eine Umfrage, um Ihr Team oder sich selbst zu befragen, welche Art von Führungskraft Sie sind",
      "login.cta": "ANMELDUNG",
      "login.cta_after_error": "Weiter zur Anmeldung",
      "login.terms_of_use.text":
        "Mit der Erstellung eines Kontos, gebe ich meine Zustimmung zu den",
      "login.terms_of_use.cta": "Nutzungsbedingungen",
      "surveyConf.category.title": "Kompetenz",
      "surveyConf.subcategory.title": "Fähigkeit",
      "surveyConf.subcategory.question_title": "Frage",
      "surveyConf.page_title": "Starten Sie Ihre Umfrage",
      "surveyConf.page_text":
        "Hier ist eine Vorschau auf die Fragen, die gestellt werden",
      "surveyConf.box.title": "ANLEITUNG",
      "surveyConf.box.text_1":
        "Im nächsten Schritt werden Sie aufgefordert, die Zugangsinformationen an Ihre Teammitglieder zu verteilen. Sie können dies tun, indem Sie deren E-Mail-Adressen angeben oder ihnen einen direkten Link geben. Die folgenden Abschnitte veranschaulichen das System, das wir zur Beurteilung Ihres Führungsstils verwenden, sowie die Fragen, die wir Ihrem Team stellen",
      "surveyConf.box.text_2":
        "Um die Fragen zu sehen, die wir Ihren Teammitgliedern schicken werden, klicken Sie auf die einzelnen Elemente unten. Wir werden sie auch bitten, drei offene Fragen zu beantworten, in denen sie Ihre Vorschläge und Ihr Feedback mitteilen können.",
      "surveyConf.stats.time": "Die Länge dieser Umfrage beträgt ca.:",
      "surveyConf.minutes": "Min",
      "surveyConf.stats.categories": "Ausgewählte Elemente:",
      "surveyConf.stats.questions": "Fragen insgesamt:",
      "surveyConf.stats.cta": "UMFRAGE ERSTELLEN",
      "surveyResults.info.click_to_complete_self_survey":
        "Klicken Sie hier, um die Selbsteinschätzung abzuschließen (öffnet in einem neuen Fenster).",
      "surveyResults.info.after_survey_refresh":
        "Nach Abschluss der Umfrage aktualisieren Sie bitte die Seite, um die Ergebnisse anzuzeigen.",
      "surveyResults.info.no_results_for_manager":
        "Für diesen Manager liegen keine Umfrageergebnisse vor",
      "surveyResults.info.self_assessment_required":
        "Sie müssen die folgenden Selbsteinschätzungen abschließen, bevor Sie die Ergebnisse anzeigen können",
      "surveyResults.summary_results_scorecard": "Ergebnisübersicht",
      "surveyResults.summary.leadership_elements":
        "Bewertete Führungkompetenzen",
      "surveyResults.summary.participants": "Anzahl der Teilnehmer",
      "surveyResults.summary.skills": "Bewertete Führungsfähigkeiten",
      "surveyResults.summary.answers_given": "Erhaltende Antworten",
      "surveyResults.summary.total_exercises":
        "Alle Übungen in der Vollversion verfügbar",
      "surveyResults.summary.opinions_given": "Erhaltene offene Antworten",
      "surveyResults.summary.time_average": "Durchschnittlich benötigte Zeit",
      "surveyResults.summary.completion_rate": "Finalsierungsquote",
      "surveyResults.summary.title": "Zusammenfassung",
      "surveyResults.summary.box_text_1": "VeeMind",
      "surveyResults.summary.box_text_2": "Unternehmen",
      "surveyResults.summary.box_cta": "Fragen Sie uns per E-Mail",
      "surveyResults.summary.text_bold":
        "Diese Umfrage ist ein Vorgeschmack auf die Unternehmensversion von VeeMind",
      "surveyResults.summary.text_1":
        "Danke, dass Sie sich die Zeit genommen haben, sie zu nutzen",
      "surveyResults.summary.text_2":
        "Wenn Sie unsere Lösung in Ihrem Unternehmen einsetzen möchten, ermöglicht unsere Plattform weitere datengesteuerte Entscheidungen, wie z. B.:",
      "surveyResults.summary.text_list_1":
        "Überblick über die Qualität der gesamten Unternehmensführung",
      "surveyResults.summary.text_list_2": "Historische Statistiken",
      "surveyResults.summary.text_list_3": "Einfache Umfrageverwaltung",
      "surveyResults.summary.pdf_cta": "Als PDF versenden",
      "surveyResults.page_title": "Ergebnisübersicht",
      "surveyResults.page_text":
        "Diese Seite zeigt und erklärt Ihre Ergebnisse",
      "surveyResults.no_results.title": "Keine Ergebnisse",
      "surveyResults.no_results.text":
        "Die Ergebnisse sind verfügbar, sobald die Umfragen abgeschlossen sind.",
      "surveyResults.strength.title": "STÄRKE",
      "surveyResults.weakness.title": "SCHWÄCHE",
      "surveyResults.feedback.title": "Offenes Feedback",
      "surveyResults.feedback.show_all": "alle anzeigen",
      "surveyResults.feedback.no_results": "Keine Ergebnisse verfügbar",
      "surveyResults.category.title": "Kompetenz",
      "surveyResults.subcategory.title": "Fähigkeit",
      "surveyResults.category.subcategories_included": "enthaltene Fähigkeiten",
      "surveyResults.subcategory.tooltip.self-assess": "Selbsteinschätzung",
      "surveyResults.subcategory.academy-cta": "Verbessern Sie",
      "surveyResults.subcategory.question_title": "Frage",
      "surveyResults.select_survey": "Wählen Sie eine oder mehrere Umfragen",
      "surveyResults.all_surveys": "Alle Umfragen",
      "tab.results.survey_title.label": "Titel der Umfrage",
      "tab.results.started_on.label": "Angefangen am",
      "tab.results.end_date.label": "Enddatum",
      "tab.results.end_date.text": "oder wenn alle Antworten eingegangen sind",
      "tab.results.status.label": "Status",
      "tab.results.survey_closed": "Diese Umfrage ist nun geschlossen",
      "tab.email.title": "E-Mail verwenden",
      "tab.email.invite-emails.label": "Per E-Mail einladen",
      "tab.email.invite-emails.placeholder":
        "Empfänger E-Mails (durch Komma getrennt)",
      "tab.email.invite-emails.not_valid_message":
        "Die folgenden E-Mails sind nicht gültig",
      "tab.email.invite-emails.already_sent_message":
        "Die folgenden Personen wurden bereits eingeladen",
      "tab.email.invite-emails.send_to_your_email_message":
        "Sie können keine Einladungen an sich selbst senden",
      "tab.email.tracking.label": "Verfolgung der Antwort",
      "tab.email.tracking.list_item_1": "automatische Erinnerung",
      "tab.email.tracking.list_item_2": "Fortschrittsanzeige",
      "tab.email.tracking.list_item_3":
        "Umfrage wird mit dem letzten Befragten abgeschlossen",
      "tab.email.end_date.label": "Enddatum",
      "tab.email.end_date.text": "oder wenn alle Antworten eingegangen sind",
      "tab.email.self-assess.cta": "Selbsteinschätzung",
      "tab.email.invite.cta": "einladen",
      "tab.url.title": "Verwendung eines Links",
      "tab.stats.title": "Status",
      "tab.url.invite-url.label": "URL freigeben",
      "tab.url.invite-url.text":
        "Verwenden Sie den obigen Link, um ihn an Ihr Team weiterzugeben, und klicken Sie auf die Schaltfläche, um die Umfrage zu starten.",
      "tab.url.invite-url.cta.share": "teilen",
      "tab.url.invite-url.cta.copied": "kopiert!",
      "tab.url.tracking.label": "Verfolgung der Antwort",
      "tab.url.tracking.text":
        "Mit Einladungen per E-Mail erzielen Sie die besten Ergebnisse. Einladungen per E-Mail ermöglichen:",
      "tab.url.tracking.list_item_1": "automatische Erinnerungen",
      "tab.url.tracking.list_item_2": "Fortschrittskontrolle",
      "tab.url.tracking.list_item_3":
        "Umfrage wird mit dem letzten Befragten abgeschlossen",
      "tab.url.end_date.label": "End Datum",
      "tab.url.end_date.text": "Wird um Mitternacht geschlossen",
      "tab.url.self-assess.cta": "Selbsteinschätzung",
      "modal.register_user.title": "Nur noch ein Schritt zu Ihrem Ergebnis",
      "modal.register_user.text":
        "Bitte füllen Sie die nachstehenden Felder aus, um Zugang zu Ihrer personalisierten Führungsbewertung zu erhalten",
      "modal.register_user.q1": "Ihr Name?",
      "modal.register_user.first_name": "Vorname",
      "modal.register_user.last_name": "Nachname",
      "modal.register_user.email": "E-Mail",
      "modal.register_user.q2": "Was beschreibt Ihre Rolle am besten?",
      "modal.register_user.choose": "Wählen Sie eine",
      "modal.register_user.q3": "Was ist Ihre Branche?",
      "modal.register_user.q4": "Geben Sie Ihre Branche an",
      "modal.register_user.q5": "Wo befinden Sie sich?",
      "modal.register_user.q6": "Wie groß ist das Team, das Sie leiten?",
      "modal.register_user.q7": "Wie groß ist Ihr Unternehmen?",
      "modal.register_user.q8":
        "Verfügt Ihr Unternehmen über einen Leitfaden für Führung und/oder Unternehmenskultur?",
      "modal.register_user.cta": "Speichern",
      "managers.search_input": "Führungskraft suchen",
      "managers.add_new_btn": "Hinzufügen",
      "managers.no_managers_found": "Keine Führungskraft gefunden.",
      "managers.copy_invite": "Link zur Umfrage kopieren",
      "table.edit_btn": "Bearbeiten",
      "table.save_btn": "Speichern",
      "table.archive_btn": "Archivieren",
      "table.delete_btn": "Löschen",
      "modal.new_manager.title": "Neue Führungskraft",
      "modal.new_manager.first_name": "Vorname",
      "modal.new_manager.last_name": "Nachname",
      "modal.new_manager.email": "E-Mail",
      "modal.new_manager.submit_btn": "Hinzufügen",
      "modal.link_employee.title": "Mitarbeiter zum Verknüpfen auswählen",
      "modal.link_employee.search_input": "Nach Namen suchen",
      "modal.link_employee.selection": "Mitarbeiterauswahl",
      "modal.link_employee.selected_employees": "Ausgewählte Mitarbeiter",
      "modal.link_employee.link_button": "Mitarbeiter verknüpfen",
      "modal.link_employee.cancel_button": "Abbrechen",
      "surveys.start": "Start",
      "surveys.end": "Ende",
      "surveys.invite_link": "Einladungslink",
      "surveys.send_reminder": "Erinnerung senden",
      "surveys.not_active": "Umfrage nicht aktiv",
      "surveys.completed": "Umfrage abgeschlossen",
      "surveys.copy": "Link kopieren",
      "surveys.all_surveys_placeholder": "Alle Umfragen",
      "surveys.draft_surveys_placeholder": "Entwürfe",
      "surveys.active_surveys_placeholder": "Aktive Umfragen",
      "surveys.invited_managers": "Eingeladene Manager",
      "surveys.invited_employees": "Eingeladene Mitarbeiter",
      "surveys.title": "Umfragen",
      "surveys.add_new_btn": "Hinzufügen",
      "surveys.starting_date": "Umfragestartdatum",
      "surveys.closing_date": "Umfrageenddatum",
      "surveys.completion_rate": "Abschlussrate",
      "surveys.closed": "Umfrage geschlossen",
      "surveys.closed_yes": "Ja",
      "surveys.closed_no": "Nein",
      "surveys.invite_based": "Umfrage basierend auf Einladungen",
      "surveys.link": "Umfragelink",
      "surveys.link_to_survey": "Link zur öffentlichen Umfrage",
      "surveys.invite_based_no": "Nein",
      "surveys.invite_based_yes": "Ja",
      "surveys.no_results_found": "Keine Umfrageergebnisse gefunden.",
      "surveys.survey_not_started": "Umfrage hat noch nicht begonnen",
      "surveys.status.not_started": "Nicht gestartet",
      "surveys.status.active": "Aktiv",
      "surveys.status.closed": "Geschlossen",
      "surveys.status.completed": "Abgeschlossen",
      "surveys.status.in_progress": "In Bearbeitung",
      "surveys.button.see_results": "Ergebnisse anzeigen",
      "surveys.button.add_participants": "Teilnehmer hinzufügen",
      "surveys.button.duplicate": "Duplizieren",
      "surveys.tab.managers": "Führungskraft",
      "surveys.tab.employees": "Mitarbeiter",
      "surveys.table_head.name_email": "Name / E-Mail",
      "surveys.table_head.email": "E-Mail",
      "surveys.table_head.manager": "Führungskraft",
      "surveys.table_head.total_responses": "Gesamtantworten",
      "surveys.table_head.team_members_invited": "Eingeladene Teammitglieder",
      "surveys.table_head.status": "Status",
      "surveys.table_head.total_invites_received": "Gesamtzahl der Einladungen",
      "surveys.manager_self_survey_link":
        "Link zur Selbstbewertung der Führungskraft",
      "surveys.name_filter_placeholder": "Suche",
      "create_survey.create_draft": "Entwurf erstellen",
      "create_survey.save_draft": "Entwurf speichern",
      "create_survey.clear_selection": "Auswahl löschen",
      "create_survey.select_all_managers_and_employees":
        "Alle Manager und Mitarbeiter auswählen",
      "create_survey.select_all_managers": "Alle Manager auswählen",
      "create_survey.link_existing_employee":
        "Bestehenden Mitarbeiter verknüpfen",
      "create_survey.cancel_btn": "Abbrechen",
      "create_survey.launch_btn": "Starten",
      "create_survey.template_label": "Vorlage",
      "create_survey.start_date_label": "Startdatum",
      "create_survey.duration_label": "Dauer",
      "create_survey.managers_invite_employees_label":
        "Die Führungskraft läd ihre Mitarbeiter ein",
      "create_survey.survey_title_lable": "Umfragetitel",
      "create_survey.invite_based_survey_label":
        "Mitarbeiter per E-Mail einladen",
      "create_survey.self_survey_requested_label":
        "Selbsteinschätzung angefordert",
      "create_survey.add_manager_btn": "Führungskraft hinzufügen",
      "create_survey.select_manager_title": "Führungskraft auswählen",
      "create_survey.add_employee_btn": "Mitarbeiter hinzufügen",
      "create_survey.select_employee_title": "Mitarbeiter auswählen",
      "create_survey.launch_btn_loading": "Laden...",
      "tags.search_input": "Gruppen suchen",
      "tags.add_new_btn": "Hinzufügen",
      "modal.new_group.title": "Neue Gruppe",
      "modal.new_group.name_label": "Name",
      "modal.new_group.manager_label": "Führungskraft",
      "modal.new_group.submit_btn": "Hinzufügen",
      "employees.search_input": "Mitarbeiter suchen",
      "employees.add_new_btn": "Hinzufügen",
      "modal.new_employee.title": "Neuer Mitarbeiter",
      "modal.new_employee.first_name_label": "Vorname",
      "modal.new_employee.last_name_label": "Nachname",
      "modal.new_employee.email_label": "E-Mail",
      "modal.new_employee.manager_label": "Führungskraft",
      "modal.new_employee.submit_btn": "Hinzufügen",
      "uploads.select_label": "Führungskraft oder Mitarbeiter auswählen",
      "uploads.select_placeholder": "Importtyp auswählen",
      "uploads.manager_option": "Führungskraft",
      "uploads.employee_option": "Mitarbeiter",
      "uploads.step_1.title": "Führungskraft oder Mitarbeiter auswählen",
      "uploads.step_1.text": "",
      "uploads.step_1.title_manager": "Führungskraft",
      "uploads.step_1.text_manager": "",
      "uploads.step_1.title_employee": "Mitarbeiter",
      "uploads.step_1.text_employee": "",
      "uploads.step_1.manager_btn": "Auswählen",
      "uploads.step_1.employee_btn": "Auswählen",
      "upload_form.import_successful": "Import erfolgreich",
      "upload_form.import_update_successful": "Aktualisierung erfolgreich",
      "upload_form.managers_title": "Führungskraft hochladen",
      "upload_form.employees_title": "Mitarbeiter hochladen",
      "upload_form.sample_file_label": "Beispieldatei",
      "upload_form.sample_file_btn": "Beispieldatei herunterladen",
      "upload_form.upload_file_label": "Datei hochladen",
      "upload_form.table.field_name": "Feldname",
      "upload_form.table.available_fields": "Verfügbare Felder",
      "upload_form.table.edit": "Bearbeiten",
      "upload_form.table.select_placeholder": "Eine Option wählen",
      "upload_form.table.remove_btn": "Entfernen",
      "upload_form.table.import_btn": "Importieren",
      "upload_form.available_fields_label": "Verfügbare Felder",
      "new_survey.back_btn": "Zurück",
      "new_survey.next_btn": "Weiter",
      "new_survey.done_btn": "Fertig",
      "new_survey.step_1.title": "Umfrage starten",
      "new_survey.step_1.text":
        "Möchten Sie eine neue Umfrage von Grund auf erstellen, für neue Führungskräfte und Mitarbeiter oder für bereits hochgeladene Führungskräfte und Mitarbeiter?",
      "new_survey.step_1.title_yes":
        "Ich möchte neue Führungskräfte und Mitarbeiter hochladen",
      "new_survey.step_1.text_yes":
        "Im nächsten Schritt können Sie eine Excel-Vorlage herunterladen, um Führungskräfte und Mitarbeiter einzugeben",
      "new_survey.step_1.title_no":
        "Ich möchte Führungskräfte hochladen oder sie manuell hinzufügen",
      "new_survey.step_1.text_no":
        "Sie haben die Möglichkeit, diese Aufgabe Ihren Führungskräften zu übertragen oder Sie können sie manuell erledigen",
      "new_survey.step_1.title_custom":
        "Ich möchte eine Umfrage für bestehende Führungskräfte starten",
      "new_survey.step_1.text_custom": "",
      "new_survey.step_1.cta_custom": "Neue Umfrage erstellen",
      "new_survey.step_2.title": "Führungskräfte den Umfrageantworten zuordnen",
      "new_survey.step_2.manager_handles_invite_text":
        "Möchten Sie Ihre Führungskräfte bitten, ihre Mitarbeiter hinzuzufügen? Sie können überprüfen, wen sie eingeladen haben",
      "new_survey.step_2.managers_list_text":
        "Haben Sie eine Liste von Führungskräften, die Sie hochladen oder vorbereiten können, oder werden Sie sie manuell hinzufügen?",
      "new_survey.step_5.title": "Bereit zum Start",
      "new_survey.step_5.text_1":
        "Sie sind jetzt bereit, die Umfrage zu starten",
      "new_survey.step_5.text_2": "Im nächsten Schritt",
      "new_survey.step_5.text_2.li_1":
        "Duplikate werden ignoriert, Personen werden anhand ihrer E-Mail erkannt",
      "new_survey.step_5.text_2.li_2":
        "Sie können den Import im nächsten Schritt vorab anzeigen",
      "new_survey.step_5.text_3": "Während die Umfrage aktiv ist",
      "new_survey.step_5.text_3.li_1":
        "Sie haben die Möglichkeit, die eingeladenen Manager während der gesamten Umfragezeit zu überprüfen und zu bearbeiten",
      "analyze.company.all_surveys_placeholder": "Alle Umfragen",
      "analyze.company.all_groups_placeholder": "Alle Gruppen",
      "analyze.company.date_placeholder": "Gesamte Zeit",
      "analyze.company.name_filter_placeholder": "Nach Namen filtern",
      "analyze.company.export_btn": "Exportieren",
      "analyze.company.primary_box":
        "Wie viele Führungskräfte haben ihre Gesamtpunktzahl erhöht?",
      "analyze.company.data_points_title": "Wichtige Datenpunkte",
      "analyze.company.feedback_title": "Offenes Feedback",
      "analyze.company.feedback_box_title": "Feedback",
      "analyze.company.graph_title": "Unternehmensergebnisse",
      "analyze.company.graph_text":
        "Durch Klicken auf die einzelnen Kompetenzen links werden Ihnen die Beschreibungen und die dazugehörigen Fragen angezeigt, die zur Bewertung der Führungskompetenz verwendet wurden. Durch Klicken auf die Punkte in der Graphik können Sie sich die erreichten Werte anzeigen lassen.",
      "analyze.company.insights_title": "Beschreibung",
      "analyze.company.questions_title": "Fragen",
      "analyze.company.cat_description_title": "Beschreibung",
      "analyze.managers.all_surveys_placeholder": "Alle Umfragen",
      "analyze.managers.all_groups_placeholder": "Alle Gruppen",
      "analyze.managers.date_placeholder": "Gesamte Zeit",
      "analyze.managers.name_filter_placeholder": "Nach Namen filtern",
      "analyze.managers.export_btn": "Exportieren",
      "analyze.managers.primary_box":
        "Welches Element der Führung hat sich am meisten geändert?",
      "analyze.managers.insights_title": "Beschreibung",
      "analyze.managers.questions_title": "Fragen",
      "analyze.managers.cat_description_title": "Beschreibung",
      "analyze.managers.feedback_box_title": "Feedback",
      "analyze.managers.summary": "Zusammenfassung",
      "analyze.managers.list": "Liste",
      "analyze.managers.no_results": "Keine Ergebnisse",
      "anaylze.managers.self_assessed": "Selbsteinschätzung",
      "analyze.survey.back_btn": "Zurück",
      "analyze.groups.rank": "Rang",
      "analyze.groups.highest_ratings": "Höchste Bewertungen",
      "analyze.groups.highest_ratings_subtitle": "95% Perzentil",
      "analyze.groups.distribution": "Verteilung",
      "analyze.groups.list": "Liste",
      "analyze.groups.managers": "Führungskraft",
      "analyze.groups.inspect": "Inspektion",
      "analyze.groups.rest": "Rest",
      "analyze.groups.lowest_ratings": "Niedrigste Bewertungen",
      "analyze.groups.lowest_ratings_subtitle": "5% Perzentil",
      "analyze.widgets.leadership_increase_most_by_percent": "hat zugenommen",
      "analyze.widgets.leadership_decrease_most_by_percent": "hat abgenommen",
      "analyze.widgets.leadership_change_most_least_score.most":
        "hat die meisten Änderungen",
      "analyze.widgets.leadership_change_most_least_score.least":
        "hat die geringsten Änderungen",
      "analyze.widgets.leadership_highest_score": "hat die höchste Punktzahl",
      "analyze.widgets.leadership_lowest_score": "hat die niedrigste Punktzahl",
      "analyze.widgets.question_lowest_score_all_manager":
        "Frage mit der niedrigsten Punktzahl unter allen Managern",
      "settings.title": "Firmeneinstellungen",
      "settings.data.title": "Firmendaten",
      "settings.data.name": "Name:",
      "settings.data.address": "Adresse:",
      "settings.data.address_number": "Adressnummer:",
      "settings.data.btn": "Firmendaten speichern",
      "settings.data.save_language": "Sprache speichern",
      "settings.save_other_settings": "Andere Einstellungen speichern",
      "settings.self_survey_required": "Selbstbewertung erforderlich",
      "settings.language_options": "Sprachoptionen",
      "settings.academy_contents": "Akademie-Inhalte",
      "settings.other_settings": "Andere Einstellungen",
      "settings.default_language": "Standard-Sprache",
      "settings.available_languages": "Verfügbare Sprachen",
      "settings.custom_fields.title": "Benutzerdefiniertes Feld",
      "settings.new_field.title": "Neues benutzerdefiniertes Feld hinzufügen",
      "settings.choose_widget": "Widget auswählen",
      "settings.save_widgets": "Widget-Auswahl speichern",
      "settings.new_field.name": "Feldname",
      "settings.new_field.btn": "Benutzerdefiniertes Feld hinzufügen",
      "settings.selected": "Ausgewählt",
      "settings.not_selected": "Nicht ausgewählt",
      "settings.data.saved": "Firmendaten gespeichert",
      "settings.enabled": "Aktiviert",
      "settings.disabled": "Deaktiviert",
      "modal.btn.save": "Speichern",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
