import React from "react";

const SkeletonGroups = () => {
  return (
    <div className="animate-pulse">
      <div className="top-bar mb-5 skeleton anima">
        <div className="lg:flex lg:flex-wrap">
          <div className="lg:w-1/3">
            <div className="skeleton-title w-2/3 mb-2"></div>
            <div className="skeleton-title w-1/3"></div>
          </div>
          <div className="lg:pl-16 lg:w-2/3">
            <div className="skeleton-text !w-1/4"></div>
          </div>
        </div>
        <div className="inner-white-box lg:flex lg:flex-wrap text-primary/75">
          <div className="lg:w-[30%] lg:pr-8 flex flex-wrap">
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3 mb-7">
              <div className="skeleton-title !h-2.5 !w-2/3 mb-2"></div>
              <div className="skeleton-text !w-3/5"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className={`w-2/3 pl-3 mb-7 `}>
              <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 text-white relative"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3">
              <div className="skeleton-text !w-2/3 mb-2"></div>
              <div className="skeleton-title !h-2.5 !w-1/2"></div>
            </div>
          </div>
          <div className="lg:w-1/2 border-l-[1px] border-[#AFAAC9]/30 lg:px-8">
            <div className="skeleton-title !h-2.5 !w-1/12 mb-16"></div>
            <div className="bg-[#e2e4ea] w-2/3 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
          <div className="lg:w-1/5 border-l-[1px] border-[#AFAAC9]/30 lg:pl-8">
            <div className="skeleton-title !h-2.5 !w-1/4 mb-16"></div>
            <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
        </div>
      </div>
      <div className="top-bar mb-5 skeleton anima">
        <div className="lg:flex lg:flex-wrap">
          <div className="lg:w-1/3">
            <div className="skeleton-title w-2/3 mb-2"></div>
            <div className="skeleton-title w-1/3"></div>
          </div>
          <div className="lg:pl-16 lg:w-2/3">
            <div className="skeleton-text !w-1/4"></div>
          </div>
        </div>
        <div className="inner-white-box lg:flex lg:flex-wrap text-primary/75">
          <div className="lg:w-[30%] lg:pr-8 flex flex-wrap">
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3 mb-7">
              <div className="skeleton-title !h-2.5 !w-2/3 mb-2"></div>
              <div className="skeleton-text !w-3/5"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className={`w-2/3 pl-3 mb-7 `}>
              <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 text-white relative"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3">
              <div className="skeleton-text !w-2/3 mb-2"></div>
              <div className="skeleton-title !h-2.5 !w-1/2"></div>
            </div>
          </div>
          <div className="lg:w-1/2 border-l-[1px] border-[#AFAAC9]/30 lg:px-8">
            <div className="skeleton-title !h-2.5 !w-1/12 mb-16"></div>
            <div className="bg-[#e2e4ea] w-2/3 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
          <div className="lg:w-1/5 border-l-[1px] border-[#AFAAC9]/30 lg:pl-8">
            <div className="skeleton-title !h-2.5 !w-1/4 mb-16"></div>
            <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
        </div>
      </div>
      <div className="top-bar mb-5 skeleton anima">
        <div className="lg:flex lg:flex-wrap">
          <div className="lg:w-1/3">
            <div className="skeleton-title w-2/3 mb-2"></div>
            <div className="skeleton-title w-1/3"></div>
          </div>
          <div className="lg:pl-16 lg:w-2/3">
            <div className="skeleton-text !w-1/4"></div>
          </div>
        </div>
        <div className="inner-white-box lg:flex lg:flex-wrap text-primary/75">
          <div className="lg:w-[30%] lg:pr-8 flex flex-wrap">
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3 mb-7">
              <div className="skeleton-title !h-2.5 !w-2/3 mb-2"></div>
              <div className="skeleton-text !w-3/5"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className={`w-2/3 pl-3 mb-7 `}>
              <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 text-white relative"></div>
            </div>
            <span className="w-1/3 pr-2">
              <div className="skeleton-text !w-1/2"></div>
            </span>
            <div className="w-2/3 pl-3">
              <div className="skeleton-text !w-2/3 mb-2"></div>
              <div className="skeleton-title !h-2.5 !w-1/2"></div>
            </div>
          </div>
          <div className="lg:w-1/2 border-l-[1px] border-[#AFAAC9]/30 lg:px-8">
            <div className="skeleton-title !h-2.5 !w-1/12 mb-16"></div>
            <div className="bg-[#e2e4ea] w-2/3 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
          <div className="lg:w-1/5 border-l-[1px] border-[#AFAAC9]/30 lg:pl-8">
            <div className="skeleton-title !h-2.5 !w-1/4 mb-16"></div>
            <div className="bg-[#e2e4ea] w-4/5 h-[96px] text-right p-4 rounded-[22px] pt-14 mb-7 -mt-0.5 text-white relative"></div>
            <div className="skeleton-text !w-2/3 mb-2"></div>
            <div className="skeleton-title !h-2.5 !w-1/2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonGroups;
