import React, { useState, useEffect } from "react";

import ManagerDetails from "./SelectedManager";

const EditableTable = ({
  dataList,
  setShowSearchForm,
  showSearchForm,
  categoriesData,
}) => {
  // const [averages, setAverages] = useState({});
  const [selectedManager, setSelectedManager] = useState(null);
  // const [isHintToggle, setIsEHintToggle] = useState(false);

  // const handleHintToggle = () => {
  //   setIsEHintToggle(!isHintToggle ? true : false);
  // };

  // useEffect(() => {
  //   if (dataList?.length > 0 && categoriesData?.length > 0) {
  //     const getAverages = {};
  //     dataList.forEach(({ category_scores, manager }) => {
  //       let old_score = 0;
  //       let new_score = 0;
  //       categoriesData.forEach(({ title }) => {
  //         old_score += parseFloat(category_scores[title]?.prior_score || 0);
  //         new_score += parseFloat(category_scores[title]?.latest_score || 0);
  //       });
  //       getAverages[manager?.id] = {
  //         value: new_score,
  //         status: new_score >= old_score ? "green" : "red",
  //       };
  //     });
  //     setAverages(getAverages);
  //   }
  // }, [dataList, categoriesData]);

  useEffect(() => {
    if (showSearchForm && selectedManager) {
      setSelectedManager(null);
    }
  }, [showSearchForm, selectedManager]);

  return (
    categoriesData?.length > 0 && (
      <>
        {!selectedManager ? (
          <>
            {" "}
            <table className="admin-data-table min-w-full analyze">
              {/* Table header */}
              <thead>
                <tr>
                  <th></th>
                  {categoriesData.map(({ title }) => (
                    <th key={title}>{title}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {dataList?.length > 0 &&
                  dataList.map((managerdata) => {
                    const { category_scores, manager } = managerdata;
                    return (
                      <tr
                        onClick={() => {
                          setSelectedManager(managerdata);
                          setShowSearchForm(false);
                        }}
                        key={manager?.id}
                      >
                        <td className="title-primary-base">
                          {manager?.first_name}
                          <br />
                          {manager?.last_name}
                          <br />
                          {/* <span
                            className={`status-${
                              averages[manager?.id]?.status
                            }`}
                          >
                            <strong>
                              {(averages[manager?.id]?.value / 6).toFixed(1)}
                            </strong>{" "}
                            avg.
                          </span> */}
                        </td>
                        {categoriesData.map(({ title, palette }) => (
                          <td key={title}>
                            <div
                              className={`manager-scores element-${palette} text-${palette}`}
                            >
                              {/* <span
                                className="old-score"
                                style={{
                                  width: `calc(${
                                    category_scores[title]?.prior_score > 0
                                      ? parseFloat(
                                          category_scores[title]?.prior_score
                                        )
                                      : 0
                                  } * 100% / 6)`,
                                  height: `calc(${
                                    category_scores[title]?.prior_score > 0
                                      ? parseFloat(
                                          category_scores[title]?.prior_score
                                        )
                                      : 0
                                  } * 100% / 6)`,
                                }}
                              ></span> */}
                              <span
                                className={`new-score bg-${palette}-light`}
                                style={{
                                  width: `calc(${
                                    category_scores[title]?.latest_score > 0
                                      ? parseFloat(
                                          category_scores[title]?.latest_score
                                        )
                                      : 0
                                  } * 100% / 6)`,
                                  height: `calc(${
                                    category_scores[title]?.latest_score > 0
                                      ? parseFloat(
                                          category_scores[title]?.latest_score
                                        )
                                      : 0
                                  } * 100% / 6)`,
                                }}
                              ></span>
                              {category_scores[title]?.latest_score}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* <div
              className={`table-managers-info ${isHintToggle ? "show" : ""}`}
            >
              <button
                onClick={handleHintToggle}
                className="title-primary-md block w-full text-left relative"
              >
                Legend
              </button>
              <p className="my-5">
                <strong className="mr-4">0.0</strong>Score in last survey
              </p>
              <p className="inline-block w-[48%] align-middle">
                <span className="rounded-solid mr-3"></span>Last survey
              </p>
              <p className="inline-block w-[48%] align-middle">
                <span className="rounded-dashed mr-3"></span>Second-last survey
              </p>
            </div> */}
          </>
        ) : (
          <ManagerDetails
            categoriesData={categoriesData}
            selectedManager={selectedManager}
            setSelectedManager={setSelectedManager}
          />
        )}
      </>
    )
  );
};

export default EditableTable;
