import { useEffect, useState, useCallback } from "react";
import Header from "../modules/Header";
import { useOutletContext } from "react-router-dom";
import FooterInner from "../modules/FooterInner";
import {
  SurveyTabs,
  SurveyResultsBox,
  SurveyTextQuestionBox,
  SurveySummaryResults,
  ModalCategories,
} from "../modules/partials/survey-results-partials";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axios from "axios";

import { FormControl, Select, MenuItem } from "@mui/material";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const SurveyResults = () => {
  const {
    userDetails,
    frontendurl,
    notify,
    getSurveyResults,
    sendInvite,
    logOut,
  } = useOutletContext();

  const [toggleActive, setToggleActive] = useState();
  const [expandActive, setExpandActive] = useState();
  const [openTab, setOpenTab] = useState("");
  const [copied, setCopied] = useState(false);
  const [inviteEmailAddresses, setInviteEmailAddresses] = useState("");
  const [surveyResults, setSurveyResults] = useState(null);
  const [allSurveys, setAllSurveys] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState(["all"]);

  const [view, setView] = useState("user");

  const [incompleteSelfSurveys, setIncompleteSelfSurveys] = useState([]);

  const { t } = useTranslation();

  let strength = {
    title: "",
    text: "",
    value: null,
    img: "",
  };
  let weakness = {
    title: "",
    text: "",
    value: null,
    img: "",
  };

  const piePalette = [
    {
      name: "light-green",
      backgroundColor: "rgba(216, 240, 232, 0.7)",
      selectedCategoryBackgroundColor: "rgba(200, 220, 210, 1)",
      borderColor: "#32B78B",
    },
    {
      name: "light-blue",
      backgroundColor: "rgba(222, 238, 249, 0.7)",
      selectedCategoryBackgroundColor: "rgba(200, 220, 240, 1)",
      borderColor: "#479CDA",
    },
    {
      name: "light-brown",
      backgroundColor: "rgba(252, 244, 228, 0.9)",
      selectedCategoryBackgroundColor: "rgba(230, 220, 200, 1)",
      borderColor: "#CE992F",
    },
    {
      name: "dark-purple",
      backgroundColor: "rgba(229, 227, 241, 0.8)",
      selectedCategoryBackgroundColor: "rgba(210, 200, 230, 1)",
      borderColor: "#3A2699",
    },
    {
      name: "light-purple",
      backgroundColor: "rgba(237, 228, 246, 0.8)",
      selectedCategoryBackgroundColor: "rgba(220, 210, 230, 1)",
      borderColor: "#7A4CC4",
    },
    {
      name: "dark-red",
      backgroundColor: "rgba(251, 230, 237, 0.8)",
      selectedCategoryBackgroundColor: "rgba(230, 200, 210, 1)",
      borderColor: "#E2376B",
    },
    {
      name: "hot-orange",
      backgroundColor: "rgba(255, 242, 232, 0.9)",
      selectedCategoryBackgroundColor: "rgba(237, 225, 216, 1)",
      borderColor: "#fd7e14",
    },
    {
      name: "sea-blue",
      backgroundColor: "rgba(220, 237, 255, 0.9)",
      selectedCategoryBackgroundColor: "rgba(204, 220, 237, 1)",
      borderColor: "#007bff",
    },
  ];

  const radarData = {
    labels: surveyResults?.data?.categories?.map((item) => item.title),
    short_labels: surveyResults?.data?.categories?.map((item) => {
      // pick first two letters of each category
      return item.title.substring(0, 2);
    }),
    datasets: [
      {
        data: [],
        backgroundColor: surveyResults?.data?.categories?.map(
          (item) =>
            piePalette.find((palette) => palette.name === item.palette)
              .backgroundColor
        ),
        borderWidth: 0.2,
        borderColor: surveyResults?.data?.categories?.map(
          (item) =>
            piePalette.find((palette) => palette.name === item.palette)
              .borderColor
        ),
        circular: "inner",
        borderJoinStyle: "round",
      },
    ],
  };

  let all_sum = surveyResults?.data?.summary?.participant_complete_count;
  let start_date = surveyResults?.survey_starting_date
    ? new Date(surveyResults?.survey_starting_date)
    : null;
  let end_date = surveyResults?.survey_closing_date
    ? new Date(surveyResults?.survey_closing_date)
    : null;
  let self_access_is_set = surveyResults?.self_survey_complete;

  const SurveySummaryResultsData = [
    {
      title: t("surveyResults.summary.leadership_elements"),
      result: surveyResults?.data?.summary?.leadership_elements_count,
    },
    {
      title: t("surveyResults.summary.participants"),
      result: surveyResults?.data?.summary?.participant_count,
    },
    {
      title: t("surveyResults.summary.skills"),
      result: surveyResults?.data?.summary?.skills_count,
    },
    {
      title: t("surveyResults.summary.answers_given"),
      result: surveyResults?.data?.summary?.answers_given_count,
    },
    {
      title: t("surveyResults.summary.opinions_given"),
      result: surveyResults?.data?.summary?.opinions_given_count,
    },
    {
      title: t("surveyResults.summary.completion_rate"),
      result: surveyResults?.data?.summary?.completion_rate_count,
    },
  ];

  if (surveyResults?.data?.categories) {
    const category_copy = [...surveyResults.data.categories];

    const data = [];

    radarData.labels.forEach((label) => {
      const item = category_copy.find((item) => item.title === label);

      let rating = 0;
      let responses = 0;

      item?.skills?.forEach((skill) => {
        skill?.questions?.forEach((question) => {
          rating += question.answer.rating ?? 0;
          responses += question.answer.possible_score_range ?? 0;
        });
      });

      const percentage = (rating * 100) / responses;

      if (!strength.value || parseFloat(strength.value) < percentage) {
        strength = {
          title: item.title,
          text: item.text,
          value: percentage,
          img: item.icon.url,
        };
      }

      if (!weakness.value || weakness.value > percentage) {
        weakness = {
          title: item.title,
          text: item.text,
          value: percentage,
          img: item.icon.url,
        };
      }

      const roundedValue = Math.round(((percentage * 5) / 100) * 10) / 10;
      data.push(roundedValue);
    });

    radarData.datasets[0].data = data;
  }

  useEffect(() => {
    // if (all_sum >= 3) {
    setOpenTab("stats");
    // } else {
    // if (
    //   surveyResults?.invite_based === null ||
    //   surveyResults?.invite_based === false
    // ) {
    //   setOpenTab("invite");
    // } else {
    //   setOpenTab("link");
    // }
    // }
  }, [surveyResults, all_sum]);

  const fetchData = useCallback(async () => {
    try {
      let params = null;

      if (selectedSurveys.length > 0 && !selectedSurveys.includes("all")) {
        params = {
          managerSelectedSurveys: selectedSurveys,
        };
      }

      const results = await getSurveyResults(params);

      if (results?.allSurveys && results?.surveyResults) {
        setAllSurveys(results.allSurveys);
        setSurveyResults(results.surveyResults);
      } else {
        // check if self survey is required

        const baseUrl = process.env.REACT_APP_FRONTEND_URL;

        if (
          results?.issue === "self_survey_required" &&
          results?.incomplete_surveys?.length > 0
        ) {
          const inccompleteSurvey = results?.incomplete_surveys.map(
            (userSurvey) => {
              return {
                survey_name: userSurvey.survey_name,
                self_survey_link: `${baseUrl}/${userSurvey.self_survey_link}`,
              };
            }
          );

          setIncompleteSelfSurveys(inccompleteSurvey);
        }
      }
    } catch (err) {
      console.error(err.response.data.error.message);
    }
  }, [getSurveyResults, selectedSurveys]);

  const inviteHandler = useCallback(
    async (data) => {
      try {
        const result = await sendInvite(data);

        if (result) {
          setInviteEmailAddresses("");

          result.invites_sent > 0 &&
            notify(
              `Invite(s) sent to ${result.invites_sent} recipient(s)`,
              "success"
            );
          result.invites_failed > 0 &&
            notify(
              `Invite(s) to ${result.invites_failed} recipient(s) have already been sent`,
              "error"
            );

          await fetchData();
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
      }
    },
    [notify, sendInvite, fetchData]
  );

  const sendEvent = useCallback(
    async (data) => {
      try {
        let formdata = new URLSearchParams();
        formdata.append("actid", 28045100);
        formdata.append("key", "c1ebfdc51a663d5a4252fc99a65a9adf570a9989");
        formdata.append("event", data.event);
        formdata.append("eventdata", data.eventdata);
        formdata.append("visit", JSON.stringify({ email: data.email }));
        axios
          .post("https://trackcmp.net/event", formdata, {
            headers: {
              accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (err) {
        notify(err, "error");
        console.log(err);
      }
    },
    [notify]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return surveyResults?.data ? (
    <div>
      <Header logOut={logOut} />

      <section
        className={`container lg:px-0 pt-[140px] pb-[60px] mx-auto lg:w-[1050px]`}
      >
        <div className="pt-8 pb-8 flex items-center space-x-2">
          <div className="subtitle">{t("surveyResults.select_survey")}</div>
          <div className="w-full lg:w-1/2">
            <FormControl className="xl:!mr-4 !mr-2 lg:!min-w-[15%]" fullWidth>
              <Select
                value={selectedSurveys}
                id="allSurveys"
                name="allSurveys"
                onChange={(e) => {
                  const value = e.target.value;
                  const lastSelected = value[value.length - 1];

                  const newValue =
                    lastSelected === "all" || value.length === 0
                      ? ["all"]
                      : value.filter((survey) => survey !== "all");

                  setSelectedSurveys(newValue);
                }}
                multiple
              >
                <MenuItem value="all">
                  {t("surveyResults.all_surveys")}
                </MenuItem>
                {allSurveys.map((survey) => {
                  return (
                    <MenuItem key={survey.id} value={survey.id}>
                      {survey.block_title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="lg:w-[calc(50%-17px)] w-full mb-[34px] self-end">
            <h2 className="title-primary mb-2">
              {t("surveyResults.page_title")}
            </h2>
            <p className="subtitle mb-5">{t("surveyResults.page_text")}</p>
            <div className="bg-[#E9E9E9] flex flex-wrap rounded-[15px] p-6">
              <>
                <div className="sm:w-1/2 w-full text-center bg-white rounded-[15px] pt-3 pb-3 px-1 sm:mb-0 mb-4">
                  <h3 className="title-primary-sm mb-1 uppercase">
                    {t("surveyResults.summary_results_scorecard")}
                  </h3>
                  <PolarArea
                    className="mx-auto max-w-full p-2"
                    data={radarData}
                    options={{
                      animation: { duration: 1400 },
                      plugins: {
                        ChartDataLabels,
                        datalabels: {
                          formatter: function (value, context) {
                            return radarData.short_labels[
                              context.dataIndex
                            ].toUpperCase();
                          },
                          anchor: "end",
                          align: "center",
                          font: {
                            family: "Poppins, sans-serif",
                            weight: "bold",
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                      clip: { left: 0, top: 0, right: 0, bottom: 0 },
                      scales: {
                        r: {
                          angleLines: {
                            display: true,
                          },
                          ticks: {
                            maxTicksLimit: 1,
                            display: false,
                          },
                          pointLabels: {
                            /* render: "label",
                             * arc: true,
                             * fontColor: "#000",
                             * position: "outside", */
                            display: false,
                            /* centerPointLabels: true, */
                            /* font: {}, */
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div className="sm:w-1/2 w-full pt-3">
                  <div className="lg:px-5 px-4">
                    <h3 className="title-primary-sm mb-3">
                      {t("surveyResults.strength.title")}
                    </h3>
                  </div>
                  <div className="lg:px-5 px-4 mb-7">
                    <div className="align-top inline-block">
                      <span
                        className={`icon-strength-weakness bg-${strength.title} mr-2 `}
                      >
                        <img
                          className="icon-inner-img"
                          src={imageUrl + strength.img}
                          alt={strength.title}
                        />
                      </span>
                    </div>
                    <div className="align-middle inline-block w-2/3">
                      <h4 className="title-base">{strength.title}</h4>
                      <p className="subtitle">{strength.text}</p>
                    </div>
                  </div>

                  <div className="lg:px-5 px-4">
                    <h3 className="title-primary-sm mb-3">
                      {t("surveyResults.weakness.title")}
                    </h3>
                  </div>
                  <div className="lg:px-5 px-4">
                    <div className="align-top inline-block">
                      <span
                        className={`icon-strength-weakness bg-${weakness.title} mr-2`}
                      >
                        <img
                          className="icon-inner-img"
                          src={imageUrl + weakness.img}
                          alt={weakness.title}
                        />
                      </span>
                    </div>
                    <div className="align-middle inline-block w-2/3">
                      <h4 className="title-base">{weakness.title}</h4>
                      <p className="subtitle ">{weakness.text}</p>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>

          <SurveyTabs
            all_sum={all_sum}
            surveyResults={surveyResults}
            start_date={start_date}
            end_date={end_date}
            openTab={openTab}
            setOpenTab={setOpenTab}
            t={t}
            inviteEmailAddresses={inviteEmailAddresses}
            setInviteEmailAddresses={setInviteEmailAddresses}
            usersurveylink={`${frontendurl}/user-survey/${surveyResults.slug}`}
            selfsurveylink={`${frontendurl}/self-survey/${surveyResults.slug}/${surveyResults.manager_invite_uuid}`}
            copied={copied}
            setCopied={setCopied}
            inviteHandler={inviteHandler}
            surveyResultsId={surveyResults.id}
            surveyClosingDate={surveyResults.survey_closing_date}
            surveyInviteBased={surveyResults.invite_based}
            self_access_is_set={self_access_is_set}
            participantDetails={surveyResults?.detailed_participant_summary?.filter(
              (filtpart) =>
                filtpart?.email_invite?.email_address ? true : false
            )}
            userDetails={userDetails}
            sendEvent={sendEvent}
          />

          {surveyResults?.data?.categories?.map((item) => {
            return (
              <SurveyResultsBox
                key={item.id}
                item={item}
                toggleActive={toggleActive}
                t={t}
                setToggleActive={setToggleActive}
              />
            );
          })}
          <div className="w-full mt-5">
            <div className="flex justify-between">
              <h2 className="title-primary mb-8">
                {t("surveyResults.feedback.title")}
              </h2>
              <div className="flex space-x-4 mr-4 h-[50px]">
                <button
                  className={`graph-btn ${
                    view === "summary"
                      ? "text-white bg-dark-purple"
                      : "text-dark-purple"
                  }`}
                  onClick={() => setView("summary")}
                >
                  {t("analyze.managers.summary")}
                  <span className="ml-2 px-2 py-1 text-xs font-semibold text-dark-purple bg-white rounded-full">
                    Beta
                  </span>
                </button>
                <button
                  className={`graph-btn ${
                    view === "user"
                      ? "text-white bg-dark-purple"
                      : "text-dark-purple"
                  }`}
                  onClick={() => setView("user")}
                >
                  {t("analyze.managers.list")}
                </button>
              </div>
            </div>

            {surveyResults?.data?.inputs?.questions?.map((question) => {
              return (
                <SurveyTextQuestionBox
                  key={question.id}
                  item={question}
                  expandActive={expandActive}
                  setExpandActive={setExpandActive}
                  view={view}
                  t={t}
                />
              );
            })}
          </div>

          <div className="mt-5 box-secondary px-10">
            <h2 className="title-white-2xl text-center mb-8">
              {t("surveyResults.summary.title")}
            </h2>
            <div className="flex flex-wrap lg:mb-11">
              <div className="lg:pl-6 lg:w-1/2 w-full self-center">
                {SurveySummaryResultsData &&
                  SurveySummaryResultsData.map((item, ind) => {
                    return <SurveySummaryResults key={ind} item={item} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {toggleActive !== null &&
        toggleActive !== undefined &&
        surveyResults?.data?.categories?.map((item, ind) => {
          return (
            toggleActive === item.title && (
              <ModalCategories
                userDetails={userDetails}
                academylink={`${frontendurl}/managers/academy`}
                item={item}
                t={t}
                key={item.id}
                setToggleActive={setToggleActive}
              />
            )
          );
        })}
      <FooterInner align="center" />
    </div>
  ) : incompleteSelfSurveys?.length > 0 ? (
    <>
      <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
        <p className="mb-2 text-lg">
          {t("surveyResults.info.self_assessment_required")}
        </p>
        <ul className="">
          {incompleteSelfSurveys.map((survey, index) => (
            <li className="flex space-x-2" key={index}>
              <div>{survey.survey_name}:</div>
              <a
                href={survey.self_survey_link}
                target="_blank"
                rel="noreferrer"
                className="text-red-500 hover:text-red-700"
              >
                {t("surveyResults.info.click_to_complete_self_survey")}
              </a>
            </li>
          ))}
        </ul>

        <p className="mt-2 text-sm text-gray-600">
          {t("surveyResults.info.after_survey_refresh")}
        </p>
      </div>
      <button className="button m-2" onClick={logOut}>
        {t("menu.logout")}
      </button>
    </>
  ) : (
    <div className="p-4">
      <div>{t("surveyResults.info.no_results_for_manager")}</div>
      <button className="button mt-2" onClick={logOut}>
        {t("menu.logout")}
      </button>
    </div>
  );
};

export default SurveyResults;
