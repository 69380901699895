import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import managerTemplate from "../../files/manager_template.xlsx";
import employeeTemplate from "../../files/employee_template.xlsx";
import * as XLSX from "xlsx";
import DataChangeModal from "./DataChangeModal";
import InitializeOptionsLists from "../../modules/options";

const UploadForm = ({ importType, setImportStatus }) => {
  const { t } = useTranslation();

  const { availableManagerFields, availableEmployeeFields } =
    InitializeOptionsLists();

  const { uploadUsersHandler, notify, setUploadedUsers, customFields } =
    useOutletContext();

  const managerFields = useMemo(
    () => [
      {
        key: "first_name",
        text: t("field.first_name"),
        editable: true,
        type: "fixed",
      },
      {
        key: "last_name",
        text: t("field.last_name"),
        editable: true,
        type: "fixed",
      },
      { key: "email", text: t("field.email"), editable: true, type: "fixed" },
      // dynamically add custom fields if length > 0 else return empty array
      // TBD How to handle localization for custom fields?
      ...(customFields.length > 0
        ? customFields.map((field) => ({
            key: field.field_name,
            text: field.field_description,
            editable: true,
            type: "custom",
          }))
        : []),
    ],
    [customFields, t]
  );

  const employeeFields = [
    { key: "first_name", text: t("field.first_name"), editable: true },
    { key: "last_name", text: t("field.last_name"), editable: true },
    { key: "email", text: t("field.email"), editable: true },
    {
      key: "manager_user_ids",
      text: t("field.manager"),
      editable: true,
    },
  ];

  const availableFields = useMemo(() => {
    if (importType === "manager") {
      const prepcustomfields = customFields.map((field) => {
        return {
          text: field.field_description,
          value: field.field_name,
        };
      });

      const mergedCustomFields = [
        ...prepcustomfields,
        ...availableManagerFields,
      ];

      return mergedCustomFields;
    } else if (importType === "employee") {
      return availableEmployeeFields;
    }
    return [];
  }, [
    importType,
    customFields,
    availableManagerFields,
    availableEmployeeFields,
  ]);

  const fileInputRef = useRef(null);

  const [fileUploaded, setFileUploaded] = useState([]);

  const [chosenFields, setChosenFields] = useState([]);

  const [changedData, setChangedData] = useState(null);

  const [invalidRows, setInvalidRows] = useState([]);

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const validateData = useCallback(
    (alluserdata) => {
      // Create a map of field index to target field
      const fieldIndexMap = chosenFields.reduce((acc, field) => {
        acc[field.index] = field.target;
        return acc;
      }, {});

      // Skip the first row as it contains headers
      const newInvalidRows = alluserdata.slice(1).map((user, rowIndex) => {
        let rowHasError = false;

        const invalidCells = user.map((cell, cellIndex) => {
          const target = fieldIndexMap[cellIndex];

          if (!target) return false;

          if (target === "email" && !isValidEmail(cell)) {
            rowHasError = true;
            return true;
          }

          if (["first_name", "last_name"].includes(target) && !cell) {
            rowHasError = true;
            return true;
          }

          if (target === "manager_emails") {
            const emails = cell
              .split(",")
              .filter((email) => !isValidEmail(email));
            if (emails.length > 0) {
              rowHasError = true;
              return true;
            }
          }

          return false;
        });

        return rowHasError ? { rowIndex: rowIndex + 1, invalidCells } : null;
      });

      setInvalidRows(newInvalidRows.filter((row) => row !== null));
    },
    [chosenFields]
  );

  const handleUpload = useCallback(
    (e) => {
      e.preventDefault();

      const files = e.target.files,
        f = files[0];
      if (files.length === 0) {
        setFileUploaded([]);
        setChosenFields([]);
        setInvalidRows([]);
        return;
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });

        setFileUploaded(dataParse);

        const firstRow = dataParse[0];
        const chosenFields = [];
        firstRow.forEach((field, index) => {
          const availableField = availableFields.find(
            (availableField) => availableField.text === field
          );
          if (availableField) {
            chosenFields.push({ target: availableField.value, index });
          }
        });

        setChosenFields(chosenFields);
      };

      reader.readAsBinaryString(f);
    },
    [availableFields]
  );

  useEffect(() => {
    if (fileUploaded.length > 1) validateData(fileUploaded);
  }, [fileUploaded, validateData]);

  const chosenFieldsHandler = useCallback(
    (e, ind) => {
      const checkIfAlreadyChosen = chosenFields.findIndex(
        (field) => field.index === ind
      );

      setChosenFields((prior) => {
        if (checkIfAlreadyChosen === -1) {
          return [...prior, { target: e.target.value, index: ind }];
        } else {
          return prior.map((field, index) => {
            if (index === checkIfAlreadyChosen) {
              return { target: e.target.value, index: ind };
            } else {
              return field;
            }
          });
        }
      });
    },
    [chosenFields]
  );

  const removeField = (index) => {
    setChosenFields((prior) => {
      const fields = [...prior];
      const indexToRemove = fields.findIndex((field) => field.index === index);
      if (indexToRemove !== -1) {
        fields.splice(indexToRemove, 1);
      }
      return fields;
    });
  };

  const formatData = () => {
    // removed first row because it's the header
    const formattedUsers = fileUploaded.slice(1).map((field) => {
      const mapChosenFields = chosenFields.map(({ target, index }) => ({
        [target]: field[index],
      }));
      return Object.assign({}, ...mapChosenFields);
    });

    return formattedUsers;
  };

  const handleUploadUsers = async (type, data) => {
    const result = await uploadUsersHandler(data, type, false);

    if (result.data) {
      if (
        result.data.requestUserInput &&
        result.data.requestUserInput.length > 0
      ) {
        setChangedData(result.data.requestUserInput);
      }

      const { type, results } = result.data;

      if (results && type) {
        setUploadedUsers((prior) => ({
          ...prior,
          [type]:
            type === "manager"
              ? [...prior[type], ...results]
              : { ...prior[type], ...results },
        }));
      }

      if (result.data.requestUserInput.length === 0) {
        setChangedData(null);
        setImportStatus(true);
      }

      let successCount = 0;

      if (type === "manager") {
        successCount = results.length;
      } else if (type === "employee") {
        let employeesUploaded = [];

        // results is an object with keys as manager ids and values as array of employees

        Object.values(results).forEach((employee) => {
          employeesUploaded = [...employeesUploaded, ...employee];
        });

        // filter out duplicate employee ids. Use Set to remove duplicates
        const uniqueEmployeesUploaded = [...new Set(employeesUploaded)];

        successCount = uniqueEmployeesUploaded.length;
      }

      notify(`${successCount} imported successfully`, "success");
    }
  };

  useEffect(() => {
    setFileUploaded([]);
    setChosenFields([]);
    setImportStatus(false);
    if (importType === "manager" || importType === "employee") {
      fileInputRef.current.value = ""; // reset input value
    }
  }, [setImportStatus, importType]);

  const handleDownload = ({ filename, file }) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = filename;
    link.click();
  };

  const handleCellEdit = (rowIndex, cellIndex, newValue) => {
    const updatedFileUploaded = [...fileUploaded];
    updatedFileUploaded[rowIndex][cellIndex] = newValue;
    setFileUploaded(updatedFileUploaded);
  };

  const handleRemoveRow = (rowIndex) => {
    const updatedFileUploaded = [...fileUploaded];
    updatedFileUploaded.splice(rowIndex, 1);
    setFileUploaded(updatedFileUploaded);
  };

  const sortByInvalidRows = () => {
    const fileUploadedHeader = fileUploaded[0];
    const fileUploadedErrors = [];
    const fileUploadedValid = [];

    fileUploaded.forEach((row, rowIndex) => {
      // skip the first row as this refers to the headers
      if (rowIndex === 0) return;
      // check if index is present in invalidRows
      if (invalidRows.some((invalidRow) => invalidRow.rowIndex === rowIndex)) {
        fileUploadedErrors.push(row);
      } else {
        fileUploadedValid.push(row);
      }
    });

    const sortedFileUploaded = [
      fileUploadedHeader,
      ...fileUploadedErrors,
      ...fileUploadedValid,
    ];

    setFileUploaded(sortedFileUploaded);
  };

  return (
    importType && (
      <div className="box-white upload-form">
        <div className="pt-4 title-primary-md mb-6">
          {importType === "manager"
            ? t("upload_form.managers_title")
            : t("upload_form.employees_title")}
        </div>
        <div className="pt-4 flex items-center">
          <span className="title-primary-base inline-block mr-4 w-[150px]">
            {t("upload_form.sample_file_label")}
          </span>
          <button
            className="button btn-sm"
            onClick={() =>
              handleDownload(
                importType === "manager"
                  ? {
                      filename: "manager_template.xlsx",
                      file: managerTemplate,
                    }
                  : {
                      filename: "employee_template.xlsx",
                      file: employeeTemplate,
                    }
              )
            }
          >
            {t("upload_form.sample_file_btn")}
          </button>
        </div>
        <div className="w-full flex items-center pt-4">
          <label htmlFor="file_input">
            {t("upload_form.upload_file_label")}
          </label>
          <input
            ref={fileInputRef}
            onChange={handleUpload}
            className="block border-primary border-[1px] !bg-white py-2"
            id="file_input"
            type="file"
            accept=".csv , .xlsx, .xls"
          ></input>
        </div>
        <div className="pt-4 flex items-center">
          <span className="title-primary-base inline-block mr-4 w-[150px]">
            {t("upload_form.available_fields_label")}
          </span>
          {availableFields.map((field, index) => (
            <div key={index} className="inline-block mr-4">
              {field.text}
            </div>
          ))}
        </div>
        {fileUploaded?.length > 0 && (
          <>
            <div className="bg-[#dedff1] lg:p-6 rounded-[18px] mt-6">
              <table className="w-full admin-data-table">
                <thead>
                  <tr>
                    <th scope="col">{t("upload_form.table.field_name")}</th>
                    <th scope="col">
                      {t("upload_form.table.available_fields")}
                    </th>
                    <th scope="col" className="title-primary-base">
                      <span className="sr-only">
                        {t("upload_form.table.edit")}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fileUploaded[0].map((header, ind) => (
                    <tr key={header}>
                      <td className="title-primary-base">{header}</td>
                      <td>
                        <FormControl className="w-full !max-w-full">
                          <Select
                            onChange={(e) => chosenFieldsHandler(e, ind)}
                            value={
                              chosenFields?.find((field) => field.index === ind)
                                ?.target || ""
                            }
                          >
                            <MenuItem value="" disabled>
                              {t("upload_form.table.select_placeholder")}
                            </MenuItem>
                            {availableFields.map((option, ind) => (
                              <MenuItem
                                key={ind}
                                value={option.value}
                                disabled={
                                  chosenFields.some(
                                    (chosenfield) =>
                                      chosenfield.target === option.value
                                  ) ||
                                  option.disabled ||
                                  false
                                }
                              >
                                {option.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </td>
                      <td className="lg:text-end text-center">
                        <div
                          onClick={() => removeField(ind)}
                          className="!text-red-500 hover:!text-red-600 text-sm font-bold cursor-pointer"
                        >
                          {t("upload_form.table.remove_btn")}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {fileUploaded[0].length === chosenFields.length && (
              <div className="bg-[#dedff1] lg:p-6 rounded-[18px] mt-6">
                <div className="pt-4">
                  <div className="overflow-x-auto">
                    <table className="w-full admin-data-table">
                      <thead>
                        <tr>
                          {fileUploaded[0].map((header, index) => (
                            <th key={index}>{header}</th>
                          ))}
                          <th></th>
                          <th>
                            {" "}
                            <button
                              onClick={sortByInvalidRows}
                              disabled={invalidRows?.length === 0}
                              className="button btn-sm disabled:!bg-gray-400 disabled:!cursor-not-allowed disabled:!opacity-50"
                            >
                              {"Error Sort"}
                            </button>
                          </th>
                        </tr>
                      </thead>

                      {fileUploaded.map((user, rowIndex) => {
                        // Skip the first row as it contains headers
                        if (rowIndex === 0) return null;

                        const rowHasError = invalidRows.some(
                          (invalidRow) => invalidRow.rowIndex === rowIndex
                        );

                        return (
                          <tbody key={rowIndex}>
                            <tr>
                              {user.map((cell, cellIndex) => {
                                return (
                                  <td key={cellIndex}>
                                    <input
                                      className={
                                        invalidRows.find(
                                          (invalidRow) =>
                                            invalidRow.rowIndex === rowIndex
                                        )?.invalidCells[cellIndex]
                                          ? "text-red-500 outline outline-red-500"
                                          : ""
                                      }
                                      type="text"
                                      value={cell}
                                      onChange={(e) =>
                                        handleCellEdit(
                                          rowIndex,
                                          cellIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                );
                              })}
                              <td className="lg:text-end text-center">
                                <div
                                  onClick={() => handleRemoveRow(rowIndex)}
                                  className="text-red-500 hover:text-red-600 text-sm font-bold cursor-pointer"
                                >
                                  Remove
                                </div>
                              </td>
                              <td>
                                {rowHasError && (
                                  <div className="text-red-500 font-bold">
                                    {"Check Error(s)"}
                                  </div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {changedData && (
          <DataChangeModal
            dataList={changedData}
            role={importType}
            fields={importType === "manager" ? managerFields : employeeFields}
            setChangedData={setChangedData}
            handleUploadUsers={handleUploadUsers}
          />
        )}
        <div className="flex justify-between pt-4 items-center">
          {/* Display number of errors left to fix */}
          <div>
            {invalidRows?.length > 0 && (
              <div className="text-red-500 font-bold text-md">
                {`${t("error.errors_to_correct")} ${invalidRows?.length}`}
              </div>
            )}
          </div>
          <button
            onClick={() => handleUploadUsers(importType, formatData())}
            disabled={
              chosenFields?.length === fileUploaded[0]?.length &&
              invalidRows?.length === 0
                ? false
                : true
            }
            className="button btn-primary min-w-[150px] disabled:!bg-gray-400 disabled:!cursor-not-allowed disabled:!opacity-50"
          >
            {t("upload_form.table.import_btn")}
          </button>
        </div>
      </div>
    )
  );
};

export default UploadForm;
