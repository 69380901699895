import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import LinkEmployeeModal from "./LinkEmployeeModal";

const SelectedSurveyGroup = ({
  selectedSurveyGroup,
  setShowFirstPage,
  notify,
  t,
  navigate,
  setActiveTab,
  activeTab,
  sendInvite,
}) => {
  const {
    title,
    block_title,
    starting_date,
    ending_date,
    managers,
    total_participants,
    total_managers,
    invite_based,
    average_completion_rate,
    survey_closed,
  } = selectedSurveyGroup;

  const { handleAddRespondents, employeeList } = useOutletContext();

  const [selectedManager, setSelectedManager] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [linkEmployeeModalOpen, setLinkEmployeeModalOpen] = useState(false);

  // Determine survey status
  const startDate = new Date(starting_date).setHours(0, 0, 0, 0);
  const endDate = new Date(ending_date).setHours(23, 59, 59, 999);
  const currentDate = new Date();

  const surveyNotStarted = currentDate < startDate;
  const surveyClosed =
    currentDate > endDate || average_completion_rate === 100 || survey_closed;
  const surveyActive =
    endDate > currentDate &&
    currentDate > startDate &&
    !surveyNotStarted &&
    !surveyClosed;

  const allEmployees =
    managers?.length > 0 &&
    managers.reduce((acc, manager) => {
      if (selectedManager && selectedManager.id !== manager.id) return acc;
      return [...acc, ...manager.participants];
    }, []);

  const filteredManagerList = managers.filter((manager) => {
    const query = searchQuery.toLowerCase();
    return (
      manager?.first_name?.toLowerCase().includes(query) ||
      manager?.last_name?.toLowerCase().includes(query)
    );
  });

  const filteredEmployeeList = allEmployees.filter((employee) => {
    const query = searchQuery.toLowerCase();
    return employee?.email_invite?.email_address?.toLowerCase().includes(query);
  });

  const surveyStatus = surveyNotStarted ? (
    <span className="survey-status-red">
      <BlockIcon />
      {t("surveys.status.not_started")}
    </span>
  ) : surveyActive ? (
    <span className="survey-status-yellow">
      <BlockIcon />
      {t("surveys.status.in_progress")}
    </span>
  ) : (
    <span className="survey-status-green">
      <CheckCircleIcon />
      {t("surveys.status.completed")}
    </span>
  );

  const copyData = (type, data) => {
    const baseRoute = window.location.origin;
    navigator.clipboard.writeText(`${baseRoute}/${type}/${data}`);
    notify(t("general.copied_to_clipboard"), "success");
  };

  const handleClickManager = (tab, selectedManager) => {
    setActiveTab(tab);
    setSelectedManager(selectedManager ? selectedManager : null);
  };

  const handleSearch = (searchQuery) => {
    setSearchQuery(searchQuery);
  };

  const closeLinkEmployeeModal = () => {
    setLinkEmployeeModalOpen(false);
  };

  const handleLinkEmployees = async (employeeIds) => {
    const selectedManagerObject = managers.find(
      (manager) => manager.id === selectedManager?.id
    );

    if (!selectedManagerObject) {
      notify(t("surveys.error.added_participants"), "error");
      return;
    }

    try {
      await handleAddRespondents(employeeIds, selectedManagerObject.survey_id);
      setLinkEmployeeModalOpen(false);
    } catch (error) {
      notify(t("surveys.error.added_participants"), "error");
    }
  };

  return (
    <>
      <div className="relative box-white w-full mb-4">
        <div className="lg:flex lg:flex-wrap">
          <div className="lg:w-1/3 lg:pl-3 lg:order-2 lg:text-right lg:pt-16 space-x-4">
            {activeTab === "invitees" &&
              selectedManager &&
              !surveyClosed &&
              !selectedManager?.survey_closed && (
                <button
                  onClick={() => setLinkEmployeeModalOpen(true)}
                  className="btn-secondary btn-sm"
                >
                  {t("surveys.button.add_participants")}
                </button>
              )}

            <button
              onClick={() => navigate("/admin/analyze/company")}
              className="btn-primary btn-sm"
            >
              {t("surveys.button.see_results")}
            </button>
            {/* <button className="btn-primary btn-sm ml-2">
              {t("surveys.button.duplicate")}
            </button> */}
          </div>
          <div className="lg:w-2/3 lg:pr-3 lg:order-1">
            <h3 className="title-primary-md mb-2">{title}</h3>
            <h3 className="title-primary-base mb-4">{block_title}</h3>
            <div className="flex justify-between mb-4">
              <p className="mb-2 lg:pr-3 text-primary">
                <span className="subtitle block mb-1">
                  {t("surveys.starting_date")}:
                </span>
                <strong>{new Date(starting_date).toLocaleDateString()}</strong>
              </p>
              <p className="mb-2 lg:px-3 text-primary">
                <span className="subtitle block mb-1">
                  {t("surveys.closing_date")}:
                </span>
                <strong>{new Date(ending_date).toLocaleDateString()}</strong>
              </p>
              <p className="mb-2 lg:px-3">
                <span className="subtitle block mb-1">
                  {t("surveys.completion_rate")}:
                </span>
                <div className="progress-bar bg-opacity-20">
                  <span
                    className="!bg-primary"
                    style={{ width: `${average_completion_rate}%` }}
                  ></span>
                </div>
              </p>
              <div className="lg:ml-3">
                <p className="mb-1">
                  <span className="subtitle mr-1">
                    {t("surveys.invited_managers")}:
                  </span>
                  <strong className="text-primary">{total_managers}</strong>
                </p>
                {invite_based && (
                  <p className="mb-2">
                    <span className="subtitle mr-1">
                      {t("surveys.invited_employees")}:
                    </span>
                    <strong className="text-primary">
                      {total_participants}
                    </strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:float-right lg:w-1/2 lg:text-right mb-2">
          <input
            type="text"
            placeholder={t("surveys.name_filter_placeholder")}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="survey-tabs before:!hidden admin-survey-tabs clear-both">
          <div
            className={`survey-tab cursor-pointer ${
              activeTab === "managers" ? "active" : ""
            }`}
            onClick={() => handleClickManager("managers", null)}
          >
            {t("surveys.tab.managers")}
          </div>
          {invite_based && (
            <div
              className={`survey-tab cursor-pointer ${
                activeTab === "invitees" ? "active" : ""
              }`}
              onClick={() => handleClickManager("invitees", null)}
            >
              {t("surveys.tab.employees")}
            </div>
          )}
        </div>
        {activeTab === "managers" && (
          <div className="bg-[#dedff1] lg:p-6 rounded-[18px] relative h-[80vh]">
            <div className="max-h-full overflow-auto">
              <table className="admin-data-table min-w-full">
                <thead>
                  <tr>
                    <th>{t("surveys.table_head.name_email")}</th>
                    <th>{t("surveys.table_head.total_responses")}</th>
                    <th>
                      <span className="sr-only">Separator</span>
                    </th>
                    <th>{t("surveys.table_head.team_members_invited")}</th>
                    {!invite_based && <th>Link</th>}
                    <th>{t("surveys.table_head.status")}</th>
                    <th>{t("surveys.manager_self_survey_link")}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredManagerList?.length > 0 &&
                    filteredManagerList.map((manager) => {
                      let participants_survey_complete =
                        manager?.participants.filter(
                          (participant) => participant.survey_complete
                        ).length;

                      return (
                        <tr key={manager.email}>
                          <td className="relative !pl-10">
                            <AccountCircleIcon className="absolute top-1/2 left-3 -translate-y-1/2 !w-5 !h-5 opacity-40" />
                            <h3 className="text-[18px]">
                              {manager.first_name} {manager.last_name}
                            </h3>
                            {manager.email}
                          </td>
                          <td className="text-center font-bold">
                            {participants_survey_complete}
                          </td>
                          <td className="text-center font-light text-[26px]">
                            /
                          </td>
                          <td className="text-center">
                            {invite_based === false ? (
                              "N/A"
                            ) : (
                              <span className="font-bold">
                                {manager.participants.length}
                              </span>
                            )}
                            <span
                              className={`${
                                invite_based
                                  ? "cursor-pointer"
                                  : "pointer-events-none opacity-20"
                              } ml-3`}
                            >
                              <SearchIcon
                                onClick={() => {
                                  handleClickManager("invitees", manager);
                                  setShowFirstPage(false);
                                }}
                                className="w-5 h-5 text-gray-500"
                              />
                            </span>
                          </td>
                          {!invite_based && (
                            <td className="text-center">
                              <button
                                onClick={() =>
                                  copyData("user-survey", manager.slug)
                                }
                                className="survey-status-red"
                              >
                                {t("managers.copy_invite")}
                              </button>
                            </td>
                          )}
                          <td className="text-center">
                            {invite_based === false ? (
                              "N/A"
                            ) : participants_survey_complete !==
                              manager.participants.length ? (
                              surveyStatus
                            ) : (
                              <span className="survey-status-green">
                                <CheckCircleIcon />
                                {t("surveys.status.completed")}
                              </span>
                            )}
                          </td>
                          <td className="text-center">
                            {manager?.manager_invite_uuid ? (
                              !manager.self_survey_complete ? (
                                <button
                                  onClick={() =>
                                    copyData(
                                      "self-survey",
                                      `${manager.slug}/${manager.manager_invite_uuid}`
                                    )
                                  }
                                  className="survey-status-red"
                                >
                                  {t("surveys.copy")}
                                </button>
                              ) : (
                                t("surveys.completed")
                              )
                            ) : (
                              t("surveys.not_active")
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {activeTab === "invitees" && (
          <div className="bg-[#dedff1] lg:p-6 rounded-[18px] relative h-[80vh] overflow-auto">
            <div className="max-h-full overflow-auto">
              <table className="admin-data-table min-w-full">
                <thead>
                  <tr>
                    <th>{t("surveys.table_head.email")}</th>
                    <th>{t("surveys.table_head.total_invites_received")}</th>
                    <th>{t("surveys.invite_link")}</th>
                    <th>{t("surveys.send_reminder")}</th>
                  </tr>
                </thead>
                <tbody>
                  {invite_based &&
                    filteredEmployeeList?.length > 0 &&
                    filteredEmployeeList.map((participant) => (
                      <tr key={participant.id}>
                        <td className="relative !pl-10">
                          <AccountCircleIcon className="absolute top-1/2 left-3 -translate-y-1/2 !w-5 !h-5 opacity-40" />
                          {participant.email_invite.email_address}
                        </td>
                        <td className="text-center">
                          {participant.email_invite.number_reminders_sent}
                        </td>
                        <td className="text-center">
                          {participant.survey_complete || surveyClosed ? (
                            t("surveys.completed")
                          ) : surveyActive ? (
                            <button
                              onClick={() =>
                                copyData(
                                  "user-survey",
                                  `${participant.survey.survey_slug}/${participant.anon_request}`
                                )
                              }
                              className="survey-status-red"
                            >
                              {t("managers.copy_invite")}
                            </button>
                          ) : (
                            t("surveys.not_active")
                          )}
                        </td>
                        <td className="text-center">
                          {participant.survey_complete || surveyClosed ? (
                            t("surveys.completed")
                          ) : surveyActive ? (
                            <button
                              onClick={() =>
                                sendInvite(participant.email_invite.id)
                              }
                              className="survey-status-green"
                            >
                              {t("surveys.send_reminder")}
                            </button>
                          ) : (
                            t("surveys.not_active")
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {employeeList?.length > 0 &&
        filteredEmployeeList?.length > 0 &&
        selectedManager && (
          <LinkEmployeeModal
            linkEmployeeModalOpen={linkEmployeeModalOpen}
            employees={
              // include employees for the selected manager and that are not participants
              employeeList.filter(
                (employee) =>
                  employee.manager_user_ids.includes(selectedManager.id) &&
                  !filteredEmployeeList.find(
                    (participant) =>
                      participant.email_invite.email_address === employee.email
                  )
              )
            }
            handleLinkEmployees={handleLinkEmployees}
            closeLinkEmployeeModal={closeLinkEmployeeModal}
          />
        )}
    </>
  );
};

export default SelectedSurveyGroup;
