import { Fragment, useState, useCallback, useEffect } from "react";
import {
  useOutletContext,
  useLocation,
  useNavigate,
  Link,
  Outlet,
} from "react-router-dom";
import logo from "../../images/logo.svg";
import { ReactComponent as homeIcon } from "../../images/home.svg";
import { ReactComponent as companyIcon } from "../../images/company.svg";
import { ReactComponent as employeesIcon } from "../../images/employees.svg";
import { ReactComponent as groupsIcon } from "../../images/groups.svg";
import { ReactComponent as managersIcon } from "../../images/managers.svg";
import { ReactComponent as surveysIcon } from "../../images/surveys.svg";
import { ReactComponent as uploadIcon } from "../../images/upload.svg";
import { ReactComponent as settingsIcon } from "../../images/settings.svg";

import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";

import {
  axiosgetUsers,
  axiosGetCompanyTemplates,
  axiosEditUsers,
  axiosgetTags,
  axiosEditTags,
  axiosCompanyTemplateRoute,
  axiosSurveyRoute,
  axiosUploadUsers,
  axiosCompanyRoute,
  axiosGetFilterData,
  axiosGetCompanyCustomFields,
  axiosEditCompanyCustomFields,
  axiosCreateCompanyCustomFields,
  axiosGetAllWidgets,
  axiosGetDraftSurveys,
  axiosSendInvite,
  axiosGetLanguagesRoute,
  axiosAddRespondents,
} from "../../helpers/axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Layout = () => {
  const {
    token,
    notify,
    userDetails,
    getUserDetails,
    getSurveyResults,
    frontendurl,
    logOut,
  } = useOutletContext();

  const [companyTemplates, setCompanyTemplates] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [managerList, setManagerList] = useState(null);
  const [employeeList, setEmployeeList] = useState(null);
  const [tags, setTags] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const [uploadedUsers, setUploadedUsers] = useState({
    manager: [],
    employee: {},
  });

  const [surveyOverviewResults, setSurveyOverviewResults] = useState(null);

  const [companyOverviewResults, setCompanyOverviewResults] = useState(null);
  const [managerOverviewResults, setManagerOverviewResults] = useState(null);
  const [groupedManagerSurveyResults, setGroupedManagerSurveyResults] =
    useState(null);

  const [search, setSearch] = useState({
    selectedsurvey: ["all"],
    selectedgroups: "all",
    daterange: "",
    text: "",
  });

  const { t } = useTranslation();

  // get current location
  const { pathname } = useLocation();

  const navigation = [
    {
      name: t("menu.new_survey"),
      link: `newsurvey`,
      icon: homeIcon,
      current: pathname === "/admin/newsurvey",
    },
    {
      name: t("menu.analyze.company"),
      icon: "",
      current: false,
      children: [
        {
          name: t("menu.analyze.company"),
          link: `analyze/company`,
          icon: companyIcon,
          current: pathname === "/admin/analyze/company",
        },
        {
          name: t("menu.analyze.groups"),
          link: `analyze/groups`,
          icon: groupsIcon,
          current: pathname === "/admin/analyze/groups",
        },
        {
          name: t("menu.analyze.managers"),
          link: `analyze/managers`,
          icon: managersIcon,
          current: pathname === "/admin/analyze/managers",
        },
      ],
    },
    {
      name: t("menu.manage.managers"),
      icon: "",
      current: false,
      children: [
        {
          name: t("menu.manage.managers"),
          link: `manage/managers`,
          icon: managersIcon,
          current: pathname === "/admin/manage/managers",
        },
        {
          name: t("menu.manage.surveys"),
          link: `manage/surveys`,
          icon: surveysIcon,
          current: pathname === "/admin/manage/surveys",
        },
        {
          name: t("menu.manage.groups"),
          link: `manage/groups`,
          icon: companyIcon,
          current: pathname === "/admin/manage/groups",
        },
        {
          name: t("menu.manage.employees"),
          link: `manage/employees`,
          icon: employeesIcon,
          current: pathname === "/admin/manage/employees",
        },
        {
          name: t("menu.manage.uploads"),
          link: `manage/uploads`,
          icon: uploadIcon,
          current: pathname === "/admin/manage/uploads",
        },
        {
          name: t("menu.manage.settings"),
          link: `manage/settings`,
          icon: settingsIcon,
          current: pathname === "/admin/manage/settings",
        },
      ],
    },
  ];

  const modifyCompany = useCallback(
    async (data) => {
      try {
        const result = await axiosCompanyRoute(
          `/${userDetails.company_id.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { data },
          }
        );

        if (result) {
          await getUserDetails();
          return result;
        }
      } catch (err) {
        throw new Error("There was a problem with your request");
      }
    },
    [token, userDetails.company_id.id, getUserDetails]
  );

  const getUsers = useCallback(async () => {
    setManagerList(null);
    setEmployeeList(null);

    try {
      const getusers = await axiosgetUsers({
        // TBD - add archived_user param
        // params: { archived_user },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { managers, employees } = getusers.data;

      setManagerList(managers);
      setEmployeeList(employees);
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const getCompanyTemplates = useCallback(
    async (type) => {
      try {
        const getCompanyTemplates = await axiosGetCompanyTemplates({
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCompanyTemplates(getCompanyTemplates.data);
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const editUsers = useCallback(
    async (type, method, data = null) => {
      try {
        const results = await axiosEditUsers({
          params: { type, method },
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // im not sure why i left this commented out. Maybe it was causing an error?
        // await getUsers();

        if (results.data) {
          return results.data;
        }
      } catch (err) {
        notify("There was a problem with your request", "error");
        console.log(err);
      }
    },
    [token, notify]
  );

  const getTags = useCallback(
    async (option = null) => {
      try {
        const gettags = await axiosgetTags({
          params: { option },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTags(gettags.data);
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const getFilterData = useCallback(
    async (option = null) => {
      try {
        const filterDataResults = await axiosGetFilterData({
          params: { option },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return filterDataResults.data;
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const editTags = useCallback(
    async (method, data = null) => {
      try {
        const results = await axiosEditTags({
          params: { method },
          ...(method === "update" || method === "create" ? { data } : {}),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return results?.data;
      } catch (err) {
        notify("There was a problem with your request", "error");
        console.log(err);
      }
    },
    [token, notify]
  );

  const getWidgets = useCallback(async () => {
    try {
      const filterDataResults = await axiosGetAllWidgets({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return filterDataResults.data;
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const getDraftSurveys = useCallback(async () => {
    try {
      const results = await axiosGetDraftSurveys({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return results.data;
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const uploadUsersHandler = useCallback(
    async (data, type = null, single_import = false) => {
      try {
        const result = await axiosUploadUsers({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { data, type, single_import },
        });

        if (result) {
          return result;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const createSurveyHandler = useCallback(
    async (survey_data) => {
      try {
        const result = await axiosSurveyRoute({
          data: { data: survey_data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const createTemplateHandler = useCallback(
    async (selectedTemplate) => {
      const data = {
        selectedTemplate,
      };

      try {
        const result = await axiosCompanyTemplateRoute({
          data: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          // determine logic later
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const updateSurveyHandler = useCallback(
    async (surveyid, data) => {
      try {
        const result = await axiosSurveyRoute.put(
          `/${surveyid}`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data) {
          const results = await getSurveyResults();
          return results;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, getSurveyResults]
  );

  const getCustomFields = useCallback(async () => {
    try {
      const getcustomfields = await axiosGetCompanyCustomFields({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomFields(getcustomfields.data);
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const updateCustomFields = useCallback(
    async (id) => {
      try {
        const editcustomfields = await axiosEditCompanyCustomFields(`/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (editcustomfields.data) {
          const { status } = editcustomfields.data;
          notify(`Custom field ${status}`, "success");
          getCustomFields();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, notify, getCustomFields]
  );

  const createCustomFields = useCallback(
    async (data) => {
      try {
        const createcustomfields = await axiosCreateCompanyCustomFields({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { data },
        });

        if (createcustomfields.data) {
          notify("Custom field created", "success");
          getCustomFields();
          return createcustomfields.data;
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
      }
    },
    [token, notify, getCustomFields]
  );

  const getCompanyOverviewData = useCallback(
    async (search) => {
      const { selectedsurvey, selectedgroups, daterange } = search;

      const survey_results = await getSurveyResults({
        group_by: "company_stats",
        filter_by: { selectedsurvey, selectedgroups, daterange },
      });
      setCompanyOverviewResults(survey_results);
    },
    [getSurveyResults, setCompanyOverviewResults]
  );

  const getManagerOverViewResults = useCallback(
    async (search) => {
      const {
        selectedsurvey,
        selectedgroups,
        daterange,
        manager_group_ids = "",
        text,
      } = search;

      // dont fetch data if filter is based on internal name search (ie. text)
      if (text) return;

      const manager_results = await getSurveyResults({
        group_by: "managers",
        filter_by: {
          selectedsurvey,
          selectedgroups,
          daterange,
          manager_group_ids,
        },
      });
      setManagerOverviewResults(manager_results);
    },
    [getSurveyResults, setManagerOverviewResults]
  );

  const getGroupedManagerData = useCallback(
    async (search) => {
      const { selectedsurvey, selectedgroups, daterange } = search;

      const survey_results = await getSurveyResults({
        group_by: "groups",
        filter_by: { selectedsurvey, selectedgroups, daterange },
      });
      setGroupedManagerSurveyResults(survey_results);
    },
    [getSurveyResults, setGroupedManagerSurveyResults]
  );

  const getSurveyOverviewResults = useCallback(async () => {
    const results = await getSurveyResults({ group_by: "block_id" });

    const draftResults = await getDraftSurveys();

    setSurveyOverviewResults([...results, ...draftResults]);
    getUsers();
    getCompanyTemplates();
  }, [
    getSurveyResults,
    getUsers,
    getCompanyTemplates,
    setSurveyOverviewResults,
    getDraftSurveys,
  ]);

  const sendInvite = useCallback(
    async (invite_id) => {
      try {
        const result = await axiosSendInvite(`/${invite_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          notify(t("general.reminder_sent"), "success");
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, notify, t]
  );

  const getLanguages = useCallback(async () => {
    try {
      const result = await axiosGetLanguagesRoute({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data) {
        return result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const handleAddRespondents = useCallback(
    async (employee_ids, survey_id) => {
      const data = {
        employee_ids: employee_ids,
        survey_id: survey_id,
      };

      try {
        const result = await axiosAddRespondents({
          data: { data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          await getSurveyOverviewResults();
          notify(t("general.participants_added"), "success");
          return result.data;
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
      }
    },
    [token, notify, getSurveyOverviewResults, t]
  );

  useEffect(() => {
    if (token) {
      getCustomFields();
    }
  }, [token, getCustomFields]);

  const navigate = useNavigate();
  const location = useLocation();

  // redirect to base route if user is in either "admin" or "admin/manage" or "admin/analyze"
  // this is to prevent user from accessing these routes directly
  useEffect(() => {
    if (
      location.pathname === "/admin" ||
      location.pathname === "/admin/manage" ||
      location.pathname === "/admin/analyze"
    ) {
      navigate("/");
    }
  }, [navigate, location.pathname]);

  return (
    <>
      <div className="bg-[#dedff1]">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-primary"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto p-6 pb-2 bg-white sidebar-mobile">
                    <img
                      width="134"
                      height="23"
                      className="max-w-full block mx-auto mb-10"
                      src={logo}
                      alt="VeeMind"
                    />
                    <nav className="flex flex-1 flex-col">
                      <ul className="">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.link}
                              className={classNames(
                                item.current ? "current" : "",
                                item.link === "newsurvey" ? " no-stroke" : ""
                              )}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                        <li>
                          <button
                            onClick={logOut}
                            className="button border-primary text-primary w-full mt-8"
                          >
                            {t("menu.logout")}
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col p-8">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 sidebar max-h-full ">
            <img
              width="134"
              height="23"
              className="max-w-full block mx-auto mb-14"
              src={logo}
              alt="VeeMind"
            />
            <div className="overflow-y-auto p-1">
              <nav className="flex flex-1 flex-col">
                <ul className="">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      {!item.children ? (
                        <Link
                          to={item.link}
                          className={classNames(
                            item.current ? "current" : "",
                            item.link === "newsurvey" ? " no-stroke" : ""
                          )}
                        >
                          <item.icon aria-hidden="true" />
                          {item.name}
                        </Link>
                      ) : (
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <h3>{item.name}</h3>
                              <ul>
                                {item.children.map((subitem) => (
                                  <li key={subitem.name}>
                                    <Link
                                      to={subitem.link}
                                      className={classNames(
                                        subitem.current ? "current" : "",
                                        subitem.link === "manage/settings"
                                          ? " no-stroke fill"
                                          : ""
                                      )}
                                    >
                                      <subitem.icon aria-hidden="true" />
                                      {subitem.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                  <li>
                    <button
                      onClick={logOut}
                      className="button border-primary text-primary w-full mt-8"
                    >
                      {t("menu.logout")}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 px-4 py-4 shadow-sm sm:px-6 lg:hidden bg-white">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-primary lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            <img
              width="134"
              height="23"
              className="max-w-full"
              src={logo}
              alt="VeeMind"
            />
          </div>
        </div>

        <div className="lg:min-h-screen">
          <main className="p-8 lg:pl-72 flex flex-col h-full">
            <div className="h-full flex flex-col max-w-full">
              {
                <Outlet
                  context={{
                    token,
                    managerList,
                    employeeList,
                    getUsers,
                    editUsers,
                    getTags,
                    setTags,
                    tags,
                    editTags,
                    getSurveyResults,
                    uploadUsersHandler,
                    getCompanyTemplates,
                    companyTemplates,
                    setCompanyTemplates,
                    createTemplateHandler,
                    createSurveyHandler,
                    notify,
                    modifyCompany,
                    userDetails,
                    updateSurveyHandler,
                    frontendurl,
                    getFilterData,
                    uploadedUsers,
                    setUploadedUsers,
                    customFields,
                    updateCustomFields,
                    createCustomFields,
                    companyOverviewResults,
                    setCompanyOverviewResults,
                    getCompanyOverviewData,
                    search,
                    setSearch,
                    getManagerOverViewResults,
                    managerOverviewResults,
                    getGroupedManagerData,
                    groupedManagerSurveyResults,
                    getSurveyOverviewResults,
                    surveyOverviewResults,
                    getWidgets,
                    sendInvite,
                    getLanguages,
                    handleAddRespondents,
                  }}
                />
              }
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
