import { useEffect } from "react";
import Header from "../modules/Header";
import Footer from "../modules/Footer";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  const { t } = useTranslation();
  const formHandler = async (e) => {
    e.preventDefault();

    window.location.href = `${backendUrl}/api/connect/auth0`;
  };

  useEffect(() => {
    // get browser language
    const browserLanguage = navigator.language || "en";
    // set language to browser language
    i18n.changeLanguage(browserLanguage);
  }, []);

  return (
    <div className="dark">
      <Header />
      <section className="lg:h-screen flex flex-wrap justify-center overflow-hidden lg:px-0 px-[15px] pt-[70px] lg:pb-[70px] dark:bg-dark-login">
        <form
          onSubmit={formHandler}
          className="lg:w-[335px] w-full mx-auto self-center text-center py-[60px]"
        >
          <h2 className="title-primary dark:text-white mb-7">
            {t("login.title")}
          </h2>
          <p className="dark:text-gray_purple text-gray_2 mb-10 tracking-[.08em]">
            {t("login.text")}
          </p>
          <button
            type="submit"
            className="bg-secondary text-black border-secondary button block w-full mb-10 py-[15px]"
          >
            {t("login.cta")}
          </button>
          <p className="dark:text-gray_purple text-gray_2 tracking-[.08em]">
            {t("login.terms_of_use.text")}{" "}
            <a
              href="https://veemind.com/privacy_policy/"
              className="lg:text-white text-black underline"
            >
              {t("login.terms_of_use.cta")}
            </a>
          </p>
        </form>
      </section>
      <Footer />
    </div>
  );
};

export default Login;
