import logo from "../images/logo.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = ({
  mode,
  logOut,
  stepNumber,
  requestedLanguage,
  setRequestedLanguage,
  availableLanguages,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const changeLanguage = (e) => {
    setRequestedLanguage(e.target.value);
  };

  return (
    <header className="fixed top-0 left-0 w-screen bg-white z-10">
      <div className="container mx-auto lg:py-6 py-4 flex flex-row font-base">
        <div className="basis-1/3 self-center">
          <a href="/">
            <img src={logo} width="134" height="23" alt="VeeMind" />
          </a>
        </div>
        <div className="basis-2/3 text-right lg:text-base text-sm self-center">
          {mode === "usersurvey" && (
            <select
              disabled={stepNumber}
              value={requestedLanguage}
              onChange={changeLanguage}
              className={`lg:mr-3 mr-2 border rounded-md py-1 px-2 ${
                stepNumber ? "cursor-not-allowed" : ""
              }`}
            >
              {availableLanguages?.map((language) => (
                <option key={language.id} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          )}

          <a
            href="http://veemind.com/support"
            target="_blank"
            rel="noreferrer"
            className="text-primary lg:ml-11 ml-5"
          >
            {t("footer.support")}
          </a>
          {mode !== "usersurvey" && location.pathname !== "/login" && (
            <button
              onClick={logOut}
              className="button border-primary text-primary lg:w-[144px] lg:ml-7 ml-3"
            >
              {t("menu.logout")}
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
