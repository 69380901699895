import { useEffect, useState } from "react";
import {
  useOutletContext,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const Home = (props) => {
  const {
    getFilterData,
    companyOverviewResults,
    managerOverviewResults,
    getCompanyOverviewData,
    getManagerOverViewResults,
    getGroupedManagerData,
    groupedManagerSurveyResults,
    search,
    setSearch,
  } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [availableSurveys, setAvailableSurveys] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [showSearchForm, setShowSearchForm] = useState(true);
  const searchQuery = searchParams.get("group");
  const { t } = useTranslation();

  // print node instantiated as null when no data is present. Changes to false, when data is ready, (and true when printing)
  const [printNode, setPrintNode] = useState(null);

  const [triggerSearchQuery, setTriggerSearchQuery] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "text":
        setSearch((prev) => ({ ...prev, text: value }));
        break;
      case "allSurveys":
        let selectedSurvey = value;
        if (
          selectedSurvey.length > 1 &&
          search.selectedsurvey.includes("all")
        ) {
          selectedSurvey = selectedSurvey.filter((val) => val !== "all");
        } else if (
          selectedSurvey.includes("all") ||
          selectedSurvey.length === 0
        ) {
          selectedSurvey = ["all"];
        }
        setSearch((prev) => ({ ...prev, selectedsurvey: selectedSurvey }));
        break;
      case "allGroups":
        setSearch((prev) => ({ ...prev, selectedgroups: value }));
        break;
      case "date":
        setSearch((prev) => ({ ...prev, daterange: value }));
        break;
      default:
        break;
    }

    name !== "text" && setTriggerSearchQuery(true);
  };

  const location = useLocation();
  useEffect(() => {
    setShowSearchForm(true);
  }, [location, setShowSearchForm]);

  const clearGroupHandler = () => {
    searchParams.delete("group");
    setTriggerSearchQuery(true);
    setSearchParams(searchParams);
  };

  const currentpage = useLocation().pathname.split("/").pop();

  useEffect(() => {
    if (currentpage !== "managers") {
      setSearch((prev) => ({ ...prev, text: "" }));
    }
  }, [currentpage, setSearch]);

  useEffect(() => {
    const retrieveFilterData = async () => {
      const survey_results = await getFilterData();
      setAvailableGroups(survey_results.groups);
      setAvailableSurveys(survey_results.surveyblocks);
    };
    retrieveFilterData();
  }, [getFilterData]);

  return (
    <>
      <div className="flex justify-between items-center mb-4 top-bar">
        {!showSearchForm ? (
          <div className="flex w-full justify-between">
            <button
              className="title-primary-sm hover:text-opacity-80"
              onClick={() => {
                setShowSearchForm(true);
              }}
            >
              {t("analyze.survey.back_btn")}
            </button>
            {search?.selectedsurvey[0] !== "all" && (
              <div className="flex space-x-2 items-center">
                <div className="pr-4">Selected Surveys:</div>
                {availableSurveys
                  .filter((survey) => {
                    return search.selectedsurvey.includes(survey.block_id);
                  })
                  .map((survey) => {
                    return (
                      <Chip
                        key={survey.block_id}
                        label={survey.block_title}
                        className="bg-primary text-white m-0.5"
                      ></Chip>
                    );
                  })}
              </div>
            )}
          </div>
        ) : (
          <>
            <FormControl
              className="xl:!mr-4 !mr-2 lg:!min-w-[15%]"
              sx={{ width: 165 }}
            >
              <Select
                value={search.selectedsurvey}
                id="allSurveys"
                name="allSurveys"
                onChange={handleInputChange}
                multiple
              >
                <MenuItem value="all">
                  {t("analyze.company.all_surveys_placeholder")}
                </MenuItem>
                {availableSurveys.map((survey) => {
                  return (
                    <MenuItem key={survey.block_id} value={survey.block_id}>
                      {survey.block_title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              className="xl:!mx-4 !mx-2 lg:!min-w-[15%]"
              sx={{ width: 165 }}
            >
              <Select
                onChange={handleInputChange}
                value={search.selectedgroups}
                id="allGroups"
                name="allGroups"
              >
                <MenuItem value={"all"}>
                  {t("analyze.company.all_groups_placeholder")}
                </MenuItem>
                {availableGroups.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.id}>
                      {group.tag}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <span className="date-wrapper xl:mx-4 mx-2 lg:!min-w-[15%]">
              <input
                id="date-input"
                type="date"
                className="w-full"
                placeholder={t("analyze.managers.date_placeholder")}
                onChange={handleInputChange}
                name="date"
                value={search.daterange}
              />
            </span>
            <input
              disabled={currentpage !== "managers" ? true : false}
              id="text"
              type="text"
              className={`xl:mx-4 mx-2 lg:!min-w-[20%] ${
                currentpage !== "managers" ? "opacity-20" : ""
              }`}
              placeholder={t("analyze.managers.name_filter_placeholder")}
              onChange={handleInputChange}
              name="text"
              value={search.text}
            />
            {searchQuery && (
              <button onClick={clearGroupHandler} className="button">
                Clear Group
              </button>
            )}
            <button
              disabled={printNode === null}
              className="button export-btn xl:ml-4 ml-2 lg:!min-w-[15%] disabled:opacity-20"
              onClick={() => setPrintNode(true)}
            >
              {t("analyze.managers.export_btn")}
            </button>
          </>
        )}
      </div>
      <div className="h-[80vh] overflow-auto relative">
        {
          <Outlet
            context={{
              setPrintNode,
              printNode,
              search,
              showSearchForm,
              setShowSearchForm,
              searchQuery,
              companyOverviewResults,
              managerOverviewResults,
              getCompanyOverviewData,
              getManagerOverViewResults,
              getGroupedManagerData,
              groupedManagerSurveyResults,
              triggerSearchQuery,
              setTriggerSearchQuery,
            }}
          />
        }
      </div>
    </>
  );
};

export default Home;
