import React, { useEffect, useRef } from "react";
import { toPng } from "html-to-image";
import NewWindow from "react-new-window";

const PrintModule = ({ dataForGraph, setPrintNode }) => {
  const graphref = useRef(null);

  useEffect(() => {
    const download = async () => {
      if (graphref.current) {
        const imgData = await toPng(graphref.current, {
          pixelRatio: 1,
        });
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "exported-image.png";
        link.click();
        setPrintNode(false);
      }
    };

    const timeoutId = setTimeout(() => {
      download();
    }, 3000);

    return () => {
      clearTimeout(timeoutId); // Clear the timeout on unmount
    };
  }, [graphref, setPrintNode]);

  return (
    <NewWindow
      features={{ width: 1400, height: 700 }}
      onUnload={() => setPrintNode(false)}
    >
      <div ref={graphref} className="flex p-4" style={{ width: "1400px" }}>
        <>{dataForGraph}</>
      </div>
    </NewWindow>
  );
};

export default PrintModule;
