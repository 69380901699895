import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const NewManagerModal = ({
  handleManagerModalClose,
  handleManagerModalSubmit,
  isManagerModalOpen,
}) => {
  const { t } = useTranslation();

  const [newManager, setNewManager] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewManager((prevManager) => ({
      ...prevManager,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    if (name === "first_name" || name === "last_name") {
      if (value.length < 2) {
        newErrors[name] = "This field must be at least 2 characters";
      } else {
        newErrors[name] = "";
      }
    }

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        newErrors[name] = "Invalid email address";
      } else {
        newErrors[name] = "";
      }
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      handleManagerModalSubmit(newManager);
    }
  };

  useEffect(() => {
    if (!isManagerModalOpen) {
      setNewManager({
        first_name: "",
        last_name: "",
        email: "",
      });
      setErrors({
        first_name: "",
        last_name: "",
        email: "",
      });
    }
  }, [isManagerModalOpen]);

  return (
    isManagerModalOpen && (
      <div className="modal">
        <div className="overlay" onClick={handleManagerModalClose}></div>
        <div className="modal-body md:w-1/3">
          <button className="close-button" onClick={handleManagerModalClose}>
            X
          </button>
          <div className="modal-overflow">
            <div className="modal-inner">
              <div className="w-full p-9">
                <h3 className="title-primary-md mb-6">
                  {t("modal.new_manager.title")}
                </h3>
                <div className="mb-4">
                  <label
                    htmlFor="manager-first-name"
                    className="block font-medium mb-1"
                  >
                    {t("modal.new_manager.first_name")}
                  </label>
                  <input
                    name="first_name"
                    type="text"
                    id="manager-first-name"
                    value={newManager.first_name}
                    onChange={handleChange}
                  />
                  {errors.first_name && (
                    <div className="text-red-500">{errors.first_name}</div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="manager-last-name"
                    className="block font-medium mb-1"
                  >
                    {t("modal.new_manager.last_name")}
                  </label>
                  <input
                    name="last_name"
                    type="text"
                    id="manager-last-name"
                    value={newManager.last_name}
                    onChange={handleChange}
                  />
                  {errors.last_name && (
                    <div className="text-red-500">{errors.last_name}</div>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="manager-email"
                    className="block font-medium mb-1"
                  >
                    {t("modal.new_manager.email")}
                  </label>
                  <input
                    name="email"
                    type="text"
                    id="manager-email"
                    value={newManager.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-red-500">{errors.email}</div>
                  )}
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="disabled:opacity-20 disabled:pointer-events-none button btn-primary min-w-[100px]"
                    disabled={
                      !isFormValid() ||
                      !newManager.email ||
                      !newManager.first_name ||
                      !newManager.last_name
                    }
                  >
                    {t("modal.new_manager.submit_btn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default NewManagerModal;
