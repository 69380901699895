import React, { useState, useEffect, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

import TableRow from "./TableRow";
import InfiniteScroll from "react-infinite-scroll-component";

const EditableTable = ({
  dataList,
  role,
  fields,
  handleAdduser,
  SkeletonComponent,
}) => {
  const { editUsers, editTags, notify, getUsers, getTags } = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [tabledChanges, setTableChanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedData, setSortedData] = useState([]);
  const [endIndex, setEndIndex] = useState(30);
  const [hasMore, setHasMore] = useState(true);

  // default to 30 items per page
  const itemsPerPage = 30;

  useEffect(() => {
    if (dataList?.length > 0) setSortedData([...dataList]);
  }, [dataList]);

  const { t } = useTranslation();

  const handleFilter = useMemo(() => {
    if (sortedData.length === 0) return [];

    const lowercaseSearchQuery = searchQuery.toLowerCase();

    const filteredList = sortedData.filter((user) => {
      for (const field of fields) {
        const value = user[field.key];
        if (
          typeof value === "string" &&
          value.toLowerCase().includes(lowercaseSearchQuery)
        ) {
          return true;
        }
      }
      return false;
    });

    return filteredList;
  }, [searchQuery, sortedData, fields]);

  const handleSearchInput = (searchQuery) => {
    setSearchQuery(searchQuery);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }

    const sorted = [...sortedData].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    setSortedData(sorted);
  };

  const handleBulkSave = async () => {
    const updatedItems = tabledChanges;
    if (updatedItems.length === 0) {
      notify("No changes to save", "info");
    } else {
      let results = [];
      setLoading(true);
      if (role === "tags") {
        results = await editTags("update", updatedItems);
      } else if (role === "managers" || role === "employees") {
        results = await editUsers(role, "update", updatedItems);
      }

      if (results) {
        let errors = false;

        let successUpdates = [];
        let updateErrors = [];

        const hasErrors = results.some((result) => result.errors);

        if (hasErrors) {
          sortedData.forEach((item) => {
            const result = results.find((result) => result.id === item.id);
            delete item.errors;

            if (result) {
              const combineresult = { ...item, ...result };

              if (result.errors) {
                errors = true;
                updateErrors.push(combineresult);
              } else {
                successUpdates.push(combineresult);
              }
            } else {
              successUpdates.push(item);
            }
          });

          setSortedData([...updateErrors, ...successUpdates]);
        } else {
          if (role === "managers" || role === "employees") {
            getUsers();
          } else if (role === "tags") {
            await getTags("getmanagerstatistics");
          }
        }
        if (errors) {
          notify(`Some ${role} could not be saved`, "error");
        } else {
          notify(`${role} saved`, "success");
          setEditMode(false);
          setTableChanges([]);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editMode === false && tabledChanges?.length > 0) {
      setTableChanges([]);
      if (role === "tags") {
        getTags("getmanagerstatistics");
      } else if (role === "managers" || role === "employees") {
        getUsers();
      }
    }
  }, [editMode, getUsers, role, setTableChanges, tabledChanges, getTags]);

  const loadMoreData = () => {
    if (endIndex < handleFilter.length) {
      setEndIndex((prev) => prev + itemsPerPage);
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (handleFilter?.length > itemsPerPage) {
      setHasMore(true);
      setEndIndex(itemsPerPage);
    } else {
      setHasMore(false);
    }
  }, [handleFilter]);

  const itemsToDisplay =
    handleFilter?.length > 0 && handleFilter.slice(0, endIndex);

  return (
    <>
      <div className="flex justify-between items-center mb-4 top-bar">
        <input
          type="text"
          placeholder={t(`${role}.search_input`)}
          onChange={(e) => handleSearchInput(e.target.value)}
        />
        <button onClick={handleAdduser} className="button">
          {t(`${role}.add_new_btn`)}
        </button>
      </div>
      {!handleFilter ? (
        <SkeletonComponent />
      ) : handleFilter?.length > 0 ? (
        <div className="h-[80h] overflow-auto">
          <InfiniteScroll
            dataLength={itemsToDisplay.length}
            next={loadMoreData}
            hasMore={hasMore}
            loader="Loading..."
          >
            <table className={`admin-data-table min-w-full table-${role}`}>
              {/* Table header */}
              <thead>
                <tr>
                  {fields.map(({ key, text }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      style={{ cursor: "pointer" }}
                    >
                      {text}
                      {sortColumn === key && (
                        <span>{sortDirection === "asc" ? " ▲" : " ▼"}</span>
                      )}
                    </th>
                  ))}
                  <th>
                    <div className="flex w-full justify-end pr-4 space-x-2">
                      <Fab
                        onClick={(event) => {
                          event.preventDefault();
                          setEditMode((prior) => !prior);
                        }}
                        disabled={loading}
                        aria-label="edit"
                      >
                        {editMode === false ? <EditIcon /> : <CancelIcon />}
                      </Fab>

                      <Fab
                        onClick={(event) => {
                          event.preventDefault();
                          handleBulkSave();
                        }}
                        disabled={editMode === false || loading}
                        aria-label="edit"
                      >
                        {loading ? <CircularProgress /> : <SaveIcon />}
                      </Fab>
                    </div>
                  </th>
                  <th scope="col">
                    <span className="sr-only">{t("table.edit_btn")}</span>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {itemsToDisplay.map((item) => (
                  <TableRow
                    errors={item.errors}
                    key={item.id}
                    item={item}
                    fields={fields}
                    editMode={editMode}
                    setTableChanges={setTableChanges}
                    t={t}
                  />
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      ) : (
        <div className="text-center">
          <p>{t("general.no_results_found")}</p>
        </div>
      )}
    </>
  );
};

export default EditableTable;
