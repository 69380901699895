import React from "react";

const SkeletonEmployees = () => {
  return (
    <table className="admin-data-table min-w-full table-managers animate-pulse">
      {/* Table header */}
      <thead>
        <tr>
          <th className="w-[20%]">
            <div className="skeleton-title w-1/3 mx-auto !h-2.5 my-[5px]"></div>
          </th>
          <th className="w-[20%]">
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th className="w-[20%]">
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th className="w-[20%]">
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th className="w-[20%]"></th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td>
            <div className="bg-[#e2e4ea] w-full h-9 rounded-[10px] min-w-100px"></div>
          </td>
          <td className="xl:text-right text-center">
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
            <div className="bg-[#e2e4ea] w-12 h-6 rounded-[22px] inline-block align-middle xl:m-[2px] m-[1px]"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SkeletonEmployees;
