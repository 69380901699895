import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const SurveyStep2 = ({
  userData,
  surveyData,
  submitSurveyHandler,
  surveyAnswers,
  gatherSurveyAnswers,
  categories,
  currentCategory,
  setCurrentCategory,
  currentQuestions,
  setCurrentQuestions,
}) => {
  const { t } = useTranslation();
  const selectOptions = [
    "almost never",
    "rarely",
    "sometimes",
    "frequently",
    "often",
    "almost always",
  ];

  const [showNextButton, setShowNextButton] = useState(false);

  const current_category_image = surveyData
    ?.filter((filtertype) => filtertype.id === categories[currentCategory].id)
    .map((cat) => cat.category_image.url);

  const SurveyQuestion = ({ item, count }) => (
    <li data-count={count}>
      <h3>{item.text}</h3>
      {/* response types defined by question */}
      {item.type === "Scale" && (
        <div className="dot-select">
          {selectOptions.map((selection, ind) => {
            return (
              <span
                key={ind}
                onClick={() => gatherSurveyAnswers(item, selection)}
                className={`dot-select-option ${
                  surveyAnswers[item.id] === selection && "before:!bg-secondary"
                }`}
              >
                {t(selection)}
                {/*Translate only text not value */}
              </span>
            );
          })}
        </div>
      )}
    </li>
  );

  // check if all questions in category (section) have been answered
  useEffect(() => {
    const getquestionids = surveyData
      ?.find((filtertype) => filtertype.id === categories[currentCategory].id)
      .skills.flatMap((skill) =>
        skill.questions.flatMap((question) => question.id)
      );

    const checkforquestion = getquestionids.every((questionid) =>
      Object.keys(surveyAnswers).some(
        (key) => Number(key) === Number(questionid)
      )
    );

    if (checkforquestion) {
      setShowNextButton(true);
    } else {
      setShowNextButton(false);
    }
  }, [surveyData, currentCategory, categories, surveyAnswers]);

  let managerName = userData?.email;

  if (userData?.first_name && userData?.last_name) {
    managerName = `${userData?.first_name} ${userData?.last_name}`;
  }

  return (
    <section className="container flex flex-wrap py-[85px] mx-auto">
      <div
        className="step-banner-vertical hidden lg:block lg:sticky lg:top-[86px]"
        style={{
          backgroundImage: "url(" + imageUrl + current_category_image[0] + ")",
        }}
      ></div>
      <div className="lg:w-7/12 w-full lg:pl-[80px]">
        <h2 className="title-primary mb-2">{t("step2.title")}</h2>
        {userData && (
          <p className="subtitle mb-[70px]">
            {t("step2.description")} <strong>{managerName}</strong>
          </p>
        )}

        <ul className="questions-wrapper">
          {surveyData
            ?.find(
              (filtertype) => filtertype.id === categories[currentCategory].id
            )
            .skills.map((skill) =>
              skill.questions.map((question) => {
                return (
                  <SurveyQuestion
                    key={question.id}
                    item={question}
                    count={question.count}
                  />
                );
              })
            )}
        </ul>
        <div className="text-center w-full text-primary">
          <button
            className={
              "button text-primary mr-6 float-left step-button disabled:opacity-20 " +
              (currentCategory === 0 ? " invisible" : "")
            }
            onClick={() => {
              setCurrentCategory((prior) => {
                return prior > 0 ? prior - 1 : 0;
              });
              setCurrentQuestions((prior) => prior - 1);
              window.scrollTo(0, 0);
            }}
          >
            {"<"}
          </button>
          {currentQuestions}
          <div className="progress-bar bg-[#D1D1D1] lg:max-w-[60%] max-w-[calc(100%-190px)] inline-block mx-3 my-4 align-middle">
            <span
              style={{
                width: `calc(${currentQuestions}*100%/${surveyData.length})`,
              }}
              className="!bg-secondary"
            ></span>
          </div>
          {surveyData.length}
          <button
            className={
              "button text-primary ml-6 float-right step-button disabled:opacity-20" +
              (currentCategory === categories.length - 1 ? " invisible" : "")
            }
            disabled={!showNextButton ? true : false}
            onClick={() => {
              submitSurveyHandler({
                final_step: false,
                save_per_category: true,
              });
              window.scrollTo(0, 0);
            }}
          >
            {">"}
          </button>
          {currentQuestions === surveyData.length && (
            <button
              disabled={
                surveyData.filter((category) => {
                  const getquestionids = category.skills.flatMap((skill) =>
                    skill.questions.flatMap((question) => question.id)
                  );

                  const checkforquestion = getquestionids.every((questionid) =>
                    Object.keys(surveyAnswers).some(
                      (key) => Number(key) === Number(questionid)
                    )
                  );

                  return checkforquestion;
                }).length !== surveyData.length
              }
              className="disabled:opacity-20 button bg-secondary border-0 xl:w-[335px] lg:w-[280px] w-full text-black py-4 font-medium my-5"
              onClick={() => {
                submitSurveyHandler({});
                // window.scrollTo(0, 0);
              }}
            >
              {t("step2.cta")}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default SurveyStep2;
