import { useEffect, useState, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditableTable from "./partials/table";
import NewManagerModal from "./partials/NewManagerModal";
import SkeletonManagers from "./skeletons/SkeletonManagers";

const Manage = () => {
  const {
    getUsers,
    managerList,
    getTags,
    tags,
    uploadUsersHandler,
    notify,
    customFields,
  } = useOutletContext();

  const { t } = useTranslation();

  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);

  useEffect(() => {
    // get active managers. (first param is the role, second param is the archived status. true = archived, false = active)
    getUsers();
    getTags();
  }, [getUsers, getTags]);

  const fields = useMemo(
    () => [
      {
        key: "first_name",
        text: t("field.first_name"),
        editable: true,
        type: "fixed",
      },
      {
        key: "last_name",
        text: t("field.last_name"),
        editable: true,
        type: "fixed",
      },
      { key: "email", text: t("field.email"), editable: true, type: "fixed" },
      {
        key: "manager_tag_ids",
        text: t("field.group"),
        inputType: "select",
        editable: true,
        type: "fixed",
        options:
          tags?.length > 0
            ? tags
                .sort((a, b) => {
                  return a.tag.localeCompare(b.tag);
                })
                .map((tag) => ({ id: tag.id, display: tag.tag }))
            : [],
      },
      // dynamically add custom fields if length > 0 else return empty array
      // TBD how custom fields will be localized.
      ...(customFields?.length > 0
        ? customFields.map((field) => ({
            key: field.field_name,
            text: field.field_description,
            editable: true,
            type: "custom",
          }))
        : []),
    ],
    [tags, customFields, t]
  );

  const handleManagerAdd = () => {
    setIsManagerModalOpen(true);
  };

  const handleManagerModalClose = () => {
    setIsManagerModalOpen(false);
  };

  const handleManagerModalSubmit = async (managerData) => {
    try {
      const result = await uploadUsersHandler([managerData], "manager", true);

      // Assuming the result contains the newly created manager object

      let manageradded = false;
      let errors = "Manager not added";

      if (result?.data?.results?.length === 1) {
        if (result?.data?.results[0].id) {
          manageradded = true;
        } else if (result?.data?.results[0].errors) {
          let newerrors = result?.data?.results[0].errors;

          // check if errors is an array or string

          if (typeof newerrors === "string") {
            errors = newerrors;
          }
        }
      } else if (result?.data?.failedUploaded?.length === 1) {
        errors = result?.data?.failedUploaded[0].errors || errors;
      }

      if (manageradded) {
        notify("Manager added successfully", "success");
        getUsers();
        getTags();
        setIsManagerModalOpen(false);
      } else {
        notify(errors, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <EditableTable
        SkeletonComponent={SkeletonManagers}
        dataList={managerList}
        role="managers"
        fields={fields}
        handleAdduser={handleManagerAdd}
      />

      <NewManagerModal
        isManagerModalOpen={isManagerModalOpen}
        handleManagerModalClose={handleManagerModalClose}
        handleManagerModalSubmit={handleManagerModalSubmit}
      />
    </>
  );
};

export default Manage;
