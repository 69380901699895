import { useCallback, useEffect, useMemo, useState } from "react";
import { useOutletContext, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreateSurvey from "./partials/CreateSurvey";
import SearchIcon from "@mui/icons-material/Search";
import SkeletonSurveys from "./skeletons/SkeletonSurveys";
import RenderSelectedSurveyGroup from "./partials/RenderSelectedSurveyGroup";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Surveys = (props) => {
  const {
    managerList,
    employeeList,
    companyTemplates,
    createSurveyHandler,
    notify,
    uploadedUsers,
    surveyOverviewResults,
    getSurveyOverviewResults,
    sendInvite,
  } = useOutletContext();

  const [createNewSurvey, setCreateNewSurvey] = useState(false);
  const [draftSurveyDetails, setDraftSurveyDetails] = useState(null);
  const [selectedSurveyGroup, setSelectedSurveyGroup] = useState(null);
  const [activeTab, setActiveTab] = useState("managers");
  const [showFirstPage, setShowFirstPage] = useState(true);

  const [selectedSurveyFilter, setSelectedSurveyFilter] = useState("all");

  const { t } = useTranslation();

  const navigate = useNavigate();

  // if query param newsurvey is true, open the create survey form
  const location = useLocation();

  useEffect(() => {
    setShowFirstPage(true);
  }, [location, setShowFirstPage]);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { newsurvey, list, invite_handler } = Object.fromEntries(
    searchParams.entries()
  );

  useEffect(() => {
    if (newsurvey === "true") {
      setCreateNewSurvey(true);
      setShowFirstPage(false);
    }
  }, [location.search, searchParams, newsurvey]);

  const handleSurveyGroupClick = useCallback(
    (surveyGroup) => {
      setSelectedSurveyGroup(surveyGroup.block_id);
    },
    [setSelectedSurveyGroup]
  );

  const handleDraftSurveyClick = useCallback(
    (draftSurvey) => {
      setShowFirstPage(false);
      setCreateNewSurvey(true);
      setDraftSurveyDetails(draftSurvey);
    },
    [setCreateNewSurvey, setDraftSurveyDetails]
  );

  const handleBackClick = (page) => {
    if (page === "managers") {
      setSelectedSurveyGroup(null);
    } else if (page === "invitees") {
      setActiveTab("managers");
    }
  };

  const SurveyGroups = ({
    surveyOverviewResults,
    handleSurveyGroupClick,
    t,
    setShowFirstPage,
  }) => {
    return surveyOverviewResults.map((surveyGroup) => {
      const survey_finished = surveyGroup.survey_closed;
      const startDate = new Date(surveyGroup.starting_date);
      const endDate = new Date(surveyGroup.ending_date);
      const currentDate = new Date();

      // const totalDuration = endDate - startDate;
      // const elapsedTime = currentDate - startDate;
      // const progressPercentage = (elapsedTime / totalDuration) * 100;
      const surveyNotStarted = currentDate < startDate;
      const surveyActive =
        endDate.setHours(23, 59, 59, 999) > currentDate.setHours(0, 0, 0, 0) &&
        survey_finished === false;

      const average_completion_rate = surveyGroup.average_completion_rate;
      const blockTitle = surveyGroup.block_title;
      // const title = surveyGroup.title;

      // determine where to place the block title
      // console.log(blockTitle);

      return (
        <div
          key={surveyGroup.block_id}
          className={`cursor-pointer box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative${
            !surveyNotStarted && !surveyActive
              ? " survey-closed bg-opacity-40"
              : ""
          }`}
          onClick={() => {
            handleSurveyGroupClick(surveyGroup);
            setShowFirstPage(false);
          }}
        >
          <h3 className="title-primary-md mb-2">{blockTitle}</h3>
          {/* <h4 className="title-primary-sm mb-2">{title}</h4> */}

          {surveyNotStarted ? (
            <p className="text-red-500 text-sm">
              {t("surveys.survey_not_started")}
            </p>
          ) : (
            <div className="progress-bar bg-opacity-20 mb-4">
              <span
                className="!bg-primary"
                style={{ width: `${average_completion_rate}%` }}
              ></span>
            </div>
          )}
          <p className="mb-2 subtitle space-x-4">
            <span>
              {t("surveys.start")}: {startDate.toISOString().split("T")[0]}
            </span>
            <span>
              {t("surveys.end")}: {endDate.toISOString().split("T")[0]}
            </span>
          </p>
          {surveyNotStarted ? (
            <span className="status-red after:!hidden !text-white !mt-0">
              {t("surveys.status.not_started")}
            </span>
          ) : surveyActive ? (
            <span className="status-green !mt-0">
              {t("surveys.status.active")}
            </span>
          ) : (
            <span className="status-red !bg-dark_green after:!hidden !text-white !mt-0">
              {t("surveys.status.closed")}
            </span>
          )}
          <div className="absolute bottom-4 right-4 cursor-pointer">
            <SearchIcon />
          </div>
        </div>
      );
    });
  };

  const DraftSurveys = ({ draftSurveyResults, handleDraftSurveyClick, t }) => {
    return draftSurveyResults.map((draftSurvey) => {
      let startDate = "TBD";
      let endDate = "TBD";

      if (draftSurvey.survey_start_date) {
        const date = new Date(draftSurvey.survey_start_date);
        startDate = date.toISOString().split("T")[0];
      }

      if (draftSurvey.survey_closing_date) {
        const date = new Date(draftSurvey.survey_closing_date);
        endDate = date.toISOString().split("T")[0];
      }

      return (
        <div
          key={draftSurvey.survey_slug}
          className={`cursor-pointer box-white sm:w-[calc(25%-12px)] sm:mr-4 [&:nth-child(4n)]:mr-0 mb-4 w-full relative survey-closed bg-opacity-40`}
          onClick={() => {
            handleDraftSurveyClick(draftSurvey);
            setShowFirstPage(false);
          }}
        >
          <h3 className="title-primary-md mb-6">{draftSurvey.block_title}</h3>
          <p className="mb-2 subtitle space-x-4">
            <span>
              {t("surveys.start")}: {startDate}
            </span>
            <span>
              {t("surveys.end")} {endDate}
            </span>
          </p>
          <span className="status-orange after:!hidden !text-white !mt-0">
            {"Draft Survey"}
          </span>
          <div className="absolute bottom-4 right-4 cursor-pointer">
            <SearchIcon />
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!surveyOverviewResults) {
      getSurveyOverviewResults();
    }
  }, [surveyOverviewResults, getSurveyOverviewResults]);

  const draftSurveyResults = surveyOverviewResults?.filter(
    (survey) => survey.draft_survey === true
  );

  const activeSurveyResults = surveyOverviewResults?.filter(
    (survey) => !survey.draft_survey
  );

  return createNewSurvey && !showFirstPage ? (
    <CreateSurvey
      draftSurveyDetails={draftSurveyDetails}
      createNewSurvey={createNewSurvey}
      employees={employeeList}
      managers={managerList}
      companyTemplates={companyTemplates}
      createSurveyHandler={createSurveyHandler}
      setCreateNewSurvey={setCreateNewSurvey}
      getSurveyOverviewResults={getSurveyOverviewResults}
      uploadedUsers={uploadedUsers}
      newsurvey={newsurvey}
      list={list}
      invite_handler={invite_handler}
      setShowFirstPage={setShowFirstPage}
    />
  ) : (
    <>
      <div className="flex justify-between items-center mb-4 top-bar">
        {!selectedSurveyGroup && showFirstPage ? (
          <div className="flex items-center space-x-12">
            <h2 className="title-primary-md">{t("surveys.title")}</h2>

            <FormControl
              className="xl:!mr-4 !mr-2 lg:!min-w-[15%]"
              sx={{ width: 200 }}
            >
              <Select
                value={selectedSurveyFilter}
                id="surveyfilter"
                name="surveyfilter"
                onChange={(e) => {
                  setSelectedSurveyFilter(e.target.value);
                }}
              >
                <MenuItem value="all">
                  {t("surveys.all_surveys_placeholder")}
                </MenuItem>
                <MenuItem value="draft">
                  {t("surveys.draft_surveys_placeholder")}
                </MenuItem>
                <MenuItem value="active">
                  {t("surveys.active_surveys_placeholder")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : (
          <button
            className="title-primary-sm hover:text-opacity-80"
            onClick={() => {
              handleBackClick(activeTab);
              setShowFirstPage(true);
            }} // Go back to overview
          >
            {t("analyze.survey.back_btn")}
          </button>
        )}

        <button
          onClick={() => {
            navigate("/admin/newsurvey");
          }}
          className="button"
        >
          {t("surveys.add_new_btn")}
        </button>
      </div>

      <>
        {!surveyOverviewResults ? (
          <SkeletonSurveys />
        ) : surveyOverviewResults?.length > 0 ? (
          <div className="lg:flex lg:flex-wrap">
            {selectedSurveyGroup && !showFirstPage ? (
              <RenderSelectedSurveyGroup
                selectedSurveyGroup={surveyOverviewResults.find(
                  (survey) => survey.block_id === selectedSurveyGroup
                )}
                setShowFirstPage={setShowFirstPage}
                notify={notify}
                t={t}
                navigate={navigate}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                sendInvite={sendInvite}
              />
            ) : (
              <>
                {activeSurveyResults?.length > 0 &&
                  selectedSurveyFilter !== "draft" && (
                    <SurveyGroups
                      surveyOverviewResults={activeSurveyResults}
                      handleSurveyGroupClick={handleSurveyGroupClick}
                      t={t}
                      setShowFirstPage={setShowFirstPage}
                    />
                  )}
                {draftSurveyResults?.length > 0 &&
                  selectedSurveyFilter !== "active" && (
                    <DraftSurveys
                      draftSurveyResults={draftSurveyResults}
                      handleDraftSurveyClick={handleDraftSurveyClick}
                      t={t}
                      setShowFirstPage={setShowFirstPage}
                    />
                  )}
              </>
            )}
          </div>
        ) : (
          <div className="text-center">
            <h3>{t("general.no_results_found")}</h3>
          </div>
        )}
      </>
    </>
  );
};

export default Surveys;
