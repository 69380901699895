import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json";

const axioslogin = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/`,
  method: "post",
});

const axiosGetUserData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/users/me/`,
  method: "get",
});

const axiosUpdateUserData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/users/me/`,
  method: "put",
});

const axiosGetTemplateData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/templates`,
  method: "get",
});

const axiosCompanyTemplateRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/company-templates`,
  method: "post",
});

const axiosCompanyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/companies`,
  method: "put",
});

const axiosGetCompanyTemplates = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/company-templates`,
  method: "get",
});

const axiosSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/surveys`,
  method: "post",
});

const axiosGetSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/surveys`,
  method: "get",
});

const axiosGetFilterData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/filterdata`,
  method: "get",
});

const axiosSubmitSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/answers`,
  method: "post",
});

const axiosSendInviteRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/email-invites`,
  method: "post",
});

const axiosGetAcademyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/academy-contents`,
  method: "get",
});

const axiosUploadUsers = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/upload-users`,
  method: "post",
});

const axiosEditUsers = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/edit-users`,
  method: "post",
});

const axiosgetUsers = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/get-users`,
  method: "get",
});

const axiosgetTags = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/get-tags`,
  method: "get",
});

const axiosEditTags = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/edit-tags`,
  method: "post",
});

const axiosGetCompanyCustomFields = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/custom-field-names`,
  method: "get",
});

const axiosEditCompanyCustomFields = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/custom-field-names`,
  method: "put",
});

const axiosCreateCompanyCustomFields = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/custom-field-names`,
  method: "post",
});

const axiosDataExportRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/data-export`,
  method: "post",
});

const axiosGetAllWidgets = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/widgets`,
  method: "get",
});

const axiosGetDraftSurveys = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/getdraftsurveys`,
  method: "get",
});

const axiosSendInvite = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/sendinvite`,
  method: "post",
});

const axiosGetLanguagesRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/languages`,
  method: "get",
});

const axiosAddRespondents = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/add-respondents`,
  method: "put",
});

const axiosSendEventRoute = ({ event, eventdata, email }) => {
  return (dispatch) => {
    axios
      .post(
        "https://trackcmp.net/event",
        {
          actid: 28045100,
          key: "c1ebfdc51a663d5a4252fc99a65a9adf570a9989",
          event: event,
          eventdata: eventdata,
          visit: JSON.stringify({ email: email }),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "FETCH_TODO",
          payload: event,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export {
  axioslogin,
  axiosGetUserData,
  axiosGetTemplateData,
  axiosSurveyRoute,
  axiosGetSurveyRoute,
  axiosSubmitSurveyRoute,
  axiosSendInviteRoute,
  axiosGetAcademyRoute,
  axiosSendEventRoute,
  axiosUpdateUserData,
  axiosCompanyTemplateRoute,
  axiosUploadUsers,
  axiosgetUsers,
  axiosGetCompanyTemplates,
  axiosEditUsers,
  axiosgetTags,
  axiosEditTags,
  axiosCompanyRoute,
  axiosGetFilterData,
  axiosGetCompanyCustomFields,
  axiosEditCompanyCustomFields,
  axiosCreateCompanyCustomFields,
  axiosDataExportRoute,
  axiosGetAllWidgets,
  axiosGetDraftSurveys,
  axiosSendInvite,
  axiosGetLanguagesRoute,
  axiosAddRespondents,
};
