import { useTranslation } from "react-i18next";
const SurveyStep1 = ({ surveyData, setStepNumber }) => {
  const { t } = useTranslation();

  let managerName = surveyData?.user?.email;

  if (surveyData?.user?.first_name && surveyData?.user?.last_name) {
    managerName = `${surveyData?.user?.first_name} ${surveyData?.user?.last_name}`;
  }

  return (
    <section className="container flex flex-wrap py-[85px] mx-auto">
      <div className="step-banner-horizontal"></div>
      <div className="w-full text-center mb-[50px]">
        <h2 className="title-primary mb-2">{surveyData?.title}</h2>
        {surveyData?.user && (
          <p className="subtitle">
            {t("step1.owner")} <strong>{managerName}</strong>
          </p>
        )}
      </div>
      <div className="lg:w-1/4 px-6 mb-10">
        <span className="icon-secondary icon-leadership mb-5"></span>
        <h3 className="inline-block m-0 align-middle title-primary-md mb-5">
          {t("step1.feature1")}
        </h3>
        <p className="lg:max-w-[204px] text-base">{t("step1.explainer1")}</p>
      </div>
      <div className="lg:w-1/4 px-6 mb-10">
        <span className="icon-secondary icon-feedback mb-5"></span>
        <h3 className="inline-block m-0 align-middle title-primary-md mb-5">
          {t("step1.feature2")}
        </h3>
        <p className="lg:max-w-[204px] text-base">{t("step1.explainer2")}</p>
      </div>
      <div className="lg:w-1/4 px-6 mb-10">
        <span className="icon-secondary icon-data mb-5"></span>
        <h3 className="inline-block m-0 align-middle title-primary-md mb-5">
          {t("step1.feature3")}
        </h3>
        <p className="lg:max-w-[204px] text-base">{t("step1.explainer3")}</p>
      </div>
      <div className="lg:w-1/4 px-6 mb-10">
        <span className="icon-secondary icon-results mb-5"></span>
        <h3 className="inline-block m-0 align-middle title-primary-md mb-5">
          {t("step1.feature4")}
        </h3>
        <p className="lg:max-w-[204px] text-base">{t("step1.explainer4")}</p>
      </div>
      <div className="text-center w-full">
        <button
          className="button bg-secondary border-0 xl:w-[335px] lg:w-[280px] w-full text-black py-4 font-medium mb-5"
          onClick={(e) => {
            setStepNumber(2);
            window.scrollTo(0, 0);
          }}
        >
          {t("step1.cta")}
        </button>
        {surveyData.category.length > 0 && (
          <p className="subtitle">
            {t("step1.timeestimate")}{" "}
            {Math.floor(surveyData.category.length / 2.2)} {t("step1.minutes")}
          </p>
        )}
      </div>
    </section>
  );
};

export default SurveyStep1;
