import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const NewEmployeeModal = ({
  isEmployeeModalOpen,
  handleEmployeeModalClose,
  handleEmployeeModalSubmit,
  managerList,
  mode,
}) => {
  const { t } = useTranslation();

  const [newEmployee, setNewEmployee] = useState({
    first_name: "",
    last_name: "",
    email: "",
    manager_user_ids: [],
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prevGroup) => ({
      ...prevGroup,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    if (name === "first_name" || name === "last_name") {
      if (value.length < 2) {
        newErrors[name] = t("error.field_length_2_chars");
      } else {
        newErrors[name] = "";
      }
    }

    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        newErrors[name] = t("error.invalid_email");
      } else {
        newErrors[name] = "";
      }
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    return Object.values(errors).every((error) => !error);
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      handleEmployeeModalSubmit(newEmployee);
    }
  };

  useEffect(() => {
    if (!isEmployeeModalOpen) {
      setNewEmployee({
        first_name: "",
        last_name: "",
        email: "",
        manager_user_ids: [],
      });
      setErrors({
        first_name: "",
        last_name: "",
        email: "",
      });
    }
  }, [isEmployeeModalOpen]);

  return (
    <>
      {isEmployeeModalOpen && (
        <div className="modal">
          <div className="overlay" onClick={handleEmployeeModalClose}></div>
          <div className="modal-body md:w-1/3">
            <button className="close-button" onClick={handleEmployeeModalClose}>
              X
            </button>
            <div className="modal-overflow">
              <div className="modal-inner">
                <div className="w-full p-9">
                  <h3 className="title-primary-md mb-6">
                    {t("modal.new_employee.title")}
                  </h3>
                  <div className="mb-4">
                    <label
                      htmlFor="employee-first-name"
                      className="block font-medium mb-1"
                    >
                      {t("modal.new_employee.first_name_label")}
                    </label>
                    <input
                      name="first_name"
                      type="text"
                      id="employee-first-name"
                      value={newEmployee.first_name || ""}
                      onChange={handleChange}
                    />
                    {errors.first_name && (
                      <div className="text-red-500">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="employee-last-name"
                      className="block font-medium mb-1"
                    >
                      {t("modal.new_employee.last_name_label")}
                    </label>
                    <input
                      name="last_name"
                      type="text"
                      id="employee-last-name"
                      value={newEmployee.last_name || ""}
                      onChange={handleChange}
                    />
                    {errors.last_name && (
                      <div className="text-red-500">{errors.last_name}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="employee-email"
                      className="block font-medium mb-1"
                    >
                      {t("modal.new_employee.email_label")}
                    </label>
                    <input
                      name="email"
                      type="text"
                      id="employee-email"
                      value={newEmployee.email || ""}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="text-red-500">{errors.email}</div>
                    )}
                  </div>
                  {mode === "new" && (
                    <div className="mb-4">
                      <label
                        htmlFor="employee-manager"
                        className="block font-medium mb-1"
                      >
                        {t("modal.new_employee.manager_label")}
                      </label>
                      <FormControl className="w-full">
                        <Select
                          name="manager_user_ids"
                          id="employee-manager"
                          value={newEmployee.manager_user_ids || []}
                          onChange={handleChange}
                          multiple
                        >
                          {managerList.map(({ first_name, last_name, id }) => (
                            <MenuItem key={id} value={id}>
                              {first_name || ""} {last_name || ""}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="disabled:opacity-20 disabled:pointer-events-none button btn-primary min-w-[100px]"
                      disabled={
                        !isFormValid() ||
                        !newEmployee.email ||
                        !newEmployee.first_name ||
                        !newEmployee.last_name
                      }
                    >
                      {t("modal.new_employee.submit_btn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewEmployeeModal;
