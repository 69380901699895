import React from "react";

const SkeletonManagers = () => {
  return (
    <table className="admin-data-table min-w-full analyze animate-pulse">
      {/* Table header */}
      <thead>
        <tr>
          <th></th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5 my-[5px]"></div>
          </th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
          <th>
            <div className="skeleton-title w-1/3 mx-auto !h-2.5"></div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="title-primary-base w-[15%]">
            <div className="skeleton-title w-2/3 mb-2 !h-2.5"></div>
            <div className="skeleton-title w-1/2 mb-2 !h-2.5"></div>
            <span className="status-green h-6 !bg-[#e2e4ea]"></span>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="title-primary-base">
            <div className="skeleton-title w-2/3 mb-2 !h-2.5"></div>
            <div className="skeleton-title w-1/2 mb-2 !h-2.5"></div>
            <span className="status-green h-6 !bg-[#e2e4ea]"></span>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="title-primary-base">
            <div className="skeleton-title w-2/3 mb-2 !h-2.5"></div>
            <div className="skeleton-title w-1/2 mb-2 !h-2.5"></div>
            <span className="status-green h-6 !bg-[#e2e4ea]"></span>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="title-primary-base">
            <div className="skeleton-title w-2/3 mb-2 !h-2.5"></div>
            <div className="skeleton-title w-1/2 mb-2 !h-2.5"></div>
            <span className="status-green h-6 !bg-[#e2e4ea]"></span>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
        </tr>
        <tr>
          <td className="title-primary-base">
            <div className="skeleton-title w-2/3 mb-2 !h-2.5"></div>
            <div className="skeleton-title w-1/2 mb-2 !h-2.5"></div>
            <span className="status-green h-6 !bg-[#e2e4ea]"></span>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-2/3 h-2/3"></span>
              <span className="new-score w-full h-full bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
          <td>
            <div className="manager-scores text-[#9ca3af]">
              <span className="old-score w-full h-full"></span>
              <span className="new-score w-2/3 h-2/3 bg-[#e2e4ea] opacity-40"></span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SkeletonManagers;
