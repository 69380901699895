import { useState, useCallback, useEffect } from "react";
import Header from "../modules/Header";
import FooterInner from "../modules/FooterInner";
import SurveyStep1 from "../modules/SurveyStep1";
import SurveyStep2 from "../modules/SurveyStep2";
import SurveyStep3 from "../modules/SurveyStep3";
import SurveyStep4 from "../modules/SurveyStep4";

import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";

import { useParams } from "react-router-dom";
import {
  axiosGetSurveyRoute,
  axiosSubmitSurveyRoute,
  // axiosSelfSurveyRoute,
} from "../helpers/axios";

const cookies = new Cookies();

const SurveySteps = ({ type }) => {
  const [stepNumber, setStepNumber] = useState();
  const [currentCategory, setCurrentCategory] = useState(0);
  const [surveyData, setSurveyData] = useState();
  const [surveyErrorResponse, setSurveyErrorResponse] = useState("");
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState(1);
  const [firstRun, setFirstRun] = useState(true);
  const [requestedLanguage, setRequestedLanguage] = useState("");

  const token = cookies.get("veemind-app-token");

  const usertoken = cookies.get("veemind-user-survey");

  const { t, i18n } = useTranslation();

  let { survey_name, user_survey_id } = useParams();

  const getSurvey = useCallback(
    async (id_survey, id_user_survey, type = "user", t) => {
      try {
        let result;

        result = await axiosGetSurveyRoute(`/${id_survey}`, {
          params: {
            ...(id_user_survey && { id_user_survey: id_user_survey }),
            ...(requestedLanguage && {
              requested_language: requestedLanguage,
            }),
          },
        });

        if (result.data) {
          setSurveyData(result.data);

          if (result?.data?.findusersurvey) {
            let answerobj = {};

            result.data.findusersurvey.answers.forEach((answer) => {
              return (answerobj = {
                ...answerobj,
                [answer.question_id.id]: answer.response,
              });
            });

            setSurveyAnswers(answerobj);
          }

          // if not requested language, set to survey default
          if (!requestedLanguage) {
            const browserLanguage = window.navigator.language.split("-")[0];
            // determine if browser language is available
            if (
              result?.data?.available_languages?.length > 0 &&
              result?.data?.available_languages.some(
                (lang) => lang.code === browserLanguage
              )
            ) {
              setRequestedLanguage(browserLanguage);
            } else {
              setRequestedLanguage(result?.data?.company_language);
            }
          }
        }
        setFirstRun(false);
      } catch (err) {
        setSurveyErrorResponse(err.response.data.error.message);
        console.log(err.response.data.error.message);
      }
    },
    [requestedLanguage]
  );

  const categoriesnofeedback = surveyData?.category.filter(
    (filtertype) => filtertype.text !== "Team feedback"
  );

  const submitSurveyHandler = useCallback(
    async ({ final_step = false, save_per_category = false }) => {
      try {
        const result = await axiosSubmitSurveyRoute({
          data: {
            user_survey_id: surveyData?.findusersurvey?.id || null,
            survey_id: surveyData.id,
            answers: surveyAnswers,
            survey_type: surveyData.type,
            survey_complete: final_step,
          },
          ...(token && {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        });

        if (result.data && result.data.findusersurvey) {
          const usersurveydata = result.data.findusersurvey;
          let answerobj = {};

          setSurveyData((prior) => ({
            ...prior,
            findusersurvey: usersurveydata,
          }));

          usersurveydata.answers.forEach((answer) => {
            answerobj = {
              ...answerobj,
              [answer.question_id.id]: answer.response,
            };
          });

          setSurveyAnswers(answerobj);
          if (!save_per_category)
            setStepNumber((currentstep) => Number(currentstep) + 1);

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 15);
          cookies.set(
            "veemind-user-survey",
            result.data.findusersurvey.anon_request,
            {
              path: "/",
              expires: expirationDate,
            }
          );

          setCurrentCategory((prior) => {
            var value = prior === undefined || prior === null ? 0 : prior;
            return value < categoriesnofeedback.length - 1
              ? value + 1
              : categoriesnofeedback.length - 1;
          });
          setCurrentQuestions((prior) => prior + 1);

          window.scrollTo(0, 0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [surveyAnswers, surveyData, token, categoriesnofeedback?.length]
  );

  useEffect(() => {
    (firstRun || requestedLanguage) &&
      getSurvey(survey_name, user_survey_id || usertoken, type);
  }, [
    getSurvey,
    survey_name,
    user_survey_id,
    usertoken,
    type,
    firstRun,
    requestedLanguage,
  ]);

  const gatherSurveyAnswers = (item, selection) => {
    setSurveyAnswers((values) => ({
      ...values,
      [item.id]: selection,
    }));
  };

  useEffect(() => {
    // set front end language to requested language
    requestedLanguage && i18n && i18n.changeLanguage(requestedLanguage);
  }, [requestedLanguage, i18n]);

  return (
    <div>
      <Header
        mode={"usersurvey"}
        stepNumber={stepNumber}
        requestedLanguage={requestedLanguage}
        setRequestedLanguage={setRequestedLanguage}
        availableLanguages={surveyData?.available_languages}
      />
      {surveyData && (
        <>
          {!stepNumber && (
            <SurveyStep1
              surveyData={surveyData}
              setStepNumber={setStepNumber}
            />
          )}
          {stepNumber === 2 && (
            <SurveyStep2
              userData={surveyData?.user}
              surveyData={categoriesnofeedback}
              surveyAnswers={surveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
              gatherSurveyAnswers={gatherSurveyAnswers}
              categories={categoriesnofeedback}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
              setCurrentQuestions={setCurrentQuestions}
              currentQuestions={currentQuestions}
            />
          )}
          {stepNumber === 3 && (
            <SurveyStep3
              surveyType={surveyData.type}
              surveyData={surveyData.category?.find(
                (filtertype) => filtertype.text === "Team feedback"
              )}
              surveyAnswers={surveyAnswers}
              setStepNumber={setStepNumber}
              gatherSurveyAnswers={gatherSurveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
            />
          )}
          {stepNumber === 4 &&
            (surveyData.category?.filter(
              (filtertype) => filtertype.text === "Team feedback"
            ).length > 0 ? (
              <SurveyStep4
                surveyData={surveyData}
                setStepNumber={setStepNumber}
              />
            ) : (
              (window.location = "/survey/results")
            ))}
        </>
      )}
      {surveyErrorResponse && (
        <section className="lg:h-screen flex flex-wrap justify-center overflow-hidden lg:px-0 px-[15px] pt-[70px] lg:pb-[70px] dark:bg-dark-login">
          <div className="w-full mx-auto self-center text-center py-[60px]">
            <h2 className="font-semibold text-[40px] leading-[45px] text-primary dark:text-white mb-7">
              {t(surveyErrorResponse)}
            </h2>
          </div>
        </section>
      )}
      <FooterInner />
    </div>
  );
};

export default SurveySteps;
