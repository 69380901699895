import React from "react";

const SkeletonCreateSurvey = () => {
  return (
    <form className="flex pointer-events-none cursor-none">
      <div className="w-1/2 bg-[rgb(243,243,250)] rounded-[18px] p-7 animate-pulse">
        <div className="checkbox-list">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
            <div
              key={index}
              className={`flex items-center p-2 my-4 rounded-lg bg-[#dedff1]`}
            >
              <div className="bg-[#e2e4ea] w-5 h-5 rounded-full mr-2"></div>
              <div className="select-none cursor-pointer !mb-0 bg-[#e2e4ea] w-1/2 h-4 rounded-full"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-[calc(50%+20px)] -ml-5 bg-[#dedff1] rounded-[18px] p-7 animate-pulse">
        <div className="checkbox-list">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
            <div
              key={index}
              className={`flex items-center p-2 my-4 rounded-lg bg-[#dedff1]`}
            >
              <div className="bg-[#e2e4ea] w-5 h-5 rounded-full mr-2"></div>
              <div className="select-none cursor-pointer !mb-0 bg-[#e2e4ea] w-1/2 h-4 rounded-full"></div>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
};

export default SkeletonCreateSurvey;
