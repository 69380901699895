import React from "react";

const SkeletonCompany = () => {
  return (
    <div className="flex w-full skeleton animate-pulse">
      <div className="lg:w-1/3 lg:pr-8 lg:max-w-[270px] w-full">
        <div className="box-primary small-box py-4 px-5 mb-5 h-[160px]">
          <div className="skeleton-text"></div>
          <div className="skeleton-text !w-2/3"></div>
          <div className="skeleton-text !w-1/4"></div>
        </div>

        <div className="box-white py-4 px-5 mb-5">
          <div className="skeleton-title mb-6"></div>
          <div className="text-[#686285] flex items-center mb-4">
            <div className="inline-block w-1/2">
              <div className="skeleton-text"></div>
              <div className="skeleton-text !w-2/3"></div>
            </div>
            <span className="bg-[#f3f4f6] pill h-8"></span>
          </div>
          <div className="text-[#686285] flex items-center">
            <div className="inline-block w-1/2">
              <div className="skeleton-text"></div>
              <div className="skeleton-text !w-2/3"></div>
            </div>
            <span className="bg-[#f3f4f6] pill h-8"></span>
          </div>
        </div>
        <div className="skeleton-title mb-6 ml-4"></div>
        <div className="box-white bg-[#F8F8FC] bg-opacity-80 py-4 px-5 mb-5">
          <div className="skeleton-title !h-2.5 mb-3"></div>
          <div className="skeleton-text !w-4/5"></div>
          <div className="skeleton-text !w-2/3"></div>
          <div className="skeleton-text !w-1/3"></div>
        </div>
        <div className="box-white bg-[#F8F8FC] bg-opacity-80 py-4 px-5 mb-5">
          <div className="skeleton-title !h-2.5 mb-3"></div>
          <div className="skeleton-text !w-4/5"></div>
          <div className="skeleton-text !w-2/3"></div>
          <div className="skeleton-text !w-1/3"></div>
        </div>
      </div>
      <div className="lg:max-w-full lg:min-w-[calc(100%-270px)]">
        <div className="top-bar">
          <div className="lg:flex mb-8">
            <div className="lg:w-1/4 lg:r-4 w-full">
              <div className="skeleton-title !h-3.5"></div>
            </div>
            <div className="lg:pl-4 lg:w-3/4 w-full">
              <div className="skeleton-text !w-4/5"></div>
              <div className="skeleton-text !w-3/4"></div>
              <div className="skeleton-text !w-1/3"></div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/4 pr-4">
              <div className="graph-btn !bg-[#e2e4ea] h-9"></div>
              <div className="graph-btn !bg-[#e2e4ea] h-9"></div>
              <div className="graph-btn !bg-[#e2e4ea] h-9"></div>
              <div className="graph-btn !bg-[#e2e4ea] h-9"></div>
              <div className="graph-btn !bg-[#e2e4ea] h-9"></div>
              <div className="graph-btn !bg-[#e2e4ea] h-9 !mb-0"></div>
            </div>
            <div className="w-3/4 pl-4">
              <div className="bg-[#e2e4ea] rounded-lg w-full h-full"></div>
            </div>
          </div>
          <div className="lg:flex inner-white-box">
            <div className="lg:w-1/2 w-full lg:pr-6">
              <div className="skeleton-title !w-1/3 mb-5"></div>
              <div className="survey-box !bg-transparent !m-0 !p-0 w-full">
                <span className="survey-image !h-auto">
                  <div className="graph-btn !bg-[#f3f4f6] h-20 w-20 mx-auto"></div>
                  <div className="skeleton-title !w-2/3 mt-3 mx-auto"></div>
                </span>
                <div className="inline-block align-middle lg:w-[calc(100%-125px)] w-[calc(100%-85px)]">
                  <div className="skeleton-title !w-2/3 mb-4"></div>
                  <div className="skeleton-text !w-4/5"></div>
                  <div className="skeleton-text !w-2/3"></div>
                  <div className="skeleton-text !w-2/3"></div>
                  <div className="skeleton-text !w-1/3"></div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 w-full lg:pl-6">
              <div className="skeleton-title !w-2/3 mb-5"></div>
              <div className="rounded-xl p-8 bg-[#f3f4f6]">
                <div className="skeleton-text !w-4/5"></div>
                <div className="skeleton-text !w-2/3"></div>
                <div className="skeleton-text !w-1/3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCompany;
